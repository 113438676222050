import React, { Component } from 'react'
import { apiCall, MyLoader } from '../Utility/utils';
import { COMMUNITY_EVENTS_LIST_URLS } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#F9F9F9',
    minHeight: '100vh',
    padding: '1rem',
    width:'100%'
  },
  text: {
    marginLeft:'1rem',
    fontFamily:'Jost',
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400'
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '0.5rem',
    '&:hover': {
      background: "#676AC1",
      color: '#FFFFFF'
    },
  }
}

export default class Event extends Component {
  constructor() {
    super();
    this.state = {
      lists: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: COMMUNITY_EVENTS_LIST_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderEvents = (events, idx ) => {
    const {name, id} = events;
    return (
      <Link style={{ all: "unset" }} to={`/community/event/${id}`} key={idx}>
        <div style={styles.textContainer} >
          <p style={{...styles.text}}>{name}</p>
        </div>
      </Link>
    );
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderEvents}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render() {
    return (
      <>
      <Header societyName="Events"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    )
  }
}
