import React, { Component } from 'react'
import SearchIcon from '../Assets/search.svg'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Avatar, Typography } from '@mui/material';
import Avatar_png from '../Assets/Ellipse_3.png'
import PhoneIcon from '../Assets/phone-icon.svg'
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { apiCall, MyLoader } from '../Utility/utils';
import { DIRECTORY_VEHICLES_LIST_URLS } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D2D2D2',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop:'1rem',
  // marginRight:'1rem',
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  height:'48px',
  flexGrow:1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const styles = {
  container: {
    // backgroundColor: '#F7F7F7',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  card: {
    marginTop:'1rem',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    padding:'0.5rem 1rem'
  },
  call: {
    fontSize:'14px',
    lineHeight:'36px',
    fontWeight:'600 semi bold',
    color:'#676AC1'
  },
  show: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'400',
    color:'#676AC1',
    textDecoration:'underline',
  },
  cardHead: {
    display:'flex',
    justifyContent:'space-between',
  },
  cardBodyText16: {
    fontSize:'16px',
    fontWeight:'400',
    lineHeight:'36px',
    color:'#888888'
  },
  cardBodyText14: {
    fontSize:'14px',
    fontWeight:'400',
    lineHeight:'36px',
    color:'#888888'
  },
}

class VehicleScreen extends Component  {
  constructor(){
    super();
    this.state = {
      lists: [],
      search: "",
      listLoading: true,
      message: ""
    }
  }

  search = (value) => {
    clearTimeout(this.timeout);
    this.setState({search: value}, () => {
      this.timeout = setTimeout(() => {
        this.lists()
      }, 250);
    });
    //this.setState({search: value}, ()=>this.lists(value));
  }

  async lists() {
    let formData = {};
    formData.token =  localStorage.getItem("societyToken");
    formData.search = this.state.search;
            
    var api_options = {
      config: {
        method:'post',
        url: DIRECTORY_VEHICLES_LIST_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        
        if(response.data.data.length > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderVehicle = (vehicle, name) => {
    const {type, vcolor, number, id} = vehicle;
    return (
      <div style={styles.card} key={id}>
        <div style={styles.cardHead}>
          <p style={{fontSize:'14px', lineHeight:'36px', color:'#17171C', fontWeight:'400'}}>{vehicle.show_number} {name} <br/> {type}</p>
          <p style={{fontSize:'16px', lineHeight:'36px', color:'#888888', fontWeight:'400'}}></p>
        </div>
        <div>
          <p style={{fontSize:'16px', lineHeight:'36px', color:'#17171C', fontWeight:'400'}}>{vehicle.note}</p>
          <p style={{fontSize:'15px', lineHeight:'36px', color:'#888888', fontWeight:'400'}}>Number : {number}</p>
        </div>
      </div>
    );

  }

  renderMember = (member, idx) => {
    const name = `${member.first_name} ${member.last_name}`;

    if(this.state.listLoading === true){
      return <MyLoader />
    }

    return (

      <FlatList
        key = {idx}
        list={member.vehicle}
        renderItem={(vehicle)=>this.renderVehicle(vehicle, name)}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderMember}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }
  
  render(){
    return (
      <>
      <Header societyName="Vehicle Directory"/>
      <div style={styles.container}>
        <Search>
          <SearchIconWrapper>
            <img alt='search' src={SearchIcon}/>
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            value={this.state.search}
            onChange={(e)=>{
              Mixpanel.track("fm_directory_vehicledirectory_searched", {"search_text": e.target.value});
              this.search(e.target.value)}}
            sx={{}}
          />
        </Search>
        {this.load_main_view()}
       
      </div>
      </>
    )
  }
}

export default VehicleScreen