import React, { Component } from 'react'

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    // minHeight: '100vh',
    padding: '1rem',
    width:'100%',
    fontFamily:'Jost'
  },
  text: {
    marginLeft:'1rem',
    fontFamily:'Jost',
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400'
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '0.5rem',
    '&:hover': {
      background: "#676AC1",
      color: '#FFFFFF'
    },
  },
  cardBody400: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    color:'#888888'
  },
  hoverCardBody400: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    color:'#FFFFFF'
  },
  menuCard:{
    backgroundColor:"#FBFBFD",
    padding:"0.5rem 1rem",
    marginTop:"1rem"
  },
  hoverMenuCard:{
    backgroundColor:"#676AC0",
    padding:"0.5rem 1rem",
    marginTop:"1rem"
  }, 
  cardHeadText: {
    color:'#000000',
    fontSize:'20px',
    lineHeight:'36px',
    fontWeight:'400'
  },
  hoverCardHeadText: {
    color:'#FFFFFF',
    fontSize:'20px',
    lineHeight:'36px',
    fontWeight:'400'
  },
  yellowTextContainer: {
    backgroundColor:'#FFFDF1',
    marginTop:'1rem',
    padding:'0.5rem 1rem'
  }
}

export default class MainBalance extends Component {
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.menuCard}>
          <p style={styles.cardHeadText}>View Transaction Details</p>
          <p style={styles.cardBody400}>View all transactions that occured in a particular time period in a summarized form.</p>
        </div>
        <div style={styles.hoverMenuCard}>
          <p style={styles.hoverCardHeadText}>Balance Sheet</p>
          <p style={styles.hoverCardBody400}>View all transactions that occured in a particular time period in a summarized form.</p>
        </div>
        <div style={styles.menuCard}>
          <p style={styles.cardHeadText}>Income & Expenditure</p>
          <p style={styles.cardBody400}>View all transactions that occured in a particular time period in a summarized form.</p>
        </div>
        <div style={styles.menuCard}>
          <p style={styles.cardHeadText}>Cash Book</p>
          <p style={styles.cardBody400}>View all transactions that occured in a particular time period in a summarized form.</p>
        </div>
        <div style={styles.menuCard}>
          <p style={styles.cardHeadText}>Receipt Summary</p>
          <p style={styles.cardBody400}>View all transactions that occured in a particular time period in a summarized form.</p>
        </div>
      </div>
    )
  }
}