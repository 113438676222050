import React, { Component } from 'react'
import Grid from '@mui/material/Grid'

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    // minHeight: '100vh',
    padding: '1rem',
    width:'100%',
    fontFamily:'Jost'
  },
  text: {
    marginLeft:'1rem',
    fontFamily:'Jost',
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400'
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '0.5rem',
    '&:hover': {
      background: "#676AC1",
      color: '#FFFFFF'
    },
  },
  cardBody400: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '36px',
    color:'#888888'
  },
  card:{
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    borderRadius:"5px",
    backgroundColor:"#FFFFFF",
    padding:"1rem"
  },  
  cardHeadText: {
    color:'#676AC0',
    fontSize:'18px',
    lineHeight:'36px',
    fontWeight:'400'
  },
  yellowTextContainer: {
    backgroundColor:'#FFFDF1',
    marginTop:'1rem',
    padding:'0.5rem 1rem'
  }
}

export default class Accounts extends Component {
  render() {
    return (
      <div style={styles.container}>
        <h5>Balance Sheet</h5>
        <div style={styles.card}>
          <p style={styles.cardHeadText}>
            Main Balance
          </p>
          <div style={styles.yellowTextContainer}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <p style={styles.cardBody400}>Cash :</p>
              </Grid>
              <Grid item xs={8}>
                <p style={styles.cardBody400}>₹200</p>
              </Grid>
            </Grid>
          </div>
          <div style={styles.yellowTextContainer}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <p style={styles.cardBody400}>Bank :</p>
              </Grid>
              <Grid item xs={8}>
                <p style={styles.cardBody400}>₹0</p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}
