import React, { Component } from 'react'
import { apiCall } from '../Utility/utils';
import { CONSTRUCTION_REQUEST_CONSTRACTOR_LIST_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#F9F9F9',
    minHeight: '100vh',
    padding: '1rem',
    width:'100%'
  },
  text: {
    marginLeft:'1rem',
    fontFamily:'Jost',
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400'
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '0.5rem',
    '&:hover': {
      background: "#676AC1",
      color: '#FFFFFF'
    },
  }
}

export default class RequestContractorList extends Component {
  constructor() {
    super();
    this.state = {
      lists: []
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: CONSTRUCTION_REQUEST_CONSTRACTOR_LIST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({lists:response.data.data});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderRequestContractors = (requestContractor, idx ) => {
    const {service_desc} = requestContractor;
    return (
      <div style={styles.textContainer} key={idx}>
        <p style={{...styles.text}}>{service_desc}</p>
      </div>
    );
  }

  render() {
    return (
      <>
      <Header societyName="Requested Contractor"/>
      <div style={styles.container}>
        <FlatList
          list={this.state.lists}
          renderItem={this.renderRequestContractors}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
      </div>
      </>
    )
  }
}
