import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import { TextField } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const VerifyOTP = ({countryCode, mobileNumber, handleResendOTP, otp, handleOTPChange, handleNumberEdit, handleVerifyOTP, isOTPInvalid}) => {
    return (
        <>
            <CardContent sx={{m: 2}}>
                <Typography variant="h5" component="div" style={{"color": "#676ac0"}}>
                    Verify OTP
                </Typography>
                <Box sx={{mt: 5}} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography component="div">
                        Enter 6-digit OTP sent to {countryCode} {mobileNumber}
                    </Typography>

                    <Link onClick={handleNumberEdit} sx={{fontFamily:'Jost', color: "rgba(0, 0, 0, 1)"}}>
                        EDIT
                    </Link> 
                </Box>

                <TextField
                    sx={{mt: 1}}
                    variant="outlined"
                    label="Enter OTP"
                    placeholder="Type here"
                    helperText={`0/6 characters`}
                    value={otp}
                    onChange={(event) => handleOTPChange(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button variant="standard" onClick={handleResendOTP}>
                                    <strong>
                                        RESEND OTP
                                    </strong>
                                </Button>
                            </InputAdornment>
                        )
                    }}
                />
                <FormHelperText error={otp.length > 6}>{otp.length > 6 ? "otp should not be more than 6 digit" : ""}</FormHelperText>
            </CardContent>
            
            {isOTPInvalid &&
                <Alert severity="error">
                    <AlertTitle>INVALID OTP</AlertTitle>
                    Inserted invalid OTP
                </Alert>
            }

            <CardActions sx={{textAlign: "center"}}>
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                >
                    <Button variant="contained" sx={{backgroundColor: "black"}} onClick={handleVerifyOTP}>
                        Verify OTP <ArrowRightAltIcon/> 
                    </Button>
                </Box>
            </CardActions>
        </>
    );
}

export default VerifyOTP;