import React,{ Component } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from '../Header';

const styles = {
  container: {
    // backgroundColor: '#F7F7F7',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  button: {
    background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',
    height:'56px',
    fontFamily:'Jost',
    fontSize:'18px',
    lineHeight:'36px',
    fontWeight:'700',
    marginBottom:'2rem'
  }
}

class DirectoryCreate extends Component {
  render(){
    return (
      <>
      <Header />
      <div style={styles.container}>
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}} fullWidth id="standard-basic" label="Name" variant="standard" />
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}} fullWidth id="standard-basic" label="Contact Number" variant="standard" />
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}} fullWidth id="standard-basic" label="Email id (optional)" variant="standard" />
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}} fullWidth id="standard-basic" label="Address (optional)" variant="standard" />
        <Button sx={styles.button} fullWidth  variant="contained">Save</Button>
      </div>
      </>
    )
  }
}

export default DirectoryCreate