import { useState } from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { COTTAGE_BOOKING_CANCEL_URL } from '../Utility/URL';
import { apiCall, SweetAlert } from '../Utility/utils';
import TermsAndConditions from './TermsAndConditions';

const colorStyle = {
    color: "rgb(103, 106, 193)",
    borderColor: "rgb(103, 106, 193)"
}

const CancelBooking = ({reasons, id}) => {
    const [ open, setOpen] = useState(false);
    const [ selectedReason, setSelectedReason] = useState("");

    const [ termsAndConditionChecked, setTermsAndConditions ] = useState(false);

    const [ otherReason, setOtherReason ] =  useState("");

    const handleChangetextField = (event) => {
        setOtherReason(event.target.value);
    }

    const handleOpen = () => {
        setOpen(true)
    };


    const handleClose = () => {
        setOpen(false)
    };

    const handleChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleCancelBokking = async () => {
        if (selectedReason !== "") {
            const cancel_message = JSON.parse(selectedReason).name;
            const requestBody = {
                id: parseInt(window.location.pathname.split("/")[2]),
                token: localStorage.getItem("societyToken"),
                cancellation_message: cancel_message === "Other" ? otherReason : cancel_message
            }
            await apiCall({
                config: {
                  method: "post",
                  url: COTTAGE_BOOKING_CANCEL_URL,
                  headers:{
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  data:JSON.stringify(requestBody),
                },
                successCallback: (response)=>{
                    console.log("sucess");
                    window.location.href="/cottages";
                },
                errorCallback: (error)=>{
                  console.log("Error", error.response);
                  handleClose();
                  SweetAlert({
                    message: error.response.data.message,
                    confirm_btn_txt: "Yes",
                    cancel_btn_show: false,
                    icon: 'warning'
                  });
                }    
            });
        }
    }

    const handleTermsAndConditionOnChange = () => {
        setTermsAndConditions(!termsAndConditionChecked);
    }

    return (
        <Box sx={{ width: "100% "}}>
            <TermsAndConditions
                value={termsAndConditionChecked}
                onChange={handleTermsAndConditionOnChange}
                id={id}
            />
            <Button sx={colorStyle} onClick={handleOpen} fullWidth variant='outlined' disabled={!termsAndConditionChecked}>
                <p style={{fontFamily:'Jost'}}>
                    <strong>
                        Cancel Booking
                    </strong>
                </p>
            </Button>
            <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
                <DialogTitle><strong>Cancel Booking</strong></DialogTitle>
                <IconButton onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <FormControl>
                        <Typography  variant='h6' gutterBottom sx={{mb: 1}}>
                            Select a reason
                        </Typography>
                        <RadioGroup
                            fullWidth
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={selectedReason}
                            onChange={handleChange}
                        >
                            {reasons.map((reason) => (
                                <FormControlLabel key={reason.id} value={JSON.stringify(reason)} control={<Radio />} label={reason.name} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    {selectedReason !== "" && JSON.parse(selectedReason).name === "Other" && (
                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="Type here"
                            InputLabelProps={{shrink: true}}
                            multiline
                            variant="outlined"
                            placeholder='Describe your issue in few words'
                            onChange={handleChangetextField}
                            value={otherReason}
                            minRows={4}
                        />
                    )}

                </DialogContent>
                <DialogActions>
                    <Button disabled={selectedReason === ""} sx={{color: "rgb(103, 106, 193)", borderColor: "rgb(103, 106, 193)"}} fullWidth onClick={handleCancelBokking} variant='outlined'>
                        <p style={{fontFamily:'Jost'}}>
                                Cancel Now
                        </p>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default CancelBooking;