import React,{ Component } from 'react'
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import UploadIcon from '../Assets/upload-cloud.svg'
import { apiCall, SweetAlert, MyLoader } from '../Utility/utils';
import { CONSTRUCTION_REQUEST_CONTRACTOR_SAVE_URL, CONSTRUCTION_REQUEST_CONSTRACTOR_LIST_URL } from '../Utility/URL';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom'
import { Mixpanel } from '../Mixpanel';
import {isAndroid, isIOS} from 'react-device-detect';

const styles = {
  container: {
    backgroundColor:'#FAFAFA',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost',
    minHeight:'100vh',
  },
  button: {
    background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',
    height:'56px',
    fontFamily:'Jost',
    fontSize:'18px',
    lineHeight:'36px',
    fontWeight:'700',
    marginTop:'3rem',
    marginBottom:'2rem'
  },
  uploadContainer: {
    border: '1px dashed #C4C4C4',
    borderRadius:'5px',
    display:'flex',
    justifyContent:'center',
    height: '6rem',
    backgroundColor:'#FFFFFF',
    padding:'1rem'
  },
  p1: {
    fontFamily:'Jost',
    marginTop:'1rem',
    marginBottom:'0.5rem',
    color:'#646464'
  },
  p2: {
    alignSelf:'center',
    marginLeft:'0.5rem',
    color:'#9D9EB1',
    fontFamily:'Jost'
  },
}

class RequestContractor extends Component {
  constructor(){
    super();
    this.state = {  
      formData:{
        service_desc: "",
        notes: "",
      },
      errors: {
        service_desc_error: "",
      },
      listLoading: true,
      file_name: ""
    };
  }

  validate = () => {
    const errorMsg = {
      service_desc_error: this.state.formData.service_desc === "" ? "This field is required." : ""
    }
    this.setState(prevState=> ({...prevState, errors: {...errorMsg}}));

    return Object.values(errorMsg).some(error => error !== "");
  }

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  async save() {
    const data = new FormData();
    
    for (let k in this.state.formData) {
      data.append(k, this.state.formData[k]);
    }
    data.append('token', localStorage.getItem("societyToken"));
    
    var api_options = {
      config: {
        method: "post",
        url: CONSTRUCTION_REQUEST_CONTRACTOR_SAVE_URL,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      },
      successCallback: (response)=>{
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator("/construction/contractor/list", {replace: true})
        }
        SweetAlert(config);
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }
    }
    Mixpanel.track("fm_construction_request_contractor_sned_request_clicked", this.state.formData)
    apiCall(api_options);
  }

  submitForm = (e) => {
    e.preventDefault()
    if(!this.validate()){
      this.save();
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    }
            
    var api_options = {
      config: {
        url: CONSTRUCTION_REQUEST_CONSTRACTOR_LIST_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false}, ()=> this.props.navigator("/construction/contractor/list", { replace: true }));
        } else {
          return this.setState({listLoading:false});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  sendUploadAction = () => {
     
     var shareObj = { 
      "type": "doc",
      "page_name": "request_contractor"                                  
      }; 
      
      if(isIOS){
        window.webkit.messageHandlers.sendUploadActionInNative.postMessage(JSON.stringify(shareObj));  
      }        

      if(isAndroid){       
        window.JSBridge.sendUploadActionInNative(JSON.stringify(shareObj)); 
      }
  };

  render(){
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <>
      <Header societyName="Request Contractor"/>
      <div style={styles.container}>
       
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}} 
          fullWidth id="standard-basic" 
          label="Service Description" 
          variant="standard"
          name="service_desc"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.errors.service_desc_error !== "" ? true : false}
          helperText={this.state.errors.service_desc_error}
        />

        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}}
          fullWidth id="standard-basic"
          label="Notes (if any)" 
          variant="standard"
          name="notes"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
        />

        <p style={styles.p1}>Upload files (optional)</p>
        
        <a style={{all:"unset"}} href="javascript:void(0)" onClick={ () => { this.sendUploadAction() }}>

        <label htmlFor="upload-photo" style={{width:'100%'}}>
          <div style={styles.uploadContainer}>
            <img src={UploadIcon} width="24px" />
            <p style={styles.p2} >Add files</p>
          </div>
        </label><br/>        
        </a>
        <p style={styles.p1}>{this.state.file_name}</p>

        <Button sx={styles.button} fullWidth  variant="contained" onClick={(e) =>this.submitForm(e)}>Send Request</Button>
      </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(RequestContractor);