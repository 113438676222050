import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ImageCarousel = ({images}) => {
    return (
        <OwlCarousel
            autoplay
            autoplayTimeout={5000}
            autoplayHoverPause={true}
            loop={true}
            animateOut="fadeOut"
            className="owl-theme"
            items={1}
        >
            {images.map((img) => (
                <img className="item carousel" height="200vh" src={img} width="100%" alt="party_hall_big_img" />
            ))}
        </OwlCarousel>
    )
}

export default ImageCarousel;