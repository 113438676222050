import React,{ Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Verified_red from '../Assets/verified_red.svg'
import { apiCall, MyLoader, format_date_str } from '../Utility/utils';
import { NOTIFICATION_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import { padding } from '@mui/system';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    padding:'1rem',
    fontFamily:'Jost',
  },
  listItem: {
    display:'flex',
    margin:'1rem 0',
    backgroundColor:"#FBFBFB",
    padding:'0.3rem',
    borderRadius:'5px'
  },
  listItemText: {
    fontSize:'14px',
    lineHeight:'22px',
    fontWeight:'400',
    whiteSpace: 'pre-line'
  }
}

class Notifications extends Component {
  constructor(){
    super();
    this.state = {
      data: [],
      new_data: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken"),
    }
            
    var api_options = {
      config: {
        url: NOTIFICATION_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),
      },
      successCallback:(response)=>{
        if(response.data.old_total > 0 || response.data.new_total > 0){
          return this.setState({listLoading:false, data:response.data.data, new_data: response.data.new_data, message: ""});
        }else {
          return this.setState({listLoading:false, message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  }

  renderNotifications = (notification, idx) => {
    const {id, subject, message, created_at, is_read} = notification;
    return (
        <div style={{...styles.listItem}} key={idx}>
          {/* {is_read==0 && } */}
          <Avatar alt="avatar" fontSize="small" style={{margin:'0 0.8rem'}} ><NotificationsIcon /></Avatar>
          <div style={styles.listItemText}>{subject}. {'\n'} <span style={{color:"#888888", fontSize:'10px'}}>{format_date_str(created_at)}</span></div>
          {/* {'\n'} */}
          {/* <div style={styles.listItemText}>{message}.</div> */}
        </div>
     )
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      this.state.message !== "" ? 
      NO_DATA_FOUND(this.state.message) :
      (<>
        <FlatList
            list={this.state.new_data}
            renderItem={this.renderNotifications}
            renderWhenEmpty={() =><></>}
            // renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
          />
        
        <FlatList
            list={this.state.data}
            renderItem={this.renderNotifications}
            renderWhenEmpty={() =><></>}
            // renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
      </>)
    )

  }

  render(){
    return (
      <>
      <Header societyName="Notifications"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    )
  }
}

export default Notifications