import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card';
import partyhallimg from '../Assets/Mask_group.png'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './index.css'
import { apiCall, MyLoader, NO_DATA_FOUND } from '../Utility/utils';
import { AMENITY_BOOKINGS_URL, FACILITY_IMG_URL, FACILITY_DEFAULT_IMG_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import Grid from '@mui/material/Grid';
import { format_date_str, format_time_str } from '../Utility/utils';

export default class BookedFacility extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
      listLoading: true
    };
    this.handleShowMore = this.handleShowMore.bind(this) 
  }

  async lists(){
    let formData = {
      token: localStorage.getItem("societyToken")
    };

    let api_options = {
      config: {
        method: 'post',
        url: AMENITY_BOOKINGS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        if(response.data.total>0){
          this.setState({listLoading:false, lists:response.data.data, message: ''});
        }else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  handleShowMore(){
    this.setState((prevState) => {
      return {showSlots:!prevState.showSlots}
    })
  }

  componentDidMount() {
    this.lists();
  };  

  renderBookedFacility(facility, idx) {
    const {name, id, start_date, amount, time_slot, image, status_name, facility_id} = facility;
    return (
      <a href={`facilities/${facility_id}`} style={{all:'unset'}}>
        <Card sx={{ minWidth: 275 , marginTop: '1rem' }}  key={idx}>
          <CardMedia
            component="img"
            width="100%"
            // height="194"
            image={Boolean(image) ? FACILITY_IMG_URL+image : FACILITY_DEFAULT_IMG_URL}
            alt="party hall image"
          />
          <CardHeader
            // action={
            //   <IconButton aria-label="settings">
            //     <MoreVertIcon />
            //   </IconButton> 
            // }
            title={
              <p style={{fontFamily:'Jost',color:"#000000",fontSize:"19px", fontWeight:"400"}}>
                {name}
              </p>
            }
          />
          <div style={{padding:"0 1rem", marginBottom:"1rem" }}>
            <div>
              {/* <div style={{display:"flex", justifyContent:"space-between"}}> */}
                <p style={{fontFamily:'Jost', color:"#888888", fontSize:"15px"}}><CalendarTodayOutlinedIcon/> {format_date_str(start_date)}</p> 
                {/* <p style={{fontFamily:'Jost', color:"#888888", fontSize:"15px", textDecoration:"underline"}} >Show Slots</p>  */}
              {/* </div> */}
              <div style={{marginTop:"0.5rem"}}>
              <Grid container spacing={1}>
                {
                  (() => {
                    let container = [];
                    time_slot.forEach((val, index) => {
                      container.push(<Grid item xs={6} key={index}> <p style={{fontFamily:'Jost', color:"#888888", fontSize:"12px"}}><AccessTimeOutlinedIcon/> {format_time_str("2022-01-01, "+ val.time_from)} - {format_time_str("2022-01-01, "+ val.time_to)}</p></Grid>)
                    });
                    return container;
                  })() 
                }
              </Grid>
              
              </div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <p style={{color: '#676AC1',marginTop:'10px' ,fontFamily:'Jost',fontWeight:"600", fontSize:"16px",lineHeight:"36px"}}>
              ₹ <strong>{ amount > 0 ? parseInt(amount) + '/hr' : '--.--'}</strong>
            </p>
            <p style={{color: '#676AC1',marginTop:'10px' ,fontFamily:'Jost',fontWeight:"600", fontSize:"16px",lineHeight:"36px"}}>
              {status_name}
            </p>
            </div>
          </div>
        </Card>
      </a>
    )
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

      // if(typeof this.state.message != "undefined" && this.state.message != ""){
      //     return (<div className="container">{this.state.message}</div>)
      // }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderBookedFacility}
          showSlots={this.state.showSlots}
          handleShowMore={this.handleShowMore}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}      
        />
    )
  }

  render() {
    return (
      <>
      <div className="container">
        {this.load_main_view()}
        {/* <a href="/facilities/facilitylist" style={{all:'unset'}}>
          <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
            <AddIcon size="large" fontSize="large" />
          </Fab>
        </a> */}
      </div>
      </>
    )
  }
}
