import mixpanel from 'mixpanel-browser';
mixpanel.init('b6e5cdf30ddcdfb3886bdaa58a1d0acc');


let actions = {
  track: (name, props) => {
    mixpanel.track(name, props);
  }
};

export let Mixpanel = actions;