import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { apiCall } from '../Utility/utils';
import { COUNTRY_CODE_URL } from '../Utility/URL';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiInputAdornment-root': {
        marginRight: 0,
    },
    '& .MuiSelect-select': {
        minWidth: '50px',
        textAlign: 'center',
    },
    '& .MuiOutlinedInput-input': {
        paddingLeft: '10px',
    },
    '& .MuiFormHelperText-root': {
        textAlign: "right"
    }
});

const PhoneNumberInput = ({countryCode, onCountryCodeChange, mobileNumber, onMobileNumberChange}) => {
   
    const [ availableCountryCodes, setAvailableCountryCodes ] = useState([]);

    const getCountryCodes = () => {
        apiCall({
            config: {
                method: "post",
                url: COUNTRY_CODE_URL,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            },
            successCallback: (response) => {
                console.log("Recieved country codes", response.data.data);
                setAvailableCountryCodes(response.data.data);
            },
            errorCallback: (error) => {
                console.error("Getting error in countryCode fetching")
                console.log("Error", error.response);
            }
        });
    }

    useEffect(() => {
        getCountryCodes();
    }, []);

    return (
        <CustomTextField
            sx={{mt: 5}}
            variant="outlined"
            label="Enter Your Phone Number"
            fullWidth
            value={mobileNumber}
            onChange={(e) => onMobileNumberChange(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Select
                            value={countryCode}
                            onChange={(e) => onCountryCodeChange(e.target.value)}
                            variant="standard"
                            disableUnderline
                        >
                            {availableCountryCodes.map((countryData) => (
                                <MenuItem key={countryData.code} value={countryData.code}>{countryData.code}</MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                ),
            }}
            helperText={`${mobileNumber.length}/10 characters`}
            placeholder="Mobile number"
        />
        
    );
};

export default PhoneNumberInput;
