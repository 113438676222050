import React, { Component } from 'react'
import Grid from '@mui/material/Grid'
import { apiCall, MyLoader } from '../Utility/utils';
import { ACCOUNTS_REPORTS_BALANCESHEET } from '../Utility/URL';
import FlatList from 'flatlist-react'
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    // minHeight: '100vh',
    width:'100%',
    fontFamily:'Jost'
  },
  text: {
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400',
    color:"#9D9EB1"
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '0.5rem',
    '&:hover': {
      background: "#676AC1",
      color: '#FFFFFF'
    },
  },
  headText:{
    color:'#57564F',
    fontWeight:'600',
    lineHeight:'36px'
  },
  yellowTextContainer: {
    backgroundColor:'#FFFDF1',
    padding:'0.7rem 1.5rem',
  },
  divider:{
    backgroundColor:"#F3F3F3",
    height:"2px",
    border: "1px solid #F3F3F3",
  }


}

export default class BalanceSheet extends Component {
  constructor(){
    super();
    this.state = {
      assets: [],
      liablities: [],
      listLoading: true,
      message: ""
    }
  }

  async lists() {
    
    let da5a = {
      token: localStorage.getItem("societyToken"),
      balancesheet_id: 2
    };

    var api_options = {
      config: {
        method:'post',
        url: ACCOUNTS_REPORTS_BALANCESHEET,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(da5a),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, assets: response.data.assets, liablities: response.data.liablities,  message: ''});
        } // else {
        //   return this.setState({listLoading:false, lists: [], message: response.data.message  });
        // }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderAssets = (asset, idx ) => {
    return (
      <>
        <div style={styles.yellowTextContainer}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={styles.headText}>Assets</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>Amount</h5>
            </Grid>
          </Grid>
        </div>
        <div style={{padding:'0.7rem 1.5rem'}}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Transfer fees receivables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>FD Income interest receivables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Late payment income recievables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  renderLiablities = (Liablity, idx ) => {

    return (
     <>
      <div style={styles.yellowTextContainer}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <h5 style={styles.headText}>Liabilities</h5>
          </Grid>
          <Grid item xs={3} style={{textAlign:'right'}}>
            <h5 style={styles.headText}>Amount</h5>
          </Grid>
        </Grid>
      </div>
      <div style={{padding:'0.7rem 1.5rem'}}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <h5 style={{color:"#9D9EB1"}}>Current Liabilities</h5>
            <p style={{color:"#9D9EB1"}}>Member advanced</p>
          </Grid>
          <Grid item xs={3} style={{textAlign:'right'}}>
            <h5 style={styles.headText}>0.00</h5>
          </Grid>
          <Grid item xs={12}>
            <div style={styles.divider}></div>
          </Grid>
          <Grid item xs={9}>
            <h5 style={{color:"#9D9EB1"}}>Reserve and Surplus</h5>
            <p style={{color:"#9D9EB1"}}>Member advanced</p>
          </Grid>
          <Grid item xs={3} style={{textAlign:'right'}}>
            <h5 style={styles.headText}>0.00</h5>
          </Grid>
          <Grid item xs={12}>
            <div style={styles.divider}></div>
          </Grid>
          <Grid item xs={9}>
            <h5 style={{color:"#9D9EB1"}}>Income & Expenditure account</h5>
            <p style={{color:"#9D9EB1"}}>Member advanced</p>
          </Grid>
          <Grid item xs={3} style={{textAlign:'right'}}>
            <h5 style={styles.headText}>0.00</h5>
          </Grid>
        </Grid>
      </div>
     </>
    );
  }

  load_assets  = () => {
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
        list={this.state.assets}
        renderItem={this.renderAssets}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }

  load_liability  = () => {
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
        list={this.state.liablities}
        renderItem={this.renderLiablities}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.yellowTextContainer}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={styles.headText}>Liabilities</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>Amount</h5>
            </Grid>
          </Grid>
        </div>
        <div style={{padding:'0.7rem 1.5rem'}}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Current Liabilities</h5>
              <p style={{color:"#9D9EB1"}}>Member advanced</p>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Reserve and Surplus</h5>
              <p style={{color:"#9D9EB1"}}>Member advanced</p>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Income & Expenditure account</h5>
              <p style={{color:"#9D9EB1"}}>Member advanced</p>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
          </Grid>
        </div>
        <div style={styles.yellowTextContainer}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={styles.headText}>Assets</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>Amount</h5>
            </Grid>
          </Grid>
        </div>
        <div style={{padding:'0.7rem 1.5rem'}}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Transfer fees receivables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>FD Income interest receivables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1"}}>Late payment income recievables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}