import React, { Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Feedback_svg from '../Assets/community_assets/feedback_logo.svg';
import Notice_svg from '../Assets/community_assets/notice_logo.svg';
import Poll_svg from '../Assets/community_assets/poll_logo.svg';
import Meetings_svg from '../Assets/community_assets/meetings_logo.svg';
import Event_svg from '../Assets/community_assets/event_logo.svg';
import Header from '../Header';
import { apiCall } from '../Utility/utils';
import { COMUUNITY_MODULE_WISE_NOTIFICATIONS_URL } from '../Utility/URL';
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    background: '#F9F9F9',
    minHeight: '100vh',
    padding: '1rem',
  },
  subContainer1: {
    display:'flex',
  },
  subContainerText: {
    fontFamily:'Jost',
    fontWeight:'400',
    fontSize:'14px',
    lineHeight:'36px'
  },
  communityMenu: {
    backgroundColor:'#FFFFFF',
    width:'100%',
    borderRadius:'5px',
    display:'flex',
    justifyContent:'space-between',
    height: '48px',
    padding: '0.5rem',
    marginTop: '1rem',
    alignContent:'center'
  },
  avatar: {
    bgcolor: '#57564F',
    height:'30px',
    width:'30px',
    marginRight:'1rem',
    marginLeft:'0.5rem', 
  },
  avatarBadge: {
    bgcolor:'#F36B80',
    height:'24px',
    width:'24px',
    fontFamily:'Jost',
    alignSelf:'center',
    fontSize:'12px',
  }
}

export default class Community extends Component {
  constructor() {
    super();
    this.state = {
      listLoading: true,
      message: "",
      lists: []
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    }
    
    var api_options = {
      config: {
        url: COMUUNITY_MODULE_WISE_NOTIFICATIONS_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback:(response)=>{
        if(response.data.notification.length > 0){
          return this.setState({listLoading:false, lists:response.data.notification, message: ''});
        }else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };

    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  render() {
    return (
      <>
      <Header societyName="Community"/>
      <div style={styles.container}>
        <a
          onClick={()=>{
            Mixpanel.track("fm_community_feedback_clicked");
          }} 
          href="/community/feedback" style={{ all: "unset" }}>
          <div style={styles.communityMenu}>
            <div style={styles.subContainer1}>
              <Avatar sx={styles.avatar}>
                <img src={Feedback_svg} />
              </Avatar>
              <p style={styles.subContainerText}>Feedback/Suggestion</p>
            </div>
            {this.state.lists.filter(x=> x.name =='Suggestions').length>0 &&
            this.state.lists.filter(x=> x.name =='Suggestions')[0].count>0 &&
              <Avatar sx={styles.avatarBadge}>
                {this.state.lists.filter(x=> x.name =='Suggestions')[0].count}
              </Avatar>
            }
          </div>
        </a>
        <a
          onClick={()=>{
            Mixpanel.track("fm_community_notice_clicked");
          }}  
          href="/community/notice" style={{ all: "unset" }}>
          <div style={styles.communityMenu}>
            <div style={styles.subContainer1}>
              <Avatar sx={styles.avatar}>
                <img src={Notice_svg} />
              </Avatar>
              <p style={styles.subContainerText}>Notice</p>
            </div>
            {
              this.state.lists.filter(x=> x.name =='Notice').length>0 && 
              this.state.lists.filter(x=> x.name =='Notice')[0].count>0 &&
              <Avatar sx={styles.avatarBadge}>
                {this.state.lists.filter(x=> x.name =='Notice')[0].count}
              </Avatar>
            }
          </div>
        </a>
        <a
          onClick={()=>{
            Mixpanel.track("fm_community_poll_clicked");
          }}  
          href="/community/poll" style={{ all: "unset" }}>
          <div style={styles.communityMenu}>
            <div style={styles.subContainer1}>
              <Avatar sx={styles.avatar}>
                <img src={Poll_svg} />
              </Avatar>
              <p style={styles.subContainerText}>Poll</p>
            </div>
            {
              this.state.lists.filter(x=> x.name =='Poll').length>0 &&
              this.state.lists.filter(x=> x.name =='Poll')[0].count>0 &&
              <Avatar sx={styles.avatarBadge}>
                {this.state.lists.filter(x=> x.name =='Poll')[0].count}
              </Avatar>
            }
          </div>
        </a>
        <a 
          onClick={()=>{
            Mixpanel.track("fm_community_meetings_clicked");
          }} 
          href="/community/meetings" style={{ all: "unset" }}>
          <div style={styles.communityMenu}>
            <div style={styles.subContainer1}>
              <Avatar sx={styles.avatar}>
                <img src={Meetings_svg} />
              </Avatar>
              <p style={styles.subContainerText}>Meetings</p>
            </div>
            {
              this.state.lists.filter(x=> x.name =='Meetings').length>0 && 
              this.state.lists.filter(x=> x.name =='Meetings')[0].count>0 &&
              <Avatar sx={styles.avatarBadge}>
                {this.state.lists.filter(x=> x.name =='Meetings')[0].count}
              </Avatar>
            }
          </div>
        </a>
        <a
          onClick={()=>{
            Mixpanel.track("fm_community_event_clicked");
          }}  
          href="/community/event" style={{ all: "unset" }}>
          <div style={styles.communityMenu}>
            <div style={styles.subContainer1}>
              <Avatar sx={styles.avatar}>
                <img src={Event_svg} />
              </Avatar>
              <p style={styles.subContainerText}>Event</p>
            </div>
            {
              this.state.lists.filter(x=> x.name =='Event').length>0 &&
              this.state.lists.filter(x=> x.name =='Event')[0].count>0 &&
              <Avatar sx={styles.avatarBadge}>
                {this.state.lists.filter(x=> x.name =='Event')[0].count}
              </Avatar>
            }
          </div>
        </a>
        <a href="/community/lostandfound" style={{ all: "unset" }}>
          <div style={styles.communityMenu}>
            <div style={styles.subContainer1}>
              <Avatar sx={styles.avatar}>
                <img src={Event_svg} />
              </Avatar>
              <p style={styles.subContainerText}>Lost and Found</p>
            </div>
            {
              this.state.lists.filter(x=> x.name =='Lost and Found').length>0 &&
              this.state.lists.filter(x=> x.name =='Lost and Found')[0].count>0 &&
              <Avatar sx={styles.avatarBadge}>
                {this.state.lists.filter(x=> x.name =='Lost and Found')[0].count}
              </Avatar>
            }
          </div>
        </a>
      </div>
      </>
    )
  }
}
