import { Routes, Route } from 'react-router-dom'
import Navigation from './Home'
import GatepassCreate from './gatepass/manage_v2'
import GatepassList from './gatepass/index_v2'
import VisitorList from './Visitors/index_v2'
import Dailyhelperlist from './Dailyhelpers/index_v2'
import HelperDetails from './Dailyhelpers/HelperDetails'
import DailyhelperCreate from './Dailyhelpers/create'
import MyProperties from './MyProperties'
import Facilities from './Facilities'
import AmenityDetails from './Facilities/AmenityDetails'
import FacilityList from './Facilities/FacilityList'
import TimeSelection from './Facilities/TimeSelection'
import Complaints from './Complaints'
import ComplaintCreate from './Complaints/ComplaintCreate'
import Community from './Community'
import Feedback from './Community/Feedback'
import Poll from './Community/Poll'
import Notice from './Community/Notice'
import Meetings from './Community/Meetings'
import Event from './Community/Event'
import EventDetails from './Community/EventScreen2'
import LostAndFound from './Community/LostAndFound'
import LostItem from './Community/LostItemDetail'
import Construction from './Construction'
import ConstructionUpdates from './Construction/ConstructionUpdates'
import ConstructionGuidelines from './Construction/ConstructionGuidelines'
import ConstructionRc from './Construction/RequestContractor'
import ConstructionNoc from './Construction/NOC'
import ContractorsList from './Construction/ContractorsList'
import NOCList from './Construction/NOCList'
import RequestContractorList from './Construction/RequestContractorList'
import Directory from './Directory'
import DirectoryList from './Directory/List'
import VehicleScreen from './Directory/VehicleScreen'
import DirectoryCreate from './Directory/DirectoryCreate'
import GuardScreen from './Directory/GuardScreen'
import HelperScreen from './Directory/HelperScreen'
import EmergencyContactScreen from './Directory/EmergencyContactScreen'
import ResidentScreen from './Directory/ResidentScreen'
import Accounts from './Accounts'
import BalanceSheet from './Accounts/BalanceSheet'
import IncomeExpenditure from './Accounts/IncomeExpenditure'
import MainBalance from './Accounts/MainBalance'
import Notifications from './Notifications'
import BookedFacilityDetails from './Facilities/BookedFacilityDetails'
import Cottages from './Cottage';
import BookedCottageDetails from './Cottage/BookedCottageDetails';
import CottageAmenityDetails from './Cottage/CottageAmenityDetails';
import CottageTimeSelection from './Cottage/CottageTimeSelection';
import Login from './Login'

const CustomRoutes = () => {

    return (
        <Routes>
            {/* Login */}
            <Route path="/login" element={<Login />} />
            {/* property */}
            <Route exact path='/:id' element={<MyProperties/>} />
            <Route path="/property/:id" element={<Navigation />}/>
            {/* gatepass */}
            <Route path='/gatepass/list' element={<GatepassList/>} />
            <Route path='/gatepass/create' element={<GatepassCreate/>} />
            <Route path='/gatepass-:str/create/:id' element={<GatepassCreate/>} />
            <Route path='/gatepass-visitor/create' element={<GatepassCreate/>} />
            {/* visitor */}
            <Route path='/visitor/list' element={<VisitorList/>} />
            {/* dailyhelpers */}
            <Route path='/dailyhelpers/gatepass/list' element={<Dailyhelperlist/>} />
            <Route path='/dailyhelpers/list' element={<Dailyhelperlist/>} />
            <Route path='/dailyhelpers/:id' element={<HelperDetails/>} />
            <Route path='/dailyhelpers/create' element={<DailyhelperCreate/>} />
            <Route path='/dailyhelpers/update/:id' element={<DailyhelperCreate/>} />
            {/* cottages */}
            <Route path='/cottages' element={<Cottages/>} />
            <Route path='/cottages/:id' element={<BookedCottageDetails/>} />
            <Route path='/cottages/amenitydetails/:id' element={<CottageAmenityDetails/>} />
            <Route path='/cottages/timeselection/:id' element={<CottageTimeSelection/>} />
            {/* facilities */}
            <Route path='/facilities' element={<Facilities/>} />
            <Route path='/facilities/:id' element={<BookedFacilityDetails/>} />
            <Route path='/facilities/amenitydetails/:id' element={<AmenityDetails/>} />
            <Route path='/facilities/timeselection/:id' element={<TimeSelection/>} />
            {/* community */}
            <Route path='/community' element={<Community/>} />
            <Route path='/community/feedback' element={<Feedback/>} />
            <Route path='/community/notice' element={<Notice/>} />
            <Route path='/community/poll' element={<Poll/>} />
            <Route path='/community/meetings' element={<Meetings/>} />
            <Route path='/community/event' element={<Event/>} />
            <Route path='/community/event/:id' element={<EventDetails/>} />
            <Route path='/community/lostandfound' element={<LostAndFound/>} />
            <Route path='/community/lostandfound/:id' element={<LostItem/>} />
            {/* complaints */}
            <Route path='/complaints' element={<Complaints/>} />
            <Route path='/complaints/create' element={<ComplaintCreate/>} />
            {/* construction */}
            <Route path='/construction' element={<Construction/>} />
            <Route path='/construction/requestcontractor' element={<ConstructionRc/>} />
            <Route path='/construction/noc' element={<ConstructionNoc/>} />
            <Route path='/construction/guidelines' element={<ConstructionGuidelines/>} />
            <Route path='/construction/updates' element={<ConstructionUpdates/>} />
            <Route path='/construction/contractor/list' element={<ContractorsList/>} />
            <Route path='/construction/noc/list' element={<NOCList/>} />
            <Route path='/construction/request-contractor/list' element={<RequestContractorList/>} />
            {/* directory */}
            <Route path='/directory' element={<Directory/>} />
            <Route path='/directory/list' element={<DirectoryList/>} />
            <Route path='/directory/vehicledirectory' element={<VehicleScreen/>} />
            <Route path='/directory/guarddirectory' element={<GuardScreen/>} />
            <Route path='/directory/helperdirectory' element={<HelperScreen/>} />
            <Route path='/directory/emergencycontactdirectory' element={<EmergencyContactScreen/>} />
            <Route path='/directory/residentdirectory' element={<ResidentScreen/>} />
            <Route path='/directory/create' element={<DirectoryCreate />} />
            {/* accounts */}
            <Route path='/accounts/list' element={<Accounts/>} />
            <Route path='/accounts/balancesheet' element={<BalanceSheet/>} />
            <Route path='/accounts/incomeexpenditure' element={<IncomeExpenditure/>} />
            <Route path='/accounts/mainbalance' element={<MainBalance/>} />
            {/* notifications */}
            <Route path='/notifications' element={<Notifications/>} />
        </Routes>
    )
}

export default CustomRoutes