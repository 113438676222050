import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Visitors_logo from './Assets/visitors_logo.svg'
import Gatepass_logo from './Assets/gatepass_logo.svg'
import Dailyhelpers_logo from './Assets/dailyhelpers_logo.svg'
import Facilities_logo from './Assets/facilities_logo.svg'
import Accounts_logo from './Assets/accounts_logo.svg'
import Complaints_logo from './Assets/complaints_logo.svg'
import Delivery_logo from './Assets/delivery_logo.svg'
import Services_logo from './Assets/services_logo.svg'
import Community_logo from './Assets/community_logo.svg'
import Directory_logo from './Assets/directory_logo.svg'
import Homestore_logo from './Assets/homestore_logo.svg'
import Construction_logo from './Assets/construction_logo.svg'
import Cottage_logo from './Assets/cottage.svg';
import Bills_logo from './Assets/bills_logo.svg'
import Avatar from "@mui/material/Avatar";
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Header from './Header';
import { apiCall } from './Utility/utils';
import { HOME_BANNER_URL, CONTENT_IMG_URL } from './Utility/URL';
import { Mixpanel } from './Mixpanel';

const theme = createTheme({
    palette: {
      yellow: {
        main: '#F2CB02',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

  const indicatorStyles = {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
};

const styles = {
  iconAvatar:{
    background: "#F0F0F9",
    marginBottom: "0.5rem",
    alignSelf: "center",
  },
  iconContainer:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  iconAvatarHover:{
    alignSelf: "center",
    background: "#F0F0F9",
    marginBottom: "0.5rem",
    border: "1px solid #676AC0",
    boxShadow: "0px 3px 4px rgba(160, 163, 225, 0.35)",
  },
  iconContainerHover:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#676AC1",
  },
}



class Navigation extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        props: props,
        hover: 0,
        listLoading: true,
        baner_name: "",
        banner_desc: "",
        isCottageEnable: false,
      }
    }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    }
    
    var api_options = {
      config: {
        url: HOME_BANNER_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
         if(response.data.total > 0){
          const stateData = {
            listLoading:false,
            baner_name: response.data.data[0].name,
            banner_desc: response.data.data[0].desc,
            message: '',
            Home_header:  CONTENT_IMG_URL + response.data.data[0].image,
            isCottageEnable: response.data.cottages === 1
          }
          return this.setState({...stateData});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message, isCottageEnable: response.data.cottages === 1});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

    render(){
      // console.log(this.state)
        return (
          <>
            <Header societyName={localStorage.getItem("societyName")} gobackURL={localStorage.getItem("propertyUrl")}/>
            <div className="Home-header">
              <div style={{ width: "60%", padding: "0.5rem" }}>
                <p
                  style={{
                    color: "#F36B80",
                    fontFamily: "Jost",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26px",
                  }}
                >
                  {this.state.baner_name}
                </p>
                <p
                  style={{
                    fontFamily: "Jost",
                    fontSize: "11px",
                    lineHeight: "16px",
                    fontWeight: "400",
                  }}
                >
                  {this.state.banner_desc}
                </p>
              </div>
              <div style={{ width: "40%", display: "flex", justifyContent:"center" }}>
                {/* <Carousel
                  showThumbs={false}
                  showStatus={false}
                >
                  <div> */}
                    <img style={{width:"100%", alignSelf:"center"}} src={this.state.Home_header} />
                  {/* </div>                 
                </Carousel> */}
              </div>
            </div>

            <div className="Home-container">
              <Typography
                variant="h5"
                sx={{ color: "#C7A603", fontFamily: "Jost", marginTop: "1rem" }}
              >
               
                Members:
              </Typography>
              <div className="Card-model">
                <a 
                  href="/gatepass/list" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track("fm_gatepass_clicked")
                  }} 
                >
                  <div
                    style={this.state.hover === 1 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:1})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 1 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Gatepass_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Gate pass</p>
                  </div>
                </a>
                <a 
                  href="/dailyhelpers/list" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track("fm_dailyhelper_clicked")
                  }}
                >
                  <div
                    style={this.state.hover === 2 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:2})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 2 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Dailyhelpers_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Daily helper</p>
                  </div>
                </a>
                
                <a 
                  href="/visitor/list" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track("fm_visitor_clicked");
                  }}  
                >
                  <div
                    style={this.state.hover === 3 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:3})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 3 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Visitors_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Visitors</p>
                  </div>
                </a>
                
              </div>

              {/* ========================== Stay: Start ========================== */}
              
                {/* ============== Guest Rooms: Start ============== */}
                  { this.state.isCottageEnable && (
                    <>
                      <Typography
                        variant="h5"
                        sx={{ color: "#C7A603", fontFamily: "Jost", marginTop: "1rem" }}
                      >
                        Stay:
                      </Typography>
                      <div className="Card-model" style={{ "justify-content": this.state.isCottageEnable ? "space-between" : "" }}>
                        <a href="/cottages" onClick={()=>{ Mixpanel.track('fm_cottages_clicked');}} style={{ all: "unset" }} >
                          <div
                            style={this.state.hover === 14 ? styles.iconContainerHover : styles.iconContainer}
                            onMouseEnter={() => this.setState({hover:14})}
                            onMouseLeave={() => this.setState({hover:0})}
                          >
                            <Avatar
                              sx={this.state.hover === 14 ? styles.iconAvatarHover : styles.iconAvatar}
                            >
                              <img src={Cottage_logo} />
                            </Avatar>
                            <p className="Home-icon-button-text">Guest Rooms</p>
                          </div>
                        </a>
                      </div>
                    </>
                  )}
                {/* =============== Guest Rooms: End =============== */}
              
              {/* =========================== Stay: End =========================== */}

              <Typography
                variant="h5"
                sx={{ color: "#C7A603", fontFamily: "Jost", marginTop: "1rem" }}
              >
                Engage:
              </Typography>
              <div className="Card-model" style={{ "justify-content": this.state.isCottageEnable ? "space-between" : "" }}>
                <a href="/facilities" 
                  onClick={()=>{
                    Mixpanel.track('fm_facilities_clicked');
                  }}  
                  style={{ all: "unset" }}
                >
                  <div
                    style={this.state.hover === 4 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:4})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 4 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Facilities_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Facilities</p>
                  </div>
                </a>

                <a 
                  href="/community" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track('fm_community_clicked');
                  }} 
                >
                  <div
                    style={this.state.hover === 5 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:5})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 5 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Community_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Community</p>
                  </div>
                </a>
                <a 
                  href="/complaints" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track('fm_complaints_clicked');
                  }} 
                >
                  <div
                    style={this.state.hover === 6 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:6})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 6 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Complaints_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Complaints</p>
                  </div>
                </a>
              </div>
              <Typography
                variant="h5"
                sx={{ color: "#C7A603", fontFamily: "Jost", marginTop: "1rem" }}
              >
                Information:
              </Typography>
              <div className="Card-model">
                <a 
                  href="/directory" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track('fm_directory_clicked');
                  }} 
                >
                  <div
                    style={this.state.hover === 7 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:7})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 7 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Directory_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Directory</p>
                  </div>
                </a>
                <a 
                  href="/construction" style={{ all: "unset" }}
                  onClick={()=>{
                    Mixpanel.track('fm_construction_clicked');
                  }}   
                >
                  <div
                    style={this.state.hover === 8 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:8})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 8 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Construction_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">
                      Construction Information
                    </p>
                  </div>
                </a>
              </div>
              <Typography
                variant="h5"
                sx={{ color: "#C7A603", fontFamily: "Jost", marginTop: "1rem", display: 'none' }}
              >
                Payments:
              </Typography>
              <div className="Card-model" style={{ display: 'none' }}>
                <a href="/visitor/list" style={{ all: "unset" }}>
                  <div
                    style={this.state.hover === 9 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:9})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 9 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Accounts_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Accounts</p>
                  </div>
                </a>
                <a href="/visitor/list" style={{ all: "unset" }}>
                  <div
                    style={this.state.hover === 10 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:10})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 10 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Bills_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Bills & Payments</p>
                  </div>
                </a>
              </div>
              <Typography
                variant="h5"
                sx={{ color: "#C7A603", fontFamily: "Jost", marginTop: "1rem", display: 'none' }}
              >
                Others:
              </Typography>
              <div className="Card-model" style={{ display: 'none' }}>
                <a href="/facilities/list" style={{ all: "unset" }}>
                  <div
                    style={this.state.hover === 11 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:11})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 11 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Delivery_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Delivery</p>
                  </div>
                </a>
                <a href="/visitor/list" style={{ all: "unset" }}>
                  <div
                    style={this.state.hover === 12 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:12})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 12 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Services_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Services</p>
                  </div>
                </a>
                <a href="/visitor/list" style={{ all: "unset" }}>
                  <div
                    style={this.state.hover === 13 ? styles.iconContainerHover : styles.iconContainer}
                    onMouseEnter={() => this.setState({hover:13})}
                    onMouseLeave={() => this.setState({hover:0})}
                  >
                    <Avatar
                      sx={this.state.hover === 13 ? styles.iconAvatarHover : styles.iconAvatar}
                    >
                      <img src={Homestore_logo} />
                    </Avatar>
                    <p className="Home-icon-button-text">Homestore</p>
                  </div>
                </a>
              </div>
            </div>
          </>
        );
    }
}

export default Navigation
