import React,{ Component } from 'react'
import SearchIcon from '../Assets/search.svg'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Avatar, Typography } from '@mui/material';
import Avatar_png from '../Assets/Ellipse_3.png'
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '../Assets/phone-icon.svg'
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { apiCall, MyLoader } from '../Utility/utils';
import { DIRECTORY_HELPERS_LIST_URLS } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D2D2D2',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop:'1rem',
  // marginRight:'1rem',
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  height:'48px',
  flexGrow:1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const styles = {
  container: {
    // backgroundColor: '#F7F7F7',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  card: {
    marginTop:'1rem',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    padding:'0.2rem 1rem'
  },
  call: {
    fontSize:'14px',
    lineHeight:'36px',
    fontWeight:'600 semi bold',
    color:'#676AC1'
  },
  show: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'400',
    color:'#676AC1',
    textDecoration:'underline',
  },
  cardHead: {
    display:'flex',
    justifyContent:'space-between',
  },
  cardBodyText16: {
    fontSize:'16px',
    fontWeight:'400',
    lineHeight:'36px',
    color:'#888888'
  },
  cardBodyText14: {
    fontSize:'14px',
    fontWeight:'400',
    lineHeight:'36px',
    color:'#888888'
  },
}

class List extends Component {
  constructor(){
    super();
    this.state = {
      lists: [],
      search: "",
      listLoading: true
    }
    this.toggler = {};
  }

  search = (value) => {
    clearTimeout(this.timeout);
    this.setState({search: value}, () => {
      this.timeout = setTimeout(() => {
        this.lists()
      }, 250);
    });
    //this.setState({search: value}, ()=>this.lists(value));
  }

  async lists() {
    let formData = {};
    formData.token =  localStorage.getItem("societyToken");
    formData.search = this.state.search;
            
    var api_options = {
      config: {
        method:'post',
        url: DIRECTORY_HELPERS_LIST_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{

        if(response.data.data.length > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  toggle_div = (helper) => {    
    this.toggler['helper_' + helper.id] = !this.toggler['helper_' + helper.id];
    this.setState({render: true});
  }

  renderHelpers = (helper, idx ) => {
    if(typeof this.toggler['helper_' + helper.id] == "undefined"){
      this.toggler['helper_' + helper.id] = false;  
    }
    var display_prop = this.toggler['helper_' + helper.id] == false ? 'none' : '';
    var display_text = this.toggler['helper_' + helper.id] == false ? 'show more' : 'show less';

    const {name, company_name, cat_name, address, contact, email, notes} = helper;
    return (
      <div style={styles.card} key={idx}>
        <div style={styles.cardHead}>
          <div style={{display:'flex'}}>
            <Avatar><PersonIcon/></Avatar>
            <div style={{marginLeft:'0.5rem'}}>
              <p style={{fontSize:'16px',lineHeight:'36px',fontWeight:'400',color:'#17171C'}}>{name} ({company_name})</p>
              <p style={{fontSize:'13px',lineHeight:'15px',fontWeight:'400',color:'#888888'}}>{cat_name}</p>
            </div>
          </div>
          <a href={"tel:" + contact} style={styles.call}><img src={PhoneIcon} style={{marginBottom:'0.3rem'}}/>Call</a>
        </div>
        <Grid container spacing={1} style={{marginTop:'0.5rem', display: display_prop}}>
          <Grid item xs={4}>
            <p style={styles.cardBodyText16}>Contact:</p>
          </Grid>
          <Grid item xs={8}>
            <p style={styles.cardBodyText14}>{contact}</p>
          </Grid>
          <Grid item xs={4}>
            <p style={styles.cardBodyText16}>Email id :</p>
          </Grid>
          <Grid item xs={8}>
            <p style={styles.cardBodyText14}>{email}</p>
          </Grid>
          <Grid item xs={4}>
            <p style={styles.cardBodyText16}>Address :</p>
          </Grid>
          <Grid item xs={8}>
            <p style={styles.cardBodyText14}>{address}</p>
          </Grid>          
        </Grid>
        <p onClick={ () => this.toggle_div(helper)} style={styles.show}>{display_text}</p>
      </div>
    );
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderHelpers}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }
  
  render(){
    return (
      <>
      <Header societyName="Helper Directory"/>
      <div style={styles.container}>
        <Search>
          <SearchIconWrapper>
            <img alt='search' src={SearchIcon}/>
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            value={this.state.search}
            onChange={(e)=>{
              Mixpanel.track("fm_directory_helperdirectory_searched", {"search_text": e.target.value});
              this.search(e.target.value)}}
            sx={{}}
          />
        </Search>
        {this.load_main_view()}
        {/* <a href="/directory/create" style={{all:'unset'}}>
          <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
            <AddIcon size="large" fontSize="large" />
          </Fab>
        </a> */}
      </div>
      </>
    )
  }
}

export default List