import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import img from '../Assets/party_hall_big_img.png'
import './index.css'
import Accordion from './Accordion';
import { FACILITY_DETAILS_URL, FACILITY_IMG_URL, FACILITY_DEFAULT_IMG_URL } from '../Utility/URL';
import { apiCall, format_time_str, MyLoader } from '../Utility/utils';
import Header from '../Header';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

export default class BookedFacilityDetails extends Component {
  constructor(){
    super();
    this.state = {
      listLoading: true,
      details: {
        timing: [{}],
        price: {}
      }
    }
  }

  async details(){
    let formData = {
      id: parseInt(window.location.href.split("facilities/")[1]),
      token: localStorage.getItem("societyToken")
    };

    let api_options = {
      config: {
        method: 'post',
        url: FACILITY_DETAILS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        if(response.data.property_info.length>0){
          this.setState({listLoading:false, details:response.data.property_info[0], message: ''});
        }
        else{
          this.setState({listLoading:false, details: {}, message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.details();
  };

  load_main_view  = () => {
    if(this.state.listLoading === true){
      return <MyLoader />
    }
    if(typeof this.state.message != "undefined" && this.state.message != ""){
        return (<div className="container">{this.state.message}</div>)
    }

    const {booking_details_image, name, price, timing, id, notes, rules, facility, capacity} = this.state.details;
      
    return (
     <div style={{minHeight:'90vh', position: 'relative'}}>
        <img src={Boolean(booking_details_image)?FACILITY_IMG_URL+booking_details_image: FACILITY_DEFAULT_IMG_URL} width="100%" alt="party_hall_big_img" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderBottom: " 2px solid #F8F8F8",
          }}
        >
          <CardContent
            style={{ flex: "1 0 auto", padding: "1rem 1rem 2rem 1rem" }}
          >
            <Typography
              component="div"
              variant="h6"
              sx={{ fontFamily: "Jost" }}
            >
              {name}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ fontFamily: "Jost" }}
            >
              Open: {format_time_str("2022-01-01, " + timing[0].time_from)}-{format_time_str("2022-01-01, " + timing[0].time_to)}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ fontFamily: "Jost" }}
            >
              Refundable Deposit : "Deposit Amount"
            </Typography>
          </CardContent>
        </Box>
        <Accordion
          title="Description"
          details={notes}
        />
        <Accordion
          title="Facilities"
          details={facility}
        />
        <Accordion
          title="Capacity"
          details={capacity}
        />
        <Accordion
          title="Rules & Regulation"
          details={rules}
        />
        <div style={{height:'75px'}}></div>
        <div style={{backgroundColor:'#FFFEF6', width:'100%', position: 'absolute',bottom:0,right: 0,padding:'1rem 2rem 1rem 2rem',display:'flex',justifyContent:'space-between'}} >
          <div>
            <Typography
              component="div"
              sx={{ fontFamily: "Jost" }}
            >
            {price.length === 1 ? "₹"+price[0].amount: ""}
            </Typography>
            <Typography
              color="text.secondary"
              variant="body1"
              sx={{ fontFamily: "Jost" }}
            >
            {price.length === 1 ? price[0].name: ""}
            
            </Typography>
          </div>
          {
            this.state.details.is_booking_allow === 0 || <a href={"/facilities/timeselection/"+id} style={{all:'unset'}}><Button sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',fontFamily:'Jost'}} variant="contained">Choose Time Slot</Button></a>
          }
        </div>
      </div>
    )
  }

  render() {

    
    return (
      <>
      <Header societyName="Facility Details"/>
        {this.load_main_view()}
      </>
    );
  }
}
