import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, IconButton } from '@mui/material';
import personsvg from '../Assets/Group_12115.svg'
import './index_v2.css'
import { useParams, useNavigate   } from 'react-router-dom'
import { apiCall, SweetAlert } from '../Utility/utils';
import { DAILYHELPER_STAFF_SAVE_URL, DAILYHELPER_STAFF_DETAILS_URL, DAILY_HELPER_IMG_URL } from '../Utility/URL';
import Header from '../Header';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Mixpanel } from '../Mixpanel';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;

  & .${badgeUnstyledClasses.badge} {
    z-index: auto;
    min-width: 30px;
    height: 30px;
    padding: 5px 0px;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
    background: #888888;
    border-radius: 15px;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    bottom: -5;
    right: -5;
    transform: translate(320%, -70%);
    transform-origin: 100% 0;
  }
`;
class DailyhelperCreate extends Component {
  constructor(){
    super();
    this.state = {  
      selectedFile: null,        
      formData:{
        first_name: "",
        last_name: "",
        contact: "",
        email: "",
        image: "",
        designation_id: [],
        address: ""
      },
      open: false,
      preload_image: "",
      first_name_error: "",
      last_name_error: "",
      contact_error: "",
      email_error: "",
      image_error: "",
      designation_id_error: "",
      temp: [269, 266],
      file_name: "",
      formDesignationData: {token: localStorage.getItem("societyToken")},
      designation: []
    };
  }

  handleClose = () => this.setState(prevState => ({...prevState, open: false}));

  handleOpen = () => this.setState(prevState => ({...prevState, open: true}));

  validate = () => {
    var errors = {
      first_name_error: this.state.formData.first_name === "" ? "This field is required." : "",
      last_name_error: this.state.formData.last_name === "" ? "This field is required." : "",
      contact_error: this.state.formData.contact === "" ? "This field is required." : this.state.formData.contact.length == 10 && (/^[0-9\b]+$/).test(this.state.formData.contact) ? "" : "Contact Number is not valid.",
      email_error: this.state.formData.email !== "" ? (/$^|.+@.+..+/).test(this.state.formData.email) ? "" : "Email is not valid." : "",
      designation_id_error: this.state.formData.designation_id === "" ? "This field is required." : "",
      // image_error: this.state.formData.image === "" ? "This field is required." : ""
    }
    this.setState(prevState=> (errors));
    return !Object.values(errors).some(x=>Boolean(x))
  
  }

  async preLoad() {
    let daily_helper_id = this.props.params.id
    let api_options = {
      config: {
        method: 'post',
        url: DAILYHELPER_STAFF_DETAILS_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          id: parseInt(daily_helper_id),
          token: localStorage.getItem("societyToken")
        }),
      },
      successCallback: (response)=>{
        if(response.data.designation.length > 0){
          this.setState({listLoading:false, message: '', designation:response.data.designation});
        }

        if(response.data.data.details != ""){

          const {image, first_name, last_name, contact, email, designation_id, address, services} = response.data.data.details;
          this.setState({preload_image: image});
          this.setState(prevState => ( { formData: {...prevState.formData, 
              id: daily_helper_id,
              // image: image,
              first_name: first_name,
              last_name: last_name,
              contact: contact,
              email: email,
              designation_id: services.map(x=>x.id),
              address: address
          } }) )
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    }
    apiCall(api_options);
  }

  componentDidMount() {
    console.log(this.props.params);
    this.preLoad();


      var currentdate = new Date(); 
    const script = document.createElement("script");
    script.src = "https://fm-admin.hoabl.in/front/external.js?t=" + currentdate.getTime();
    script.async = true;
    document.body.appendChild(script);

    window.gatepass_obj = this;

  };

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  async save() {
    const data = new FormData();
    for (let k in this.state.formData) {
      if(k === 'image' && this.state.formData[k] === ""){
        console.log("don't have image")
      }else{
        if(k === "designation_id"){
          data.append(k, JSON.stringify(this.state.formData[k]))
        }
        else{
          data.append(k, this.state.formData[k]);
        }
      }
    }
    
    data.append('token', localStorage.getItem("societyToken"));
    data.append("file_name", this.state.file_name);

    let api_options = {
      config: {
        method: 'post',
        url: DAILYHELPER_STAFF_SAVE_URL,
        headers:{
          'Content-Type': 'multipart/form-data'
        },
        data: data,
      },
      successCallback: (response)=>{
        console.log("Sucess", response);
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator(-1)
        }
        SweetAlert(config);
        
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "OK",
          cancel_btn_show: false,
          icon: 'error'
        }
        SweetAlert(config);
      }
    }
    apiCall(api_options);

  }

  sendUploadAction = () => {
      //alert("ca");
     var shareObj = { 
      "type": "image",
      "page_name": "dailyhelper"                                  
      }; 

      console.log(shareObj);
       // window.getContactListFromNative('test', 'g');
       
     //if(typeof JSBridge != "undefined"){
       
        window.JSBridge.sendUploadActionInNative(JSON.stringify(shareObj)); 
     //}
     
  }

  submitForm = (e) => {
    e.preventDefault()
    if(this.validate()){
      this.save();
    }
  }

  render() {
    return (
      <>
      <Header societyName={"Create Daily Helper"}/>
      <div className="container">
        <div style={{padding:'2rem', display:'flex', justifyContent:'center', width:'100%'}}>
          <input type='file' id="sImage" name="image" accept="image/*" capture="camera" style={{display:"none"}}
          onChange={ (e) => this.setData(e.target.name, e.target.files[0])}
          ></input>
          <label htmlFor="sImage">
            {/* <img src={Boolean(this.state.preload_image) ? DAILY_HELPER_IMG_URL+this.state.preload_image : personsvg} style={{width: 150, height: 150}} />   */}
            <StyledBadge badgeContent={<PhotoCameraIcon fontSize="small" onClick={ () => { this.sendUploadAction() }} />} >
              <Avatar src={Boolean(this.state.preload_image) && DAILY_HELPER_IMG_URL + this.state.preload_image} style={{textDecoration: 'none'}} sx={{ width: 140, height:140 }}><PersonIcon sx={{fontSize:'5rem'}}/></Avatar>
            </StyledBadge>
          </label>
          {/* {this.state.image_error !== "" && <FormHelperText error={this.state.image_error !== "" ? true : false}>{this.state.image_error}</FormHelperText>} */}
        </div>

        

        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" 
          label="First name" variant="standard" name="first_name"
          value={this.state.formData.first_name}
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.first_name_error !== "" ? true : false}
          helperText={this.state.first_name_error}/>

        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" label="Last name" 
          variant="standard" name="last_name"
          value={this.state.formData.last_name}
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.last_name_error !== "" ? true : false}
          helperText={this.state.last_name_error}/>


        <TextField style={{marginBottom:'1.5rem'}} 
          fullWidth id="standard-basic" 
          label="Contact Number" variant="standard" name="contact"
          // inputProps={{ inputMode: 'numeric'}}
          value={this.state.formData.contact}
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.contact_error !== "" ? true : false}
          helperText={this.state.contact_error}/>

        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" label="Email id (optional)" 
          variant="standard" name="email"
          value={this.state.formData.email}
          onChange={(e) => this.setData(e.target.name, e.target.value.trim())}
          error={this.state.email_error !== "" ? true : false}
          helperText={this.state.email_error}/>
        
        <FormControl fullWidth variant="standard" sx={{ minWidth: 120, marginBottom:'1.5rem' }}
          {...(this.state.designation_id_error !== "" && {error:true})}>
          <InputLabel id="demo-simple-select-standard-label">Services</InputLabel>
          <Select
            multiple={true}
            open={this.state.open}
            // value = {this.state.temp}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Gate pass for"
            name="designation_id"
            value={this.state.formData.designation_id}          
            onChange={(e) => this.setData(e.target.name, e.target.value)}
            >
              <Stack sx={{padding:'0.2rem 1rem', justifyContent:'space-between'}} direction="row" spacing={2}>
                <Button sx={{color: "#676AC0",border: '2px solid #676AC0'}} variant='outlined' 
                  onClick={() => {
                    this.setState(prevState => ({...prevState, formData: {...prevState.formData, designation_id: []}}));
                    this.handleClose();
                  }}
                >Close</Button>
                <Button sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)'}} variant='contained' onClick={()=> this.handleClose()}>Submit</Button>
              </Stack>
            {
              (() => {
                let container = [];
                this.state.designation.forEach((val, index) => {
                  container.push(<MenuItem key={val.id} value={val.id}>{val.name} </MenuItem>)
                });
                return container;
              })()
            }
            
          </Select>
          {this.state.designation_id_error !== "" && <FormHelperText error={this.state.designation_id_error !== "" ? true : false}>{this.state.designation_id_error}</FormHelperText>}
        </FormControl>
        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" label="Address (optional)" variant="standard" 
          name="address"
          value={this.state.formData.address}
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          />

        <Button sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)', marginBottom:'2rem'}} fullWidth  variant="contained" type="submit" 
          onClick={(e)=>{
            this.submitForm(e);
            Mixpanel("fm_dailyhelper_add_save")
          }}>Save</Button>
      </div>
      </>
    )
  }
}


function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(DailyhelperCreate);

