import React, { Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Avatar_png from '../Assets/Ellipse_3.png'
import Grid from '@mui/material/Grid';
import SandClock from '../Assets/sand-clock.svg'
import { apiCall, format_date_time_str, MyLoader, SweetAlert } from '../Utility/utils';
import { COMMUNITY_POLL_LIST_URLS, COMMUNITY_POLL_SAVE_URLS } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Button from '@mui/material/Button';
import moment from 'moment';
import { style } from '@mui/system';
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%'
  },
  card: {
    backgroundColor: "#FFFFFF",
    // marginTop: "2rem",
    padding: "0.5rem 1rem 1rem 1rem",
    borderTop: "2rem solid #F7F7F7"
  },
  cardHeaderP1: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'500',
    fontFamily:'Jost'
  },
  cardHeaderP2: {
    fontSize:'13px',
    fontWeight:'400',
    fontFamily:'Jost',
    color:'#9D9EB1'
  },
  cardBody: {
    fontFamily: 'Jost',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '26px'
  },
  divider: {
    height: '2px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  customButton: {
    border: '1px solid #676AC1',
    borderRadius:'3px',
    blend:'pass through',
    display:'flex',
    justifyContent:'center',
    color:'#676AC1',
    backgroundColor:'#FFFFFF',
    padding:'0.5rem',
    marginTop: '0.5rem'
  },
  bottomP: {
    color:'#888888',
    fontFamily:'Jost',
    fontSize:'15px',
    lineHeight:'26px',
    fontWeight:'500',
    marginTop:'1rem' 
  },
  majorityPoll: {

  },
  regularPoll: {

  },
  selctedVote: {
    // border: '1px solid #676AC1',
    borderRadius:'3px',
    blend:'pass through',
    display:'flex',
    justifyContent:'center',
    color:'#FFFFFF',
    backgroundColor:'#676AC1',
    padding:'0.5rem',
    marginTop: '0.5rem'
  }
}

export default class Poll extends Component {
  constructor() {
    super();
    this.state = {
      lists: [],
      voted: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: COMMUNITY_POLL_LIST_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  async voting(id) {
    if((Boolean(this.state.voted[id]) && this.state.voted[id].length>0)==false){
      return false
    }
    var formData = {
      token: localStorage.getItem("societyToken"),
      id: id,
      selected_options: JSON.stringify(this.state.voted[id])
    };

    var api_options = {
      config: {
        method:'post',
        url: COMMUNITY_POLL_SAVE_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        console.log("success");
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>window.location.reload()
        }
        SweetAlert(config);
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  setVote = (id, value) => {
    if (id in this.state.voted){
      this.state.voted[id].indexOf(value)===-1 ? this.state.voted[id].push(value) : this.state.voted[id].splice(this.state.voted[id].indexOf(value), 1);
      return this.setState(prevState => ( { voted: {...prevState.voted, [id]: this.state.voted[id]} }) )
    }
    else{
      return this.setState(prevState => ( { voted: {...prevState.voted, [id]: [value]} }) )
    }
  }

  renderPolls = (poll, idx ) => {
    const {name, created_at, options, selected_options, created_name, id, vote_result} = poll;
    return (
      <div style={styles.card} key={idx}>
        <div style={{ display: "flex" }}>
          <Avatar alt="avatar" />
          <div style={{ marginLeft: "0.8rem" }}>
            <p style={styles.cardHeaderP1}>{created_name}</p>
            <p style={styles.cardHeaderP2}>{format_date_time_str(created_at)}</p>
          </div>
        </div>
        <p style={styles.cardBody}>{name}</p>
        {
          (() => {
            let container = [];
            var voting = id in this.state.voted? this.state.voted[id]:[];
            options?.forEach((val, index) => {
              container.push(
              <div 
                onClick={(e)=>{
                  if(selected_options.length === 0){
                    this.setVote(id, val.value);
                  }
                }} 
                style={selected_options.indexOf(val.value) !==-1 || voting.indexOf(val.value) !==-1 ? styles.selctedVote: styles.customButton} 
                value={val.value} key={index}
              > 
                <p>{val.label}{vote_result?.length>0 ? (": " + vote_result[index].count) : ""}</p>
              </div>)
            });
            return container;
          })()
        }
        {selected_options.length === 0 && <Button onClick={()=>{
          Mixpanel.track("fm_community_poll_vote_now_clicked", poll);
          this.voting(id)}} sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',marginTop:'1rem',fontFamily:'Jost'}} fullWidth  variant="contained">Vote Now</Button>}

        {/* <div style={{display:'flex', justifyContent:'space-between'}}>
          <p style={styles.bottomP}><img src={SandClock} width='24px' height='24px' />48:00:00 hrs left</p>
          <p style={styles.bottomP}>73 votes</p>
        </div> */}
      </div>
    );
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderPolls}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render() {
    return (
      <>
      <Header societyName="Poll"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    );
  }
}