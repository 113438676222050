import React, { Component } from 'react'
import Box from '@mui/material/Box';
import { CardActions, Grid } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { COTTAGE_BOOKING_DETAILS_URL } from '../Utility/URL';
import { apiCall, format_date_time_str } from '../Utility/utils';
import Header from '../Header';
import CancelBooking from './CancelBooking';
import './index.css'

export default class BookedCottageDetails extends Component {
  constructor(){
    super();
    this.state = {
      details: {
        timing: [{}],
        price: {},
        facility_messages: []
      },
      cancel_reason: {},
    }
  }

  async details(){
    let formData = {
      id: parseInt(window.location.href.split("cottages/")[1]),
      token: localStorage.getItem("societyToken")
    };

    let api_options = {
      config: {
        method: 'post',
        url: COTTAGE_BOOKING_DETAILS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        console.log("============== response ============", response.data)
        this.setState({details:response.data.booking_info, cancel_reason: response.data.cancel_reason});
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.details();
  };

  load_main_view  = () => {
    const detailsData = {
      ...this.state.details,
      transaction_status: "Successful"
    }

    const bookinDetailsTitle = {
      booking_id: "Booking ID",
      user_name: "Name",
      contact: "Contact",
      start_date: "Check-in",
      end_date: "Check-out",
      adult_count: "Adults",
      child_count: "Children",
      qty: "Rooms",
      base_amount: "Amount",
      cgst_amount: "CGST",
      sgst_amount: "SGST",
      total_amount: "Total Amount",
      transaction_status: "Transaction Status",
    }

    const formatData = (field) => {
      if(field === "start_date" || field === "end_date") {
        return format_date_time_str(detailsData[field]);
      }

      if (field === "base_amount" || field === "total_amount") {
        return parseFloat(detailsData[field]).toFixed(2);
      }

      return detailsData[field]
    }
      
    return (
    <div style={{minHeight:'90vh', position: 'relative'}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //borderBottom: " 2px solid #F8F8F8",
            m: 1
          }}
        >
          <CardContent>
            <Grid container >
              {/* ========================== START ========================== */}
              <Grid item xs={7} sx={{mb: 1}}>
                <Typography component="div" variant="h6" sx={{ fontFamily: "Jost" }}>
                  {detailsData.name}
                </Typography>
              </Grid>

              <Grid item xs={5} style={{textAlign: "-webkit-right"}}>
                <div style={{backgroundColor:'#CCFBD7', width:'fit-content', padding:"0.1rem 0.5rem"}}>
                  <p style={{color:'#02811B',fontFamily:'Jost'}}>{detailsData.status_name}</p>
                </div>
              </Grid>
              {/* ========================== END ========================== */}

              {Object.keys(bookinDetailsTitle).map((field) => (
                <React.Fragment key={field}>
                  <Grid item xs={5} sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                      {bookinDetailsTitle[field]}:
                    </Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                      {formatData(field)}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}

              {detailsData.facility_messages.length > 0 && (
                <>
                  <Grid item xs={12} sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                      <strong>Messages:</strong>
                    </Typography>
                  </Grid>

                  {detailsData.facility_messages.map(({desc, created_name, created_at, keyname_1, keyname_2}) => (
                    <>
                      <Grid item xs={5}>
                        <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                          {keyname_1}:
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontFamily: "Jost" }}>
                          {desc}
                        </Typography>
                      </Grid>

                      <Grid item xs={5} sx={{mb: 2}}>
                        <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                          {keyname_2}: 
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Typography variant="subtitle1" color="text.secondary" sx={{ fontFamily: "Jost" }}>
                          {format_date_time_str(created_at)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{mb: 2}}>
                        <div style={{  borderBottom: " 2px solid #F8F8F8"}}></div>
                      </Grid>
                    </>
                  ))}

                </>
              )}
            </Grid>

          </CardContent>

          {( detailsData.is_cancel_allow === 1 ) && (
            <CardActions width="100%">
              <CancelBooking reasons={this.state.cancel_reason} id={detailsData.facility_id}/>
            </CardActions>
          )}
        </Box>
      </div>
    )
  }

  render() {

    
    return (
      <>
      <Header societyName="Booking Details"/>
        {this.load_main_view()}
      </>
    );
  }
}
