import React,{ Component } from 'react'
import Grid from '@mui/material/Grid';
import { apiCall, format_time_str, format_date_str, MyLoader } from '../Utility/utils';
import { CONSTRUCTION_PROJECT_UPDATE_STATUS_LIST_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import moment from 'moment';
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    // padding:'1rem',
    fontFamily:'Jost',
  },
  card: {
    borderTop:'1rem solid #F4F4F7',
  },
  cardHead: {
    backgroundColor:'#E1E1F2',
    padding:'0.3rem 2rem'
  },
  cardBody: {
    padding:'0.5rem 2rem'
  },
  cardBodyText: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '36px'
  },
}

class ConstructionUpdates extends Component {

  constructor() {
    super();
    this.state = {
      lists: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: CONSTRUCTION_PROJECT_UPDATE_STATUS_LIST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderProjectUpdateStatus = (status, idx ) => {
    const {date, time, desc} = status;
    return (
      <div style={styles.card} key={idx}>
        <div style={styles.cardBody}>
          <Grid container spacing={0} style={{marginTop:'0.5rem'}}>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Date :</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{format_date_str(date)}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Time :</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{format_time_str("2022-01-01, "+time)}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Description:</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{desc}</p>
            </Grid>             
          </Grid>
        </div>
      </div>
    );
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderProjectUpdateStatus}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }
  
  render(){
    return (
      <>
      <Header societyName="Construction Status"/>
      <div>
        {this.load_main_view()}
        {/* <div style={styles.card}>
          <div style={styles.cardHead}>
            <p style={{fontSize:'16px', lineHeight:'36px', fontWeight:'400', color:'#17171C'}}>Contractor Finalised</p>
          </div>
          <div style={styles.cardBody}>
            <Grid container spacing={0} style={{marginTop:'0.5rem'}}>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Date :</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>15 March 2022</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Time :</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>10:00 AM</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Name of Contractor:</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Anil Kapoor</p>
              </Grid>             
            </Grid>
          </div>
        </div>
        <div style={styles.card}>
          <div style={styles.cardHead}>
            <p style={{fontSize:'16px', lineHeight:'36px', fontWeight:'400', color:'#17171C'}}>Request Accepted</p>
          </div>
          <div style={styles.cardBody}>
            <Grid container spacing={0} style={{marginTop:'0.5rem'}}>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Date :</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>15 March 2022</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Time :</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>10:00 AM</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Name of Contractor:</p>
              </Grid>
              <Grid item xs={6}>
                <p style={styles.cardBodyText}>Anil Kapoor</p>
              </Grid>             
            </Grid>
          </div>
        </div> */}
      </div>
      </>
    )
  }
}

export default ConstructionUpdates