import React, { Component } from 'react'
import Header from '../Header'
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    backgroundColor: '#F4F4F7',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost',
    minHeight:'100vh',
  },
  menuList: {
    marginTop:'1rem',
    background:'#FBFBFD',
    boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.1)',
    padding:'0.7rem 1rem',
    borderRadius:'5px',
  },
  menuText: {
    fontSize:'16px',
    fontWeight:'400',
    lineHeight:'36px'
  },
  menuListHover: {
    marginTop:'1rem',
    background:'#676AC0',
    boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.1)',
    padding:'0.7rem 1rem',
    borderRadius:'5px',
  },
  menuTextHover: {
    fontSize:'16px',
    fontWeight:'600',
    lineHeight:'36px',
    color:'#F9F9F9'
  }
}

export class Construction extends Component {
  constructor(props){
    super(props);
    this.state = {
      props: props,
      hover: 0
    }
  }

  render() {
    return (
      <>
      <Header societyName="Construction"/>
      <div style={styles.container}>
        {/* <a href="/construction/requestcontractor" style={{ all: "unset" }}>
          <div style={styles.menuListHover}>
            <p style={styles.menuTextHover}>Request Contractor</p>
          </div>
        </a> */}
        <a
          onClick={()=>{
            Mixpanel.track("fm_construction_request_contractor_clicked");
          }} 
          href="/construction/requestcontractor" style={{ all: "unset" }}>
          <div 
            style={this.state.hover === 1 ? styles.menuListHover : styles.menuList}
            onMouseEnter={() => this.setState({hover:1})}
            onMouseLeave={() => this.setState({hover:0})}
          >
            <p style={this.state.hover === 1 ? styles.menuTextHover : styles.menuText}>Request Contractor</p>
          </div>
        </a>
        <a
          onClick={()=>{
            Mixpanel.track("fm_construction_noc_clicked");
          }} 
          href="/construction/noc/list" style={{ all: "unset" }}>
          <div 
            style={this.state.hover === 2 ? styles.menuListHover : styles.menuList}
            onMouseEnter={() => this.setState({hover:2})}
            onMouseLeave={() => this.setState({hover:0})}
          >
            <p style={this.state.hover === 2 ? styles.menuTextHover : styles.menuText}>NOC</p>
          </div>
        </a>
        <a 
          onClick={()=>{
            Mixpanel.track("fm_construction_guideline_clicked");
          }}
          href="/construction/guidelines" style={{ all: "unset" }}>
          <div
            style={this.state.hover === 3 ? styles.menuListHover : styles.menuList} 
            onMouseEnter={() => this.setState({hover:3})}
            onMouseLeave={() => this.setState({hover:0})}
          >
            <p style={this.state.hover === 3 ? styles.menuTextHover : styles.menuText}>Construction Guidelines</p>
          </div>
        </a>
        <a 
          onClick={()=>{
            Mixpanel.track("fm_construction_update_clicked");
          }}
          href="/construction/updates" style={{ all: "unset" }}>
          <div
            style={this.state.hover === 4 ? styles.menuListHover : styles.menuList} 
            onMouseEnter={() => this.setState({hover:4})}
            onMouseLeave={() => this.setState({hover:0})}
          >
            <p style={this.state.hover === 4 ? styles.menuTextHover : styles.menuText}>Construction Updates</p>
          </div>
        </a>
      </div>
      </>
    )
  }
}

export default Construction