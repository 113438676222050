import React, { Component } from 'react'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Card } from 'react-bootstrap';
import Avatar from '@mui/material/Avatar';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import FlatList from 'flatlist-react'
import './index_v2.css'
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import moment from 'moment' 
import { GATEPASS_IMG_URL, GATEPASS_LIST_URL, GATEPASS_EXPIRE_URL } from '../Utility/URL';
import { apiCall, MyLoader, SweetAlert } from '../Utility/utils';
import Header from '../Header';
import {
  FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon
} from "react-share";
import { format_date_str } from '../Utility/utils';
import { NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';

import {isAndroid, isIOS} from 'react-device-detect';

//var JSBridge;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D2D2D2',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop:'1rem',
  marginLeft: 0,
  height:'48px',
  flexGrow:1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const styles = {
  pShowMore:{
    fontSize:"16px",
    fontWeight:"400",
    lineHeight:"36px",
    fontFamily:"Jost"
  }
}

class GatepassList extends Component {
  constructor(){
    super();
    this.state = {
      formData: {},
      search: "",
      listLoading: true,
      project_name: ""
    }
    this.toggler = {};
     this.timeout = "";
  }

  search = (value) => {
    clearTimeout(this.timeout);
      this.setState({search: value}, () => {
      this.timeout = setTimeout(() => {
        this.lists();
      }, 250);
    });
  }

  async lists() {
    var formData = this.state.formData;
    formData.token =  localStorage.getItem("societyToken");
    
    formData.search = this.state.search;
            
    var api_options = {
      config: {
        url: GATEPASS_LIST_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
         if(response.data.total > 0){
          return this.setState({listLoading:false,lists:response.data.data, message: '', purpose:response.data.purpose, type:response.data.type, project_name: response.data.project_name});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  async expire(id) {
    var formData = {
      id: parseInt(id),
      token: localStorage.getItem("societyToken")
    }
    var api_options = {
      config: {
        url: GATEPASS_EXPIRE_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        window.location.reload();
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  toggle_div = (gatepass) => {    
    this.toggler['gatepass_' + gatepass.id] = !this.toggler['gatepass_' + gatepass.id];
    this.setState({render: true});
  }

  share_to_native = (data) => {
      //alert("ca");

      var msg = "Your Gatepass to "+  this.state.project_name + " is " +  data.code + ". Valid from " + format_date_str(data.start_date) + " to " +  format_date_str(data.end_date) + ". Please provide the same information at security desk for seamless entry.";

     var shareObj = {                  
        message: msg,
        subject: data.name,
        title: data.name, 
        download_url: 'https://fm-admin.hoabl.in/api/user/gate-pass/download?token=' + localStorage.getItem("societyToken") + '&id=' + data.id
      }; 

      console.log(shareObj);

     //if(typeof JSBridge != "undefined"){
        
      if(isIOS){
        window.webkit.messageHandlers.shareActionInNative.postMessage(JSON.stringify(shareObj));  
      }
      

      if(isAndroid){
        window.JSBridge.shareActionInNative(JSON.stringify(shareObj));   
      }
        
     //}
     
  }

  renderGatePass = (gatepass, idx) => {
    if(typeof this.toggler['gatepass_' + gatepass.id] == "undefined"){
      this.toggler['gatepass_' + gatepass.id] = false;  
    }
    var display_prop = this.toggler['gatepass_' + gatepass.id] == false ? 'none' : '';
    var display_text = this.toggler['gatepass_' + gatepass.id] == false ? 'show more' : 'show less';

    let {name, contact, image, code, type_name, start_date, end_date, email, purpose_id, type_id, address} = gatepass;
    let is_active = moment(end_date) <= moment() ? false: true;
    start_date = format_date_str(start_date);
    end_date = format_date_str(end_date);
    const purpose = purpose_id != 0 ? this.state.purpose.filter(x => x.id==purpose_id)[0].name : "";
    return (
      <Card style={{marginTop:'1rem'}} key={idx}>
        <div  style={{display:"flex", justifyContent:"space-evenly", padding:'0.5rem 1rem 0rem 1rem',width:'100%', backgroundColor:"#FBFBFD"}}>
          <p style={{fontFamily:'Jost',fontSize:'18px', fontStyle:'bold', fontWeight:600, marginTop:'5px',flexFlow: 1}}>Code: {code}</p>
          
          <h4 style={{fontFamily:'Jost', fontWeight:400, marginTop:'5px', marginLeft:'1rem'}}>
           <a style={{all:"unset"}} href="javascript:void(0)" 
            onClick={ () => { 
              Mixpanel.track("fm_gatepass_share_clicked", {name: name, contact: contact});
              this.share_to_native(gatepass) }}><ShareOutlinedIcon style={{color:'#676AC1'}}/>           </a>
          </h4>
        </div>
        <div className="cd-header" style={{padding:'1rem 1rem 1rem 1rem',width:'100%'}}>
          <div style={{width:'100%', display:'flex'}}>
            <Avatar src={GATEPASS_IMG_URL + image}><PersonIcon/></Avatar>
            <p style={{fontSize:"18px",lineHeight:"36px", fontFamily:'Jost', fontWeight:400, marginLeft:'1rem',flexFlow: 1}}>{name} </p>
          </div>
          {is_active===true?<p style={{fontSize:"16px",lineHeight:"36px", fontFamily:'Jost', fontWeight:400, marginLeft:'1rem', color:'#009A22'}}>Active</p>:<p style={{fontSize:"14px",lineHeight:"36px",fontFamily:'Jost', fontWeight:400, marginTop:'5px', marginLeft:'1rem', color:'#FF0000'}}>Expired</p>}
        </div>
        <Card.Body className="text-muted" style={{ display: display_prop }}>
          <div style={{display:'flex'}}>
            <p style={{...styles.pShowMore,width:'40%'}}>Contact:</p>
            <p style={{...styles.pShowMore,width:'60%'}}>{contact}</p>
          </div>
          <div style={{display:'flex'}}>
            <p style={{...styles.pShowMore,width:'40%'}}>Valid from:</p>
            <p style={{...styles.pShowMore,width:'60%'}}>{start_date}</p>
          </div>
          <div style={{display:'flex'}}>
            <p style={{...styles.pShowMore,width:'40%'}}>Valid to:</p>
            <p style={{...styles.pShowMore,width:'60%'}}>{end_date}</p>
          </div>
          <div style={{display:'flex'}}>
            <p style={{...styles.pShowMore,width:'40%'}}>Email id:</p>
            <p style={{...styles.pShowMore,width:'60%'}}>{email}</p>
          </div>
          <div style={{display:'flex'}}>
            <p style={{...styles.pShowMore,width:'40%'}}>Purpose:</p>
            <p style={{...styles.pShowMore,width:'60%'}}>{purpose}</p>
          </div>
          <div style={{display:'flex'}}>
            <p style={{...styles.pShowMore,width:'40%'}}>Address:</p> 
            <p style={{...styles.pShowMore,width:'60%'}}>{address}</p>
          </div>
        </Card.Body>

        <div  style={{display:"flex", justifyContent:"space-evenly",fontFamily:'Jost',paddingLeft:'1rem',paddingRight:'1rem',height:'40px',fontWeight:'600', color:'#676AC0',width:"100%"}}>
          <p style={{...styles.pShowMore, marginTop:'5px'}}><a style={{all:"unset"}} onClick={()=>{Mixpanel.track("fm_gatepass_call_clicked", {name: name, contact: contact})}} href={"tel:" + contact}><PhoneIcon style={{marginBottom:'0.3rem'}}/>Call</a></p>
          <p onClick={()=>(this.handleOpen(idx), Mixpanel.track("fm_gatepass_expire_clicked"))} style={{...styles.pShowMore,marginTop:'5px'}}><DeleteSweepOutlinedIcon style={{marginBottom:'0.3rem'}}/></p>
          <p 
            onClick={ () => {
              Mixpanel.track("fm_gatepass_showmore_clicked");
              this.toggle_div(gatepass)}} style={{...styles.pShowMore,textDecoration: 'underline', marginTop:'5px'}}>{display_text}</p>
        </div>
      </Card>
     )
  }

  handleOpen(idx){
    let config = {
      message: "Are you sure to expired active gatepass?",
      confirm_btn_txt: "Yes",
      cancel_btn_txt: "No",
      cancel_btn_show: true,
      icon: 'warning',
      then_call_back: (result)=>{
        if (result.isConfirmed) {
          this.expire(idx);
        }
      }
    }
    SweetAlert(config);
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
            list={this.state.lists}
            renderItem={this.renderGatePass}
            renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
          />
    )
  }

  render() {
    return (
      <>
        <Header societyName={"Gatepass"}/>
        
        <div className="container" >
          <div className="search-container">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                value={this.state.search}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e)=>{
                  Mixpanel.track("fm_gatepass_searched", {"search_text": e.target.value});
                  this.search(e.target.value)}}
              />
              {/* <FilterAltOutlinedIcon style={{position:'relative', float:'right', margin: '0.6rem'}} /> */}
            </Search>
          </div>

          {this.load_main_view()}
          <a href="/gatepass/create" style={{all:'unset'}}
            onClick={()=>{
              Mixpanel.track("fm_gatepass_add_clicked");
            }}>
            <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'16px', bottom:'16px'}}>
              <AddIcon size="large" fontSize="large" />
            </Fab>
          </a>
        </div>
      </>
    )
  }
}

export default GatepassList