import React, { Component } from 'react'
import Grid from '@mui/material/Grid'
import Header from '../Header';
import { apiCall, format_date_str, format_time_str, SweetAlert } from '../Utility/utils';
import { COMMUNITY_EVENTS_SAVE_URL, COMMUNITY_EVENTS_DETAILS_URL } from '../Utility/URL';
import { useParams, useNavigate   } from 'react-router-dom'

const styles = {
  container: {
    backgroundColor: '#F9F9F9',
    minHeight: '100vh',
    padding: '1rem 1.5rem 1rem 1.5rem',
    width:'100%'
  },
  text: {
    marginLeft:'1rem',
    fontFamily:'Jost',
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400'
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '1rem',

  },
  cardBody500: {
    fontFamily: 'Jost',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color:'#57564F'
  },
  cardBody400: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '23px',
    color:'#57564F'
  },
  customButton: {
    border: '1px solid #676AC1',
    borderRadius:'3px',
    blend:'pass through',
    display:'flex',
    justifyContent:'center',
    color:'#676AC1',
    backgroundColor:'#FFFFFF',
    padding:'0.5rem',
    marginTop: '0.5rem'
  },
}

class EventDetails extends Component {
  constructor(){
    super();
    this.state = {  
      person_count: 0,
      desc: "",
      date: "",
      event_user_id: "",
      person_count_error: "",
    };
  }

  async details() {    
    let formData = {
      token: localStorage.getItem("societyToken"),
      id: window.location.href.split("event/")[1]
    }
            
    var api_options = {
      config: {
        url: COMMUNITY_EVENTS_DETAILS_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        

        if(Object.keys(response.data.data).length > 0){
        

          return this.setState({
            person_count: response.data.data.person_count, 
            event_user_id: response.data.data.event_user_id,
            desc: response.data.data.desc,
            date: response.data.data.date
          });
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    }
    apiCall(api_options);
  };
  componentDidMount() {
    this.details();
  };

  async save() {

    let formData = {
      token: localStorage.getItem("societyToken"),
      id: window.location.href.split("event/")[1],
      person_count: parseInt(this.state.person_count),
      event_user_id: this.state.event_user_id
    }
    

    var api_options = {
      config: {
        method: "post",
        url: COMMUNITY_EVENTS_SAVE_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        console.log("Sucess", response);
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator(-1)
        }
        SweetAlert(config);
        
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }
    }
    apiCall(api_options);
  }

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  submitForm = (e) => {
    e.preventDefault()
    this.save();
  }


  render() {

    return (
      <>
      <Header />
      <div style={styles.container}>
        <div style={styles.textContainer}>
          <Grid container spacing={1}>
          <Grid item xs={4}>
              <p style={styles.cardBody400}>Date:</p>
            </Grid>
            <Grid item xs={8}>
              <p style={styles.cardBody500}>{format_date_str(this.state.date)}</p>
            </Grid>
            <Grid item xs={4}>
              <p style={styles.cardBody400}>Time:</p>
            </Grid>
            <Grid item xs={8}>
              {/* <p style={styles.cardBody500}>10:00 AM to 5:00 PM</p> */}
              <p style={styles.cardBody500}>{format_time_str(this.state.date)}</p>
            </Grid>
            <Grid item xs={4}>
              <p style={styles.cardBody400}>Description:</p>
            </Grid>
            <Grid item xs={8}>
              <p style={styles.cardBody500}>{this.state.desc}</p>
            </Grid>
          </Grid>
        </div>
        <p style={{...styles.cardBody500,color:'#000000',marginTop:'3rem'}}>Let us know how many members are joining from your family</p>
        <input type='number' 
          placeholder='Enter the count here' 
          style={{width:'100%', 
          padding:'0.5rem 1rem', 
          borderRadius:'3px', 
          border:' 1px solid #C4C4C4', 
          marginTop:'1rem',
          fontFamily:'Jost',
          textAlign:'center'}}
          name="person_count"
           value={this.state.person_count} 
          onChange={(e) => this.setState({'person_count':e.target.value})}   
        />
        <div style={{...styles.customButton, marginTop:'1rem'}} onClick={(e)=>this.submitForm(e)}>
          <p><strong>Send</strong></p> 
        </div>
      </div>
      </>
    )
  }
}


function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(EventDetails)
