import React,{ Component, useCallback } from 'react'
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import UploadIcon from '../Assets/upload-cloud.svg'
import { apiCall, SweetAlert } from '../Utility/utils';
import { CONSTRUCTION_REQUEST_NOC_SAVE_URL } from '../Utility/URL';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom'
import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone'
import { Mixpanel } from '../Mixpanel';
import {isAndroid, isIOS} from 'react-device-detect';

const styles = {
  container: {
    backgroundColor:'#FAFAFA',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost',
    minHeight:'100vh',
  },
  button: {
    background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',
    height:'56px',
    fontFamily:'Jost',
    fontSize:'18px',
    lineHeight:'36px',
    fontWeight:'700',
    marginTop:'3rem',
    marginBottom:'2rem'
  },
  p1: {
    fontFamily:'Jost',
    marginTop:'1rem',
    marginBottom:'0.5rem',
    color:'#646464'
  },
  p2: {
    alignSelf:'center',
    marginLeft:'0.5rem',
    color:'#9D9EB1',
    fontFamily:'Jost'
  },
  uploadContainer: {
    border: '1px dashed #C4C4C4',
    borderRadius:'5px',
    display:'flex',
    justifyContent:'center',
    height: '6rem',
    backgroundColor:'#FFFFFF',
    padding:'1rem'
  },
}


class NOC extends Component {
  constructor(){
    super();
    this.state = {  
      formData:{
        construction_by: "",
        contractor_name: "",
        service_desc: ""
      },
      file_name: "",
      errors: {
        construction_by_error: "",
        contractor_name_error: "",
        service_desc_error: ""
      }
    };
  }

  validate = () => {
    const errorMsg = {
      construction_by_error: this.state.formData.construction_by === "" ? "This field is required." : "",
      contractor_name_error: this.state.formData.contractor_name === "" ? "This field is required." : "",
      service_desc_error: this.state.formData.service_desc === "" ? "This field is required." : ""
    }

    this.setState(prevState=> ({...prevState, errors: {...errorMsg}}));

    return Object.values(errorMsg).some(error => error !== "");
  }

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  async save() {
    const data = new FormData();
    
    for (let k in this.state.formData) {
      data.append(k, this.state.formData[k]);
    }
    
    data.append('token', localStorage.getItem("societyToken"));
    data.append("file_name", this.state.file_name);

    var api_options = {
      config: {
        method: "post",
        url: CONSTRUCTION_REQUEST_NOC_SAVE_URL,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      },
      successCallback: (response)=>{
        console.log("Sucess", response);
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator(-1)
        }
        SweetAlert(config);
        
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }
    }
    apiCall(api_options);
    Mixpanel.track("fm_construction_noc_send_request_clicked", this.state.formData);
  }

  submitForm = (e) => {
    e.preventDefault()

    if(!this.validate()){
      this.save();
    };
  }

  sendUploadAction = () => {
      //alert("ca");
     var shareObj = { 
      "type": "doc",
      "page_name": "request_noc"                                  
      }; 

      console.log(shareObj);       
       
      if(isIOS){
        window.webkit.messageHandlers.sendUploadActionInNative.postMessage(JSON.stringify(shareObj));  
      }        

      if(isAndroid){          
        window.JSBridge.sendUploadActionInNative(JSON.stringify(shareObj)); 
     }
     
  }

   componentDidMount() {
    
      var currentdate = new Date(); 
    const script = document.createElement("script");
    script.src = "https://fm-admin.hoabl.in/front/external.js?t=" + currentdate.getTime();
    script.async = true;
    document.body.appendChild(script);

    window.gatepass_obj = this;
    
  };

  render(){
    return (
      <>
      <Header societyName="NOC Request"/>
      <div style={styles.container}>
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}}
          fullWidth id="standard-basic"
          label="Construction by"
          variant="standard"
          name="construction_by"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.errors.construction_by_error !== "" ? true : false}
          helperText={this.state.errors.construction_by_error}
          
        />
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}}
          fullWidth id="standard-basic"
          label="Name of the Contractor"
          variant="standard"
          name="contractor_name"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.errors.contractor_name_error !== "" ? true : false}
          helperText={this.state.errors.contractor_name_error}
          
        />
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}}
          fullWidth id="standard-basic"
          label="Service Description"
          variant="standard"
          name="service_desc"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.errors.service_desc_error !== "" ? true : false}
          helperText={this.state.errors.service_desc_error}
          
        />
        <TextField style={{marginBottom:'1.5rem',fontFamily:'Jost'}}
          fullWidth id="standard-basic"
          label="Notes (if any)"
          variant="standard"
          name="notes"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          
        />
        
        <p style={styles.p1}>Upload files (optional)</p>
          
          

         <a style={{all:"unset"}} href="javascript:void(0)" onClick={ () => { this.sendUploadAction() }}>
              <div>
                <label htmlFor="upload-photo" style={{width:'100%'}}>
                  <div style={styles.uploadContainer}>
                    <img src={UploadIcon} width="24px" />
                    <p style={styles.p2} >Upload drawing for Construction</p>
                  </div>
                </label><br/>
                
              </div>
            </a>
       
        <p style={styles.p1}>{this.state.file_name}</p>

        {/* <label htmlFor="upload-photo" style={{width:'100%'}}>
          <div style={styles.uploadContainer}>
            <img src={UploadIcon} width="24px" />
            <p style={styles.p2} >Upload drawing for Construction</p>
          </div>
        </label><br/>
        <input id="upload-photo"
          type="file"
          style={{opacity: 0, position: 'absolute', zIndex: -1}}
          name="files[]"
          onChange={ (e) => {this.setData(e.target.name, e.target.files[0])}}
        /> */}
        <Button sx={styles.button} fullWidth  variant="contained" onClick={(e)=>this.submitForm(e)}>Send Request</Button>
      </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(NOC);