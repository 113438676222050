import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import personsvg from '../Assets/Group_12115.svg'
import { FormHelperText } from '@mui/material';
import './index_v2.css'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { apiCall, SweetAlert } from '../Utility/utils';
import { GATEPASS_REQUIRED_INFO_URL, GATEPASS_SAVE_URL, DAILYHELPER_STAFF_DETAILS_URL, DAILY_HELPER_IMG_URL } from '../Utility/URL';
import Header from '../Header';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import IconButton from '@mui/material/IconButton';
import ContactsModal from '../Utility/ContactsModal';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Mixpanel } from '../Mixpanel';
import {isAndroid, isIOS} from 'react-device-detect';

const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;

  & .${badgeUnstyledClasses.badge} {
    z-index: auto;
    min-width: 30px;
    height: 30px;
    padding: 5px 0px;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
    background: #888888;
    border-radius: 15px;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    bottom: -5;
    right: -5;
    transform: translate(320%, -70%);
    transform-origin: 100% 0;
  }
`;

class GatepassCreate extends Component {
  constructor(){
    super();
    this.state = {  
      selectedFile: null,        
      photo_text: "Photo Click",
      formData:{
        type_id: window.location.href.includes("gatepass-visitor") ? "289" : "",
        name: "",
        contact: "",
        start_date: "",
        end_date: "",
        email: "",
        image: "",
        staff_id: "",
        address: "",

      },
      contact_modal_flag: false,
      preload_image: "",
      file_name: "",
      min: "",
      formPurposeData: {},
      purpose: [],
      type: [],

      type_id_error: "",
      name_error: "",
      contact_error: "",
      start_date_error: "",
      end_date_error: "",
      email_error: "",
      image_error: "",
      contact_data: new Array()

    };
  }

  validate = () => {
    var errors = {
      type_id_error: this.state.formData.type_id === "" ? "This field is required." : "",
      name_error: this.state.formData.name === "" ? "This field is required." : "",
      contact_error: this.state.formData.contact === "" ? "This field is required." : this.state.formData.contact.length == 10 && (/^[0-9\b]+$/).test(this.state.formData.contact) ? "" : "Contact Number is not valid.",
      email_error: this.state.formData.email !== "" ? (/$^|.+@.+..+/).test(this.state.formData.email) ? "" : "Email is not valid." : "",
      start_date_error: this.state.formData.start_date === "" ? "This field is required." : "",
      end_date_error: this.state.formData.end_date === "" ? "This field is required." : this.state.formData.end_date < this.state.formData.start_date ? "End Date should be greater than Start Date" : "" ,
      // image_error: this.state.formData.image === "" ? "This field is required." : ""
    }
    this.setState(prevState=> (errors));
    return !Object.values(errors).some(x=> x !== "");
  }

  async preLoad() {
    let data = {
      id: parseInt(window.location.href.split('create/')[1]),
      token: localStorage.getItem("societyToken")

    }
    let api_options = {
      config: {
        method: 'post',
        url: DAILYHELPER_STAFF_DETAILS_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data),
      },
      successCallback: (response)=>{
        const {image, first_name, last_name, contact, email, address} = response.data.data.details;
        this.setState({preload_image: image});
        this.setState(prevState => ( { formData: {...prevState.formData,
            type_id: "156",
            // image: image || "",
            name: `${first_name} ${last_name}`,
            contact: contact || "",
            email: email || "",
            address: address || "",
            staff_id: parseInt(window.location.href.split('create/')[1])
        }}));
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    }
    apiCall(api_options);
  }

  async lists() {    
    var formData = this.state.formPurposeData;
    formData.token =  localStorage.getItem("societyToken");
            
    var api_options = {
      config: {
        url: GATEPASS_REQUIRED_INFO_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.type.length > 0 || response.data.purpose.length > 0){
          return this.setState({listLoading:false, message: '', purpose:response.data.purpose, type:response.data.type});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    }
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
      var currentdate = new Date(); 
    const script = document.createElement("script");
    script.src = "https://fm-admin.hoabl.in/front/external.js?t=" + currentdate.getTime();
    script.async = true;
    document.body.appendChild(script);

    window.gatepass_obj = this;

    if( window.location.href.includes('create/')){
      this.preLoad();
    }
  };

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  async save() {

    const data = new FormData();

    
    for (let k in this.state.formData) {
      if((k=== 'image' || k==="staff_id") && this.state.formData[k] === ""){
        console.log("don't have image or staff_id")
      }else{
        data.append(k, this.state.formData[k]);
      }
    }
    
    data.append('token', localStorage.getItem("societyToken"));
    data.append("file_name", this.state.file_name);

    var api_options = {
      config: {
        method: "post",
        url: GATEPASS_SAVE_URL,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      },
      successCallback: (response)=>{
        //document.getElementById("test-container").innerHTML = "gghhh 2" +  JSON.stringify(response);
        console.log("sucess");
        window.location.href = '/gatepass/list';
      },
      errorCallback: (error)=> { 
        console.log("Error", error.response);

        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'error'
        }
        SweetAlert(config);

      }    
    }
    apiCall(api_options);
  }

  submitForm = (e) => {
    //e.preventDefault()
    if(this.validate()){
      this.save();
    }
  }  

  sendUploadAction = () => {
     var shareObj = { 
      "type": "image",
      "page_name": "gatepass"                                  
      }; 

      console.log(shareObj);
        if(isIOS){
          window.webkit.messageHandlers.sendUploadActionInNative.postMessage(JSON.stringify(shareObj));  
        }
        
        if(isAndroid){
          window.JSBridge.sendUploadActionInNative(JSON.stringify(shareObj)); 
        }
     
  }

  get_contact_to_native = () => {
     var shareObj = { 
        "test": "test"                                  
      }; 

      console.log(shareObj);

      if(isIOS){
          window.webkit.messageHandlers.sendContactActionInNative.postMessage({});  
      }
        

      if(isAndroid){
       
        window.JSBridge.sendContactActionInNative(); 
     }
     
  }

  
  render() {
    return (
      <>
      <Header societyName={"Create Gatepass"}/>
      <ContactsModal open={this.state.contact_modal_flag} action={(name, phoneNo)=>{this.setState(prevState=>({...prevState, contact_modal_flag:false, formData:{...prevState.formData, name: name, contact: phoneNo}}))}} contacts={this.state.contact_data} onClose={()=>{this.setState({contact_modal_flag:false})}} />
      <div className="container">
         
        <div style={{padding:'2rem', display:'flex', justifyContent:'center', width:'100%'}}>
          <input type='file' id="sImage" name="image" accept="image/*" style={{display:"none"}} 
            capture="camera"
            onChange={ (e) => this.setData(e.target.name, e.target.files[0]) } ></input>


          <label htmlFor="sImage">
            {/* <img src={Boolean(this.state.preload_image) ? DAILY_HELPER_IMG_URL + this.state.preload_image : personsvg} style={{width: 150, height: 150}} /> */}
            <StyledBadge badgeContent={<PhotoCameraIcon fontSize="small" onClick={ () => { this.sendUploadAction() }} />} >
              <Avatar src={Boolean(this.state.preload_image) && DAILY_HELPER_IMG_URL + this.state.preload_image} style={{textDecoration: 'none'}} sx={{ width: 140, height:140 }}><PersonIcon sx={{fontSize:'5rem'}}/></Avatar>
            </StyledBadge>
          </label>
          {/* {this.state.image_error !== "" && <FormHelperText error={this.state.image_error !== "" ? true : false}>{this.state.image_error}</FormHelperText>} */}
        </div>        

        <FormControl fullWidth variant="standard" sx={{ minWidth: 120, marginBottom:'1.5rem' }}
          {...(this.state.type_id_error !== "" && {error:true})}
          >
         
         
          <InputLabel id="demo-simple-select-standard-label">Gate pass for</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Gate pass for"
            name="type_id"
            value={this.state.formData.type_id}
            onChange={(e) =>e.target.value===156 ? window.location.href='/dailyhelpers/gatepass/list':this.setData(e.target.name, e.target.value)}
          >
            {
              (() => {
                let container = [];
                this.state.type.forEach((val, index) => {
                  container.push(<MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>)
                });
                return container;
              })()
            }
          </Select>
          {this.state.type_id_error !== "" && <FormHelperText error={this.state.type_id_error !== "" ? true : false}>{this.state.type_id_error}</FormHelperText>}
        </FormControl>
        <TextField style={{marginBottom:'1.5rem'}}
          fullWidth id="standard-basic" label="Name" variant="standard" 
          name="name"
          value={this.state.formData.name} 
          onChange={(e) => this.setData(e.target.name, e.target.value)} 
          error={this.state.name_error !== "" ? true : false}
          helperText={this.state.name_error}
          />
        <div style={{display:"flex"}}>
        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" 
          label="Contact Number" variant="standard" 
          name="contact"
          value={this.state.formData.contact} 
          onChange={(e) => this.setData(e.target.name, e.target.value)} 
          error={this.state.contact_error !== "" ? true : false}
          helperText={this.state.contact_error}
        />

         <a style={{all:"unset",margin:'0.75rem 0 0.75rem 0.5rem'}} href="javascript:void(0)" 
          onClick={ () => {
            Mixpanel.track("fm_gatepass_contacts_clicked");
            this.get_contact_to_native() }}>
            <IconButton >
              <ContactPhoneIcon />
            </IconButton>
         </a>
         </div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            label="Valid from"
            name="start_date" 
            inputFormat="yyyy-MM-DD"
            minDate={moment()}    
            value={this.state.formData.start_date}
            onChange={(e, value)=>{              
                var data =  this.state;
                data.formData.start_date = e.format("yyyy-MM-DD");
                data.min = e;
                data.formData.end_date = "";
              this.setState(prevState=> (data));

            }}
            renderInput={(params) => 
              <TextField 
                {...params}
                variant="standard"
                error={this.state.start_date_error !== "" ? true : false}
                helperText={this.state.start_date_error}
                style={{marginBottom:'1.5rem'}} 
                fullWidth
              />}
          />
          
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            disabled={this.state.formData.start_date !== "" ? false : true}
            label="Valid to"
            name="end_date" 
            inputFormat="yyyy-MM-DD"
            minDate={this.state.min}
            value={this.state.formData.end_date}
            onChange={(e)=>{
              var data =  this.state;
              data.formData.end_date = e.format("yyyy-MM-DD");                              
              this.setState(prevState=> (data));
              //this.setData("end_date", e);
            }}
            renderInput={(params) => 
              <TextField 
                {...params}
                variant="standard"
                error={this.state.end_date_error !== "" ? true : false}
                helperText={this.state.end_date_error}
                style={{marginBottom:'1.5rem'}} 
                fullWidth
              />}
          />
        </LocalizationProvider>

        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" 
          label="Email id (optional)" variant="standard" 
          name="email"
          value={this.state.formData.email} 
          onChange={(e) => this.setData(e.target.name, e.target.value.trim())} 
          error={this.state.email_error !== "" ? true : false}
          helperText={this.state.email_error}/>

        <FormControl fullWidth variant="standard" sx={{ minWidth: 120, marginBottom:'1.5rem' }}>
          <InputLabel id="demo-simple-select-standard-label">Purpose (optional)</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Purpose (optional)"
            name="purpose_id"
            onChange={(e) => this.setData(e.target.name, e.target.value)}
          >
            {
              (() => {
                let container = [];
                this.state.purpose.forEach((val, index) => {
                  container.push(<MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>)
                });
                return container;
              })()
            }
          </Select>
        </FormControl>

        <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" 
          label="Address (optional)" 
          variant="standard" 
          name="address"
          value={this.state.formData.address} 
          onChange={(e) => this.setData(e.target.name, e.target.value)} />
        <Button sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',marginBottom:'2rem'}} fullWidth  variant="contained"  type="submit" 
          onClick={(e)=>{
            Mixpanel.track("fm_gatepass_showmore_clicked");
            this.submitForm(e);  
          }}>Save</Button>
      </div>
      </>
    )
  }
}

export default GatepassCreate