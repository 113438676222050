import React,{ Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Verified_red from '../Assets/verified_red.svg'
import { apiCall, MyLoader } from '../Utility/utils';
import { CONSTRUCTION_GUIDELINE_RULES_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    padding:'1rem',
    fontFamily:'Jost',
  },
  listItem: {
    display:'flex',
    margin:'1.5rem 0'
  },
  listItemText: {
    fontSize:'14px',
    lineHeight:'22px',
    fontWeight:'400',
    whiteSpace: 'pre-line'
  }
}

class ConstructionGuidelines extends Component {
  constructor(){
    super();
    this.state = {
      list: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken"),
    }
            
    var api_options = {
      config: {
        url: CONSTRUCTION_GUIDELINE_RULES_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),
      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ""});
        }else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  }

  renderConstructionGuidelineRules = (constructionGuidelineRule, idx) => {
    const {id, rules} = constructionGuidelineRule;
    return (
        <div style={styles.listItem} key={idx}>
          <Avatar alt="avatar" src={Verified_red} style={{margin:'0 0.8rem'}} />
          <div style={styles.listItemText}>{rules}.</div>
        </div>
     )
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderConstructionGuidelineRules}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render(){
    return (
      <>
      <Header societyName="Construction Guidelines"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    )
  }
}

export default ConstructionGuidelines