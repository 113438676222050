import React, { useState } from 'react';
import { Typography } from '@mui/material';

const options = [
  {
    id: 1,
    name:'Today'
  },
  {
    id: 2,
    name:'Tommorow'
  },
  {
    id: 3,
    name:'Custom'
  },
]

export default function RadioGroupSelect(props) {  

  const [selected, setSelceted] = useState(1)
  const [value, setValue] = React.useState(new Date());
  return (
    <div style={props.css}>
      {props?.choices?.map(item => (
        <div key={item.id} onClick={() => { setSelceted(item.id); props.onChange(item.id);  }} style={{width:'fit-content',display:'flex', justifyContent:'center', margin:'0.5rem'}}>
          <div style={!(selected === item.id) ? {height:'1.5rem', width:'1.5rem', borderRadius:'0.75rem', backgroundColor:'#C4C4C4', padding:'0.35rem'}: {height:'1.5rem', width:'1.5rem', borderRadius:'0.75rem', backgroundColor:'#676AC1', padding:'0.35rem'}} >
            <div style={{height:'0.8rem', width:'0.8rem', borderRadius:'0.4rem',backgroundColor:'#FFFFFF'}}>
            </div>
          </div>
          <Typography variant="h6" sx={!(selected === item.id) ? {marginLeft:'1rem',fontFamily:'Jost',fontSize:"16px"}:{marginLeft:'1rem',fontFamily:'Jost',color:'#676AC1', fontWeight: 'bold',fontSize:"16px"}}>{item.name}</Typography>
        </div>
      ))}
    </div>
  );
}

// #C4C4C4
// #676AC1