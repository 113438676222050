import React, { Component } from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import UploadIcon from '../Assets/upload-cloud.svg'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { apiCall, SweetAlert } from '../Utility/utils';
import { COMMUNITY_FEEDBACK_SAVE_URL } from '../Utility/URL';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom';
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    backgroundColor: '#F9F9F9',
    minHeight: '100vh',
    padding: '1rem',
    width:'100%'
  },
  textArea: {
    width: '100%',
    border:'1px solid #C4C4C4',
    borderRadius:'5px',
    padding:'1rem',
    marginTop:'3rem' 
  },
  p1: {
    fontFamily:'Jost',
    marginTop:'1rem',
    marginBottom:'0.5rem'
  },
  uploadContainer: {
    border: '1px solid #C4C4C4',
    borderRadius:'5px',
    display:'flex',
    justifyContent:'center',
    height: '6rem',
    backgroundColor:'#FFFFFF',
    padding:'1rem'
  },
  p2: {
    alignSelf:'center',
    marginLeft:'0.5rem',
    color:'#9D9EB1',
    fontFamily:'Jost'
  },
  button: {
    background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',
    marginTop:'2rem',
    marginBottom:'2rem',
    fontFamily:'Jost',
    height:'56px',
    fontSize:'18px',
    fontWeight:'700'
  }
}

class Feedback extends Component {
  constructor(){
    super();
    this.state = {  
      selectedFile: null,        
      formData:{
        name: "",
        desc: ""
      },
      errors: {
        name_error: "",
        desc_error: "",
      },

    };
  }

  validate = () => {
    const errorMsg = {
      name_error: this.state.formData.name === "" ? "This field is required." : "",
      desc_error: this.state.formData.desc === "" ? "This field is required." : ""
    }
    this.setState(prevState=> ({...prevState, errors: {...errorMsg}}));

    return Object.values(errorMsg).some(error => error !== "");
  }

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  async save() {
    const data = new FormData();
    
    for (let k in this.state.formData) {
      data.append(k, this.state.formData[k]);
    }
    data.append('token', localStorage.getItem("societyToken"));

    var api_options = {
      config: {
        method: "post",
        url: COMMUNITY_FEEDBACK_SAVE_URL,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      },
      successCallback: (response)=>{
        console.log("Sucess", response);
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator(-1)
        }
        SweetAlert(config);
        
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }
    }
    apiCall(api_options);
  }

  submitForm = (e) => {
    e.preventDefault()
    if(!this.validate()){
      this.save();
    };
  }

  render() {
    return (
      <>
      <Header societyName="Feedback"/>
      <div style={styles.container}>
        <TextField style={{marginBottom:'1.5rem'}}
          fullWidth id="standard-basic" label="Name" variant="standard" 
          name="name"
          onChange={(e) => this.setData(e.target.name, e.target.value)} 
          error={this.state.errors.name_error !== "" ? true : false}
          helperText={this.state.errors.name_error}
          />

        <TextField
          id="outlined-multiline-static"
          label="Share your feedback with the facility manager"
          multiline
          minRows={3}
          style={{width:'100%'}}
          variant="outlined"
          name="desc"
          onChange={(e) => this.setData(e.target.name, e.target.value)} 
          error={this.state.errors.desc_error !== "" ? true : false}
          helperText={this.state.errors.desc_error}
        />

        {/* <TextareaAutosize
          placeholder="Share your feedback with the facility manager"
          aria-label="minimum height"
          minRows={3}
          style={styles.textArea}
        /> */}

        <Button sx={styles.button} fullWidth  variant="contained"  onClick={(e)=>{this.submitForm(e); Mixpanel.track("fm_community_feedback_send_clicked", this.state.formData);}} >Send</Button>
      </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(Feedback);