import React,{ Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Avatar_png from '../Assets/avatar_male.png'
import Grid from '@mui/material/Grid';
import { apiCall, NO_DATA_FOUND, MyLoader } from '../Utility/utils';
import { CONSTRCUTION_CONTRACTOR_LIST_URL, CONSTRUCTION_NOC_LIST_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import PersonIcon from '@mui/icons-material/Person';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom'

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '90vh',
    width:'100%',
    fontFamily:'Jost'
  },
  card: {
    backgroundColor: "#FFFFFF",
    // marginTop: "2rem",
    padding: "0.5rem 2rem 1rem 2rem",
    borderTop: "2rem solid #F7F7F7",
  },
  cardHeaderP1: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'500',
    fontFamily:'Jost'
  },
  cardHeaderP2: {
    fontSize:'16px',
    fontWeight:'400',
    fontFamily:'Jost',
    color:'#9D9EB1'
  },
  cardBody: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '32px'
  },
  divider: {
    height: '2px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}

class ContractorsList extends Component {
  constructor(){
    super();
    this.state = {
      lists: [],
      listLoading: true,
    }
    this.toggler = {};
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    }
            
    var api_options = {
      config: {
        url: CONSTRCUTION_CONTRACTOR_LIST_URL,
        // url: CONSTRUCTION_NOC_LIST_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ""});
        }
        else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };
  
  renderContractor = (contractor, idx) => {
    const {name, company_name, contact, address, email} = contractor;
    return (
      <div style={styles.card} key={idx}>
        <div style={{ display: "flex" }}>
          <Avatar><PersonIcon/></Avatar>
          <div style={{ marginLeft: "0.8rem" }}>
            <p style={styles.cardHeaderP1}>{name}</p>
            <p style={styles.cardHeaderP2}>{company_name}</p>
          </div>
        </div>
        <Grid container spacing={1} style={{marginTop:'0.5rem'}}>
          <Grid item xs={4}>
            <p style={styles.cardBody}>Email:</p>
          </Grid>
          <Grid item xs={8}>
            <p style={styles.cardBody}>{email}</p>
          </Grid>
          <Grid item xs={4}>
            <p style={styles.cardBody}>Phone:</p>
          </Grid>
          <Grid item xs={8}>
            <p style={styles.cardBody}>{contact}</p>
          </Grid>
          <Grid item xs={4}>
            <p style={styles.cardBody}>Address:</p>
          </Grid>
          <Grid item xs={8}>
            <p style={styles.cardBody}>{address}</p>
          </Grid>            
        </Grid>
      </div>
     )
  }

  componentDidMount() {
    this.lists();
  };

  load_main_view  = () => {
    if(this.state.listLoading === true){
        return <MyLoader />
    }
    return (
      <FlatList
        list={this.state.lists}
        renderItem={this.renderContractor}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }

  render(){
    return (
      <>
      <Header societyName="Contractor"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    )
  }

  
}
function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(ContractorsList);