import './App.css';
import CustomRoutes from './Routes';
import { Fragment } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNavigate } from "react-router-dom";
import Header from './Header'

var JSBridge;

function App() {
  // let navigate = useNavigate();
  return (
    <Fragment>
      {/* <Header/> */}
      <CustomRoutes/>
    </Fragment>
  );
}

export default App;

