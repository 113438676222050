import React, { Component } from 'react'
import Personal from './Personal';
import Community from './Community';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Header from '../Header';
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%'
  },
  navContainer: {
    width:'50%',
    padding:'1rem',
    textAlign:'center'
  },
  navHover: {
    borderBottom:'1px solid #676AC1',
    color:'#676AC1',
    width:'50%',
    padding:'1rem',
    textAlign:'center'
  }
}

export default class Complaints extends Component {
  constructor() {
    super();
    this.state = {
      selectedPerson: true
    };
  }
  render() {
    return (
      <>
      <Header societyName="Complaints"/>
      <div>
        <div style={{display:'flex'}}>
          <div style={this.state.selectedPerson ? styles.navHover : styles.navContainer } onClick={()=> this.setState({selectedPerson:true})}>
            <p style={{fontFamily:'Jost'}}><strong>Personal</strong></p>
          </div>
          <div style={this.state.selectedPerson ? styles.navContainer : styles.navHover} onClick={()=> this.setState({selectedPerson:false})}>
            <p style={{fontFamily:'Jost'}}><strong>Community</strong></p>
          </div>
        </div>
        {this.state.selectedPerson ? <Personal />:<Community />}
        <a href="/complaints/create" style={{all:'unset'}}
          onClick={()=>{
            Mixpanel.track("fm_complaint_add_clicked");
          }}
        >
          <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
            <AddIcon size="large" fontSize="large" />
          </Fab>
        </a>
      </div>
      </>
    )
  }
}
