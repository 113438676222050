import React,{ Component } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CameraIcon from '../Assets/camera.svg'
import { FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import { apiCall, SweetAlert } from '../Utility/utils';
import { COMPLAINTS_SAVE_URL, COMPLAINTS_REQUIRED_INFO_URL } from '../Utility/URL';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom'
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    minHeight: '100vh',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  jostFont:{
    fontFamily:'Jost'
  },
  card: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.05)',
    borderRadius: '5px',
    padding:'1rem',
    marginTop:'1rem'
  },
  navContainer: {
    width:'50%',
    padding:'1rem',
    textAlign:'center'
  },
  navHover: {
    borderBottom:'1px solid #676AC1',
    color:'#676AC1'
  },
  textArea: {
    width: '100%',
    border: 'none',
    borderRadius:'5px',
    padding:'1rem',
    background:'#FBFBFB',
    marginTop:'1rem'
  
  },
  divider: {
    height: '1px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
}

class ComplaintCreate extends Component {
  constructor(){
    super();
    this.state = {  
      formData:{
        name: "",
        category_id: "",
        desc: ""
      },
      errors: {
        name_error: "",
        desc_error: "",
        category_id_error: ""
      },
      complain_types_list: [],
    };
  }

  
  setData = (key, value) => {
    return this.setState(prevState => ( {...prevState, formData: {...prevState.formData, [key]: value} }) )
  }

  async lists() {    
    let data = {
      token: localStorage.getItem("societyToken")
    }
            
    var api_options = {
      config: {
        url: COMPLAINTS_REQUIRED_INFO_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(data),

      },
      successCallback:(response)=>{
        if(response.data.complain_types.length > 0){
          return this.setState({complain_types_list:response.data.complain_types});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    }
    apiCall(api_options);
  };
  
  async save() {

    const data = new FormData();
    
    for (let k in this.state.formData) {
      data.append(k, this.state.formData[k]);
    }
    
    data.append('token', localStorage.getItem("societyToken"));
    var api_options = {
      config: {
        method: "post",
        url: COMPLAINTS_SAVE_URL,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      },
      successCallback: (response)=>{
        console.log("Sucess", response);
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator(-1)
        }
        SweetAlert(config);
        
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'error'
        }
        SweetAlert(config);
      }
    }
    apiCall(api_options);
  }

  submitForm = (e) => {
    e.preventDefault()
    if(!this.validate()){
      this.save();
    };
  }

  componentDidMount() {
    this.lists();
  }

  validate = () => {
    const errorMsg = {
      category_id_error: this.state.formData.category_id === "" ? "This field is required." : "",
      name_error: this.state.formData.name === "" ? "This field is required." : "",
      desc_error: this.state.formData.desc === "" ? "This field is required." : ""
    }
    this.setState(prevState=> ({...prevState, errors: {...errorMsg}}));

    return Object.values(errorMsg).some(error => error !== "");
  }

  render(){
    return (
      <>
      <Header societyName="Create Complaint"/>
      <div style={{...styles.container}}>
        <FormControl fullWidth variant="standard" sx={{...styles.jostFont, minWidth: 120, marginBottom:'1.5rem' }}>
          <TextField style={{marginBottom:'1.5rem'}} fullWidth id="standard-basic" 
          label="Complaint Name" variant="standard" name="name"
          value={this.state.formData.name}
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          error={this.state.errors.name_error !== "" ? true : false}
          helperText={this.state.errors.name_error}
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{...styles.jostFont, minWidth: 120, marginBottom:'1.5rem' }}>
          <InputLabel id="demo-simple-select-standard-label" sx={{fontFamily:'Jost'}}>Select Category</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Complaint Category"
            name ="category_id"
            onChange={(e) => this.setData(e.target.name, e.target.value)}
          >
            {
              (() => {
                let container = [];
                this.state.complain_types_list.forEach((val, index) => {
                  container.push(<MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>)
                });
                return container;
              })()
            }
          </Select>
          {this.state.errors.category_id_error !== "" && <FormHelperText error={this.state.errors.category_id_error !== "" ? true : false}>{this.state.errors.category_id_error}</FormHelperText>}
        </FormControl>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={4}
          placeholder="Share your feedback with the facility manager"
          style={styles.textArea}
          name="desc"
          onChange={(e) => this.setData(e.target.name, e.target.value)}
          // error={this.state.desc_error !== "" ? true : false}
          // helperText={this.state.desc_error}
        />
        <div style={{display:'flex', justifyContent:'space-between', marginTop:'1rem'}}>
          <FormControlLabel control={<Checkbox />} label="This is Urgent" sx={{...styles.jostFont, color:'#9D9EB1'}} />
          <div style={{ display: "none" }}>              
            <img alt='camera' src={CameraIcon} style={{margin:'0 0.5rem'}} />
          </div>
        </div>
        <Button 
        onClick={(e)=>{
          Mixpanel.track("fm_complaints_log_ticket_clicked", this.state.formData);
          this.submitForm(e);
        }} 
          sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)', marginBottom:'2rem', marginTop:'2rem'}} fullWidth  variant="contained">Log Ticket</Button>
      </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(ComplaintCreate);

