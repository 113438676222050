import React, { Component } from 'react'
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import img from '../Assets/image_874.png'
import PhoneIcon from '@mui/icons-material/Phone';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import FlatList from 'flatlist-react';
import { Link } from 'react-router-dom'
import { apiCall, NO_DATA_FOUND, MyLoader } from '../Utility/utils';
import { useParams, useNavigate   } from 'react-router-dom';
import { PROPERTIES_LIST_URLS, PROPERTIES_TOKEN, SOCIERTY_MAKE_ACTIVE, PROPERTY_DEFAULT_IMG_URL } from '../Utility/URL';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Mixpanel } from '../Mixpanel';

class MyProperties extends Component {
  constructor() {
    super();
    this.state = {
      formData:{},
      societyName: "",
      listLoading: true,
    }
  }

  async lists() {
    var formData = {
      token: this.props.params.id,
    }
    var api_options = {
      config: {
        url: PROPERTIES_LIST_URLS,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        }
        else{
          return this.setState({listLoading:false, lists:[],  message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };

    apiCall(api_options);
  };

  setToken = async (id) => {

    var formData = {
      id: id,
      token: this.props.params.id
    };

    var api_options = {
      config: {
        url: SOCIERTY_MAKE_ACTIVE,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback:(response)=>{
        this.setState({societyName: response.data.data.name});
        localStorage.setItem("societyName", response.data.data.name);
        localStorage.setItem("societyToken", response.data.data.token);
        localStorage.setItem("propertyId", id);
        localStorage.setItem("propertyUrl", window.location.pathname)
        window.location.href = "/property/"+id;
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };

    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  

  renderSociety = (society, idx) => {
    const {name, image, show_number, id} = society;
    return (
      <div key={id} 
        onClick={()=>{
          this.setToken(id);
          Mixpanel.track('fm_property_clicked', society);
        }}
      >
        <div className="card-container">
          <Card sx={{ display: 'flex', justifyContent:'flex-start' }}>
            <CardMedia
              component="img"
              sx={{ width: 80, margin:'10px' }}
              image={image || PROPERTY_DEFAULT_IMG_URL }
              alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h6" sx={{fontFamily:'Jost'}}>
                  {name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontFamily:'Jost'}}>
                  Plot No. {show_number}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </div>
      </div>
    )
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
        list={this.state.lists}
        renderItem={this.renderSociety}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }


  render() {
    localStorage.setItem("societyName", "Property List");
    return (
      <>
        
        <AppBar position="sticky" style={{boxShadow: 'none', display:'flex',justifyContent:'center' , height:'80px' ,backgroundColor:'#676AC1',color:'#FFFFFF'}}>
          <Toolbar sx={{width:'100%'}}>          
            <Typography variant="h6" component="div" sx={{ flexGrow: 1,fontFamily: 'Jost' }}>
              Manage My Properties
            </Typography>
            
          </Toolbar>
        </AppBar>

        <div className="container">
          {this.load_main_view()}
          {/* <Link to={{pathname:"", state: "hello"}} style={{all:'unset'}}>
            <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
              <AddIcon size="large" fontSize="large" />
            </Fab>
          </Link> */}
        </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(MyProperties);
