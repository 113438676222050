import React, { Component } from 'react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Header from '../Header';
import BookedCottage from './BookedCottage';
import CottageList from './CottageList'

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%'
  },
  navContainer: {
    width:'50%',
    padding:'1rem',
    textAlign:'center'
  },
  navHover: {
    borderBottom:'1px solid #676AC1',
    color:'#676AC1',
    width:'50%',
    padding:'1rem',
    textAlign:'center'
  }
}

export default class Cottages extends Component {
  constructor() {
    super();
    this.state = {
      selectedPerson: false
    };
  }
  render() {
    return (
      <>
      <Header societyName="Guest Rooms" gobackURL={`/property/${localStorage.getItem("propertyId")}`}/>
      <div>
        <div style={{display:'flex'}}>
          <div style={this.state.selectedPerson ? styles.navContainer : styles.navHover} onClick={()=> this.setState({selectedPerson:false})}>
            <p style={{fontFamily:'Jost'}}><strong>Available Guest Rooms</strong></p>
          </div>
          <div style={this.state.selectedPerson ? styles.navHover : styles.navContainer } onClick={()=> this.setState({selectedPerson:true})}>
            <p style={{fontFamily:'Jost'}}><strong>My Booked Guest Rooms</strong></p>
          </div>
        </div>
        {this.state.selectedPerson ? <BookedCottage />:<CottageList />}
      </div>
      </>
    )
  }
}
