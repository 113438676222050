import React, { Component } from 'react'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import img from '../Assets/image_10.png'
import PhoneIcon from '@mui/icons-material/Phone';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './index_v2.css'
import FlatList from 'flatlist-react'
import { Link } from 'react-router-dom'
import { DAILY_HELPER_IMG_URL } from '../Utility/URL';
import { apiCall, MyLoader, NO_DATA_FOUND } from '../Utility/utils';
import { DAILYHELPER_STAFF_ALL_URL } from '../Utility/URL';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Header from '../Header';
import { Mixpanel } from '../Mixpanel';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D2D2D2',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop:'1rem',
  // marginRight:'1rem',
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  height:'48px',
  flexGrow:1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

class Dailyhelperlist extends Component {
  constructor() {
    super();
    this.state = {
      formData:{},
      search: "",
      listLoading: true,
      message: ""
    }
       this.timeout = "";
  }

 search = (value) => {
    
     clearTimeout(this.timeout);
      this.setState({search: value}, () => {
      this.timeout = setTimeout(() => {
        this.lists();
      }, 250);
    });
  }

  async lists() {
    var formData = this.state.formData;
    formData.token =  localStorage.getItem("societyToken");
    formData.search = this.state.search;
    
    var api_options = {
      config: {
        url: DAILYHELPER_STAFF_ALL_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback:(response)=>{
        
        if(response.data.data.total > 0){          
          return   this.setState({listLoading:false, lists:response.data.data.users, message: ''});
        }else {
          return this.setState({listLoading:false, lists: [], message: response.data.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };

    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderDailyHelper = (dailyhelper, idx ) => {
    var {first_name, image, designation_name, gatepass_count, last_name} = dailyhelper;
    var image = DAILY_HELPER_IMG_URL + image;
    var gate_pass = gatepass_count > 0 ? 'Gatepass Available' : "";
    var url = window.location.href.includes("gatepass") ? `/gatepass-dailyhelper/create/${idx}` :`/dailyhelpers/${idx}`;

    return (
      <div to={url} style={{all:'unset'}} key={idx}>
        <div className="card-container" >
          <Card sx={{ display: 'flex', justifyContent:'space-evenly'}}>
            <Avatar
             onClick={()=>{
               Mixpanel.track("fm_dailyhelper_details_clicked");
             }}
             component={Link} to={url} src={image} style={{textDecoration: 'none'}} sx={{ width: 100, height:100, margin:'10px' }}><PersonIcon/></Avatar>
            <Box 
             onClick={()=>{
               Mixpanel.track("fm_dailyhelper_details_clicked");
             }}
             component={Link} to={url} style={{textDecoration: 'none'}} sx={{ display: 'flex', flexDirection: 'column', width:"100%" }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <p style={{fontFamily:'Jost',fontSize:"16px",lineHeight:"36px",fontWeight:"600", color:"#17171C"}}>
                  {first_name} {last_name}
                </p>
                <p style={{fontFamily:'Jost',fontSize:"13px",lineHeight:"24px",fontWeight:"600", color:"#9D9EB1"}}>
                  {designation_name}
                </p>
                {gate_pass==="Gatepass Available" ? (
                  <p style={{fontFamily:'Jost',fontSize:"13px",lineHeight:"24px",fontWeight:"600", color:"#F2CB00"}} >
                    {gate_pass}
                  </p>
                ) : (
                  <p style={{fontFamily:'Jost',fontSize:"13px",lineHeight:"24px",fontWeight:"600", color:"#676AC0"}} >
                    {gate_pass}
                  </p>
                ) }
              </CardContent>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent:'center', margin:"10px" }}>
              <a onClick={()=>{
                  Mixpanel.track("fm_dailyhelper_call_clicked", {name: first_name, contact: dailyhelper.contact})
                }} 
                href={"tel:" + dailyhelper.contact}  sx={{background:'#E1E1F2'}}>
                <PhoneIcon sx={{color:'#676AC1'}}/>
              </a>
            </Box>
          </Card>
        </div>
      </div>
    );
  };

  

  load_main_view  = () => {
      console.log(this.state.listLoading);
    if(this.state.listLoading === true){
        return <MyLoader />
    }


    // if(typeof this.state.message != "undefined" && this.state.message != ""){
    //     return (<div className="container">{this.state.message}</div>)
    // }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderDailyHelper}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render() {
    return (
      <>
      <Header societyName={"Daily Helper"}/>
      <div className="container">
        <div className="search-container">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              value={this.state.search}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e)=>{
                Mixpanel.track("fm_dailyhelper_searched", {"search_text": e.target.value});
                this.search(e.target.value)}}
            />
            {/* <FilterAltOutlinedIcon style={{position:'relative', float:'right', margin: '0.6rem'}} /> */}
          </Search>
        </div>

        {this.load_main_view()}

        <a href="/dailyhelpers/create" style={{all:'unset'}}
          onClick={()=>{
            Mixpanel.track("fm_dailyhelper_add_clicked");
          }}>
          <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
            <AddIcon size="large" fontSize="large" />
          </Fab>
        </a>
      </div>
      </>
    )
  }
}

export default Dailyhelperlist
