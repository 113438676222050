import { useState, forwardRef } from 'react';
import Link from '@mui/material/Link';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const RateSummary = ({ amount, cgst, sgst, totalAmount }) => {
    const [ open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <div>
            <Typography
                component="div"
                sx={{ fontFamily: "Jost" }}
            >
                {`Rs. ${totalAmount}`}
            </Typography>
            <Typography
                color="text.secondary"
                variant="body1"
                sx={{ fontFamily: "Jost" }}
            >
                <Link href="#" underline="always" onClick={handleOpen}>Rate Summary</Link>
            </Typography>

            <Dialog
                fullWidth
                maxWidth="lg"
                onClose={handleClose}
                open={open}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        position: "absolute",
                        bottom: 0,
                        margin: 0,
                        width: "100%",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                    },
                }}
            >
                <DialogTitle sx={{ textAlign: "center" }}>Rate Summary</DialogTitle>
                <DialogContent sx={{ textAlign: "center" }}>
                    <Grid container >
                        {/* ======================= Amount: Start ======================= */}
                            <Grid item xs={5} sx={{mb: 1}}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    Total
                                </Typography>
                            </Grid>

                            <Grid item xs={7}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    <strong>{amount}</strong>
                                </Typography>
                            </Grid>
                        {/* ======================== Amount: End ======================== */}

                        {/* ======================= SGST: Start ======================= */}
                            <Grid item xs={5} sx={{mb: 1}}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    SGST
                                </Typography>
                            </Grid>

                            <Grid item xs={7}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    <strong>{sgst}</strong>
                                </Typography>
                            </Grid>
                        {/* ======================== SGST: End ======================== */}

                        {/* ======================= CGST: Start ======================= */}
                        <Grid item xs={5} sx={{mb: 1}}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    CGST
                                </Typography>
                            </Grid>

                            <Grid item xs={7}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    <strong>{cgst}</strong>
                                </Typography>
                            </Grid>
                        {/* ======================== CGST: End ======================== */}

                        {/* ======================= totalAmount: Start ======================= */}
                        <Grid item xs={5} sx={{mb: 1}}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    Grand Total
                                </Typography>
                            </Grid>

                            <Grid item xs={7}>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontFamily: "Jost" }}>
                                    <strong>{totalAmount}</strong>
                                </Typography>
                            </Grid>
                        {/* ======================== totalAmount: End ======================== */}
                    </Grid>
                </DialogContent>
            </Dialog>
          </div>
    );
}

export default RateSummary;