import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';
import {apiCall} from './Utility/utils'
import {NOTIFICATION_COUNT_URL} from './Utility/URL'
import { useState, useEffect } from 'react';
import { Mixpanel } from './Mixpanel';

const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;

  & .${badgeUnstyledClasses.badge} {
    z-index: auto;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
    background: #F26B80;
    border-radius: 8px;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
`;

const Header = (props) => {
    let navigate = useNavigate();
    const [notifications, setNotifications] = useState(0);

    const lists = () => {
      apiCall({
        config: {
          url: NOTIFICATION_COUNT_URL,
          method:'post',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data:JSON.stringify({token: localStorage.getItem("societyToken")}),

        },
        successCallback:(response)=>{
          setNotifications(response.data.message);
        },
        errorCallback: (error)=>{console.log("Error", error.response);}
      });
    };

    const handleGoBack = () => {
      if(props.gobackURL) {
        return navigate(props.gobackURL)
      }
      if (props.handleGoBack) {
        return props.handleGoBack();
      }
      return navigate(-1)
    }

    useEffect(() => {
      lists();
    }, []);

    return (
      <AppBar position="sticky" style={{boxShadow: 'none', display:'flex',justifyContent:'center' , height:'80px' ,backgroundColor:'#676AC1',color:'#FFFFFF'}}>
        <Toolbar sx={{width:'100%'}}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleGoBack}
          >
            <ArrowBackIosIcon/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1,fontFamily: 'Jost' }}>
            {props.societyName}
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            component = {Link}
            to = '/notifications'
            onClick={()=>{
              Mixpanel.track("fm_notification_clicked")
            }}
          >
            {/* This notifications cannot go beyond 2 digits */}
            {notifications > 0 ?
              <StyledBadge badgeContent={notifications}>
                <NotificationsNoneIcon />
              </StyledBadge> : <NotificationsNoneIcon />
            }
            
          </IconButton>
        </Toolbar>
      </AppBar>
    )
}

export default Header