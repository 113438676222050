import React,{ useState } from 'react'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import './index.css'

function Accordion(props) {
  const [visible, setVisible] = useState(false)
  return (
    <div className="b-bottom" >
      <AccordionSummary
        expandIcon={visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={()=> setVisible(prev=>!prev)}
      >
        <Typography sx={{fontFamily:'Jost'}}>{props.title}</Typography>
      </AccordionSummary>
      {visible && (
        <AccordionDetails>
          <Typography color="text.secondary" sx={{fontFamily:'Jost'}}>
            {props.details}
          </Typography>
        </AccordionDetails>
      )}
    </div>
  )
}

export default Accordion