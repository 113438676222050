import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FACILITY_DETAILS_URL } from '../Utility/URL';
import { apiCall, SweetAlert } from '../Utility/utils';

const style = {
    color: "rgb(103, 106, 193)"
};

const TermsAndConditions = ({value, onChange, id, label}) => {
    const DEFAULT_LABEL = "Terms and Conditions";

    const [ open, setOpen] = useState(false);
    const [ termsAndConditions, setTermsAndConditions ] = useState([]);
    
    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const details = () => {
        let formData = {
          id: parseInt(id),
          token: localStorage.getItem("societyToken")
        };
    
        let api_options = {
            config: {
            method: 'post',
            url: FACILITY_DETAILS_URL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data:JSON.stringify(formData)
            },
            successCallback: (response)=>{
                const rules = response.data.property_info[0].rules
                setTermsAndConditions(rules)
            },
            errorCallback: (error)=>{
                console.log("Error", error.response);
                SweetAlert({
                    message: error.response.data.message,
                    confirm_btn_txt: "Yes",
                    cancel_btn_show: false,
                    icon: 'warning'
                });
            }
        };
        apiCall(api_options);
    };

    useEffect(() => {
        details();
    }, []);


    return (
        <Box>
            <Checkbox
                sx={{
                    pl: 0,
                    color: style.color,
                    '&.Mui-checked': {
                        color: style.color
                    }
                }}
                checked={value}
                onChange={onChange}
            />
                
            <FormControlLabel
                onClick={handleOpen}
                sx={{ ml: "1px", color: style.color }}
                control={<></>}
                label={label ?? DEFAULT_LABEL}
            />

            <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
                <DialogTitle>Terms And Conditions</DialogTitle>
                <IconButton onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                    {termsAndConditions}
                </Typography>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default TermsAndConditions;