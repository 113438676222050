import React, { Component } from 'react'
import { styled, alpha } from '@mui/material/styles';
import { Card} from 'react-bootstrap';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ShareIcon from '@mui/icons-material/Share';
import PersonIcon from '@mui/icons-material/Person';
import './index_v2.css'
import FlatList from 'flatlist-react'
import moment from 'moment';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { apiCall, MyLoader } from '../Utility/utils';
import { VISITORS_LIST_URL, VISTIOR_IMG_URL, VISITOR_PDF_DOWNLOAD_URL } from '../Utility/URL';
import Grid from '@mui/material/Grid'
import { TextField } from '@mui/material';
import Download_logo from '../Assets/download_logo.svg'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Header from '../Header';
import { format_date_time_str, date_format } from '../Utility/utils';
import { NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';
import {isAndroid, isIOS} from 'react-device-detect';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D2D2D2',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop:'1rem',
  // marginRight:'1rem',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  height:'48px',
  flexGrow:1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const styles = {
  button: {
    background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',
    height:'56px',
    fontFamily:'Jost',
    fontSize:'18px',
    lineHeight:'36px',
    fontWeight:'700',
  },
  dateDiv: {
    display:'flex',
    justifyContent:'center',
    height:'50px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px'
  },
  dateDivText: {
    alignSelf:'center',
    color:'#888888',
    fontFamily:'Jost',
    fontSize:'14px',
    lineHeight:'36px',
    fontWeight:'400'
  },
  dateDivHover: {
    display:'flex',
    justifyContent:'center',
    height:'50px',
    border: '1px solid #676AC0',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
  },
  dateDivTextHover: {
    alignSelf:'center',
    color:'#000000',
    fontFamily:'Jost',
    fontSize:'14px',
    lineHeight:'36px',
    fontWeight:'400'
  },
  colorButtonText: {
    alignSelf:'center',
    fontFamily:'Jost',
    fontSize:'15px',
    lineHeight:'26px',
    fontWeight:'600'
  },
  download: {
    background: 'rgba(103, 106, 192, 0.17)',
    height:'50px',
    display:'flex',
    justifyContent:'center',
    width:"100%",
    border:"None"
  },
  downloadOnHover: {
    background: 'rgba(103, 106, 192, 0.17)',
    height:'50px',
    display:'flex',
    justifyContent:'center',
    width:"100%",
    border:"2px solid #676AC0"
  }
}


export default class VisitorList extends Component {
  constructor(){
    super();
    this.state = {
      hover: 0,
      formData: {
        start_date:  moment().subtract(30, 'days'),
        end_date: moment()
      },
      date_picker_show: 0,
      lists: [],
      search: "",
      listLoading: true,
      message: ""
    }
    this.toggler = {};
    this.timeout = "";
  }

  search = (value) => {
    clearTimeout(this.timeout);
    this.setState({search: value}, () => {
      this.timeout = setTimeout(() => {
        this.lists()
      }, 250);
    });
  }

  async lists() {
    
    let formData = {};
    formData.start_date = this.state.formData.start_date.format('YYYY-MM-DD');
    formData.end_date = this.state.formData.end_date.format('YYYY-MM-DD');
    formData.token =  localStorage.getItem("societyToken");
    formData.search = this.state.search;

    console.log("inside list", formData)
            
    var api_options = {
      config: {
        method:'post',
        url: VISITORS_LIST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message  });
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  share_to_native = (data) => {
      //alert("ca");
     var shareObj = {                  
                  message: "Visitor Information " + data.name ,
                  subject: data.name,
                  title: data.name, 
                  //download_url: 'https://fm.hoabl.in/api/user/gate-pass/download?token=' + localStorage.getItem("societyToken") + '&id=' + data.id
              }; 

        console.log(shareObj);

     //if(typeof JSBridge != "undefined"){
       
        if(isIOS){
          window.webkit.messageHandlers.shareActionInNative.postMessage(JSON.stringify(shareObj));  
        }
        

        if(isAndroid){
          window.JSBridge.shareActionInNative(JSON.stringify(shareObj));   
        }

     //}
     
  }

  renderVisitors = (visitor, idx ) => {

    if(typeof this.toggler['visitor_' + visitor.id] == "undefined"){
      this.toggler['visitor_' + visitor.id] = false;  
    }

    var display_prop = this.toggler['visitor_' + visitor.id] == false ? 'none' : '';
    var display_text = this.toggler['visitor_' + visitor.id] == false ? 'show more' : 'show less';
    var {name, contact, in_time, type_name, image, out_time} = visitor;

    in_time = format_date_time_str(in_time)
    out_time = format_date_time_str(out_time)

    return (
      <Card style={{marginTop:'1rem'}} key={idx}>
        <div  style={{display:"flex", justifyContent:"space-evenly",padding:'1rem 1rem 0rem 1rem',width:'100%',fontFamily:"Jost"}}>
          <div style={{display:'flex',width:"100%"}}>
            <Avatar src={ VISTIOR_IMG_URL + image}><PersonIcon/></Avatar>
            <p style={{fontSize:"16px",lineHeight:"36px",fontFamily:'Jost',fontStyle:'bold', fontWeight:600, marginLeft:'1rem'}}>{name} <a style={{all:"unset"}} href="javascript:void(0)" onClick={ () => { this.share_to_native(visitor) }}><ShareIcon/></a></p>
          </div>
          <p style={{fontSize:"16px",lineHeight:"36px",fontFamily:'Jost', fontWeight:400, marginLeft:'1rem'}}>{type_name}</p>
        </div>
        <Card.Body className="text-muted" style={{fontFamily:"Jost"}}>
          <p style={{fontSize:"14px",lineHeight:"36px",fontFamily:'Jost', fontWeight:400, marginLeft:'1rem'}}>Contact: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{contact}</p>
          <p style={{fontSize:"14px",lineHeight:"36px",fontFamily:'Jost', fontWeight:400, marginLeft:'1rem'}}>IN time: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{in_time}</p>
          <div style={{ display: display_prop }}>
            <p style={{fontSize:"14px",lineHeight:"36px",fontFamily:'Jost', fontWeight:400, marginLeft:'1rem'}}>Out time: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{out_time}</p>
          </div>
        </Card.Body>                
        <div className="cd-header" style={{fontFamily:'Jost',paddingLeft:'1rem',paddingRight:'1rem',backgroundColor:'#FBFBFD',height:'40px',fontWeight:'600', color:'#676AC0'}}>
            <p onClick={ () => this.toggle_div(visitor)} style={{fontSize:"14px",lineHeight:"36px",fontFamily:'Jost', textDecoration: 'underline', }}>{display_text}</p>
            <h2 style={{marginTop:'5px', display: 'none'}}>Staff</h2>
        </div>
      </Card>
    );
  }

  toggle_div = (visitor) => {    
    this.toggler['visitor_' + visitor.id] = !this.toggler['visitor_' + visitor.id];
    this.setState({render: true});
  }

  setData = (key, value) => {
    return this.setState(prevState => ( { formData: {...prevState.formData, [key]: value} }) )
  }

  download = () => {     

    var file_name = moment(new Date()).format("DD_MM_YYYY");

    var url =  this.render_generate_url(VISITOR_PDF_DOWNLOAD_URL) + "&file_name=" + file_name  + "";
    console.log(url);

    //window.location = url;

    let formData = {};
  
    console.log("inside list", formData)
            
    var api_options = {
      config: {
        method:'get',
        url: url,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        //console.log("uploads/apartment/pdf/" + file_name + '.pdf' );    
        console.log(response);

        var obj = {
          url: response.data.url
        }
        
        if(isIOS){
          window.webkit.messageHandlers.sendDownloadActionInNative.postMessage(JSON.stringify(obj));  
        }
        

        if(isAndroid){
          window.JSBridge.sendDownloadActionInNative(JSON.stringify(obj));
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };

      apiCall(api_options);    

  }



  render_generate_url = (url) => {
    let token = localStorage.getItem("societyToken");
    let data = "?token="  + token + "&start_date=" + this.state.formData.start_date.format('YYYY-MM-DD') + "&end_date=" + this.state.formData.end_date.format('YYYY-MM-DD');    
    var url =  url + data;
    return url;
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
        list={this.state.lists}
        renderItem={this.renderVisitors}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }

  render() {

    return (
      <>
        <Header societyName={"Visitor"} />
        <div className="container" >
          <div className="search-container">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                value={this.state.search}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e)=>{
                  Mixpanel.track("fm_visitor_searched", {"search_text": e.target.value});
                  this.search(e.target.value)}}
              />
            </Search>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={()=>{
                Mixpanel.track("fm_visitor_calendar_icon_clicked");
                this.setState(prevState=> ({date_picker_show: this.state.date_picker_show ^ 1}));
              }}
              sx={{ marginTop:'1rem', marginLeft:'1rem', backgroundColor: '#F5F5FA' }}
            >
              <CalendarTodayOutlinedIcon fontSize="inherit" />
            </IconButton>
          </div>
          <Grid container spacing={2} style={{display: this.state.date_picker_show===1?"": "none"}}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  label="From"
                  name="start_date" 
                  inputFormat={date_format}
                  // minDate={moment()}    
                  value={this.state.formData.start_date}
                  onChange={(e)=>{
                    this.setData("start_date", e);
                    //this.setData("end_date", e);
                    this.setState(prevState=> ({min: e}));
                  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      style={{marginBottom:'1.5rem'}} 
                    />}
                />
                
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  label="To"
                  name="end_date" 
                  inputFormat={date_format}
                  minDate={this.state.min}
                  value={this.state.formData.end_date}
                  onChange={(e)=>{this.setData("end_date", e);}}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      onChange={(e) => {
                        this.setData(e.target.name, e.target.value); 
                      }}
                      style={{marginBottom:'1.5rem'}} 
                    />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button 
                onClick={()=>{
                  Mixpanel.track("fm_visitor_calendar_done_clicked")
                  this.lists()}} sx={styles.button} fullWidth  variant="contained">Done</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{marginTop:'1.5rem'}}>
            <Grid item xs={5}>
              <div style={{background: 'rgba(0, 154, 34, 0.1)', height:'50px', display:'flex',justifyContent:'center'}}>
                <p style={{...styles.colorButtonText,color:'#009A22'}}>Total: {this.state.lists.length} visitors</p>
              </div>
            </Grid>
            <Grid item xs={2}>
              
            </Grid>
            <Grid item xs={5}>
              <button
                onMouseEnter={() => this.setState({hover:1})}
                onMouseLeave={() => this.setState({hover:0})}
                onClick={()=>{
                  this.setState({hover:1})
                  this.download();
                  Mixpanel.track("fm_visitor_download_clicked")
                }} style={this.state.hover===0 ? styles.download : styles.downloadOnHover }>
                <p style={{...styles.colorButtonText,color:'#676AC0'}}>Download <img src={Download_logo}/></p>
              </button>
            </Grid>
          </Grid>
          {this.load_main_view()}
          {/* <Link 
            to={{
              pathname: "/gatepass-visitor/create",
              state: {visitor:true}
            }} style={{all:'unset'}}>
            <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
              <AddIcon size="large" fontSize="large" />
            </Fab>
          </Link> */}
        </div>
      </>
    )
  }
}


