import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from './Accordion';
import RateSummary from './RateSummaryPopUp';
import { FACILITY_DETAILS_URL, COTTAGE_IMG_URL } from '../Utility/URL';
import { apiCall, MyLoader } from '../Utility/utils';
import Header from '../Header';
import ImageCarousel from './ImageCarousel';
import './index.css'


export default class CottageAmenityDetails extends Component {
  constructor(){
    super();
    this.state = {
      listLoading: true,
      details: {
        timing: [{}],
        price: {}
      },
      images: [],
      openImgPath: "",
      isOpenImg: false,
    }
  }

  async details(){
    let formData = {
      id: parseInt(window.location.href.split("amenitydetails/")[1]),
      token: localStorage.getItem("societyToken")
    };

    let api_options = {
      config: {
        method: 'post',
        url: FACILITY_DETAILS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        console.log("Getting amenitydetails ", response.data);
        const images = response.data.property_info[0].extra_images.map((imageData) => (COTTAGE_IMG_URL + imageData.file));
        this.setState((prevState) => ({...prevState, listLoading:false, details:response.data.property_info[0], message: '', images: [...images]}));
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  handleImgOpen(imagePath) {
    this.setState(prevState => ({...prevState, isOpenImg: true, openImgPath: imagePath}));
  }

  handleImgClose() {
    this.setState(prevState => ({...prevState, isOpenImg: false}))
  }

  componentDidMount() {
    this.details();
  };

  load_main_view  = () => {
    console.log(this.state.listLoading);
    if(this.state.listLoading === true){
        return <MyLoader />
    }


    if(typeof this.state.message !== "undefined" && this.state.message !== ""){
        return (<div className="container">{this.state.message}</div>)
    }

    const {booking_details_image, name, price, timing, id, notes, rules, facility, capacity} = this.state.details;

    return (
     <div style={{minHeight:'90vh', position: 'relative'}}>
        <ImageCarousel images={this.state.images} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderBottom: " 2px solid #F8F8F8",
          }}
        >
          <CardContent
            style={{ flex: "1 0 auto", padding: "1rem 1rem 2rem 1rem" }}
          >
            <Typography
              component="div"
              variant="h6"
              sx={{ fontFamily: "Jost" }}
            >
              {name}
            </Typography>
            
            <Typography color="text.secondary" sx={{fontFamily:'Jost'}}>
            {notes}
          </Typography>
        
          </CardContent>
        </Box>         
          
        { this.state.details.enabled_facility === 1 && <Accordion title="Facilities" details={facility} />}

        { this.state.details.enabled_capacity === 1 && <Accordion title="Capacity" details={capacity} /> }

        <Accordion
          title="Terms & Conditions"
          details={rules}
        />
        <div style={{height:'75px'}}></div>
        <div style={{backgroundColor:'#FFFEF6', width:'100%', position: 'absolute',bottom:0,right: 0,padding:'1rem 2rem 1rem 2rem',display:'flex',justifyContent:'space-between'}} >
          {price.length === 1 && (
            <RateSummary
              amount={price[0].amount}
              cgst={price[0].cgst_amount}
              sgst={price[0].sgst_amount}
              totalAmount={price[0].total_amount}
            />
          )}
          {
            this.state.details.is_booking_allow === 0 || (
              <a href={"/cottages/timeselection/"+id} style={{all:'unset'}}>
                <Button sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',fontFamily:'Jost'}} variant="contained">
                  See Availability
                </Button>
              </a>
            )
          }
        </div>
      </div>
    )
  }

  render() {

    
    return (
      <>
      <Header societyName="Guest Room Details"/>
        {this.load_main_view()}
      </>
    );
  }
}
