import React, { Component } from 'react'
import FlatList from 'flatlist-react'
import { CardMedia, Box, Button, Typography } from '@mui/material';
import { AMENITY_BOOKINGS_URL, FACILITY_IMG_URL, FACILITY_DEFAULT_IMG_URL } from '../Utility/URL';
import { format_date_str, apiCall, MyLoader, NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';
import './index.css'

export default class BookedCottage extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
      listLoading: true,
    };
    this.handleShowMore = this.handleShowMore.bind(this) 
  }

  async lists(){
    let formData = {
      token: localStorage.getItem("societyToken"),
      type: "cottage"
    };

    let api_options = {
      config: {
        method: 'post',
        url: AMENITY_BOOKINGS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        if(response.data.total>0){
          this.setState({listLoading:false, lists:response.data.data, message: ''});
        }else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  handleShowMore(){
    this.setState((prevState) => {
      return {showSlots:!prevState.showSlots}
    })
  }

  componentDidMount() {
    this.lists();
  };

  renderBookedFacility=(facility, idx)=>{
    const {name, id, start_date, total_amount, end_date, image, status_name, qty, is_cancel_allow} = facility;
    
    return (
      <a 
        href={`cottages/${id}`}
        style={{all:'unset'}} 
        key={idx}
        onClick={()=>{                    
          Mixpanel.track('fm_facility_clicked', facility);
        }}
      >
        <div className="card-container">
          <Box sx={{ display: 'flex', justifyContent:'flex-start' }}>
            <Box sx={{ borderRadius:'10px', display:'flex', justifyContent: 'center'}}>
              <CardMedia
                component="img"
                sx={{ width: 100, height: 110, margin:'10px' }}
                image={Boolean(image) ? FACILITY_IMG_URL+image : FACILITY_DEFAULT_IMG_URL}
                alt="party hall image"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: '1 0 auto', padding:'0.5rem 0px 0px 0.5rem' }}>
                <Typography component="div" variant="h6" sx={{fontFamily:'Jost'}}>
                  {name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontFamily:'Jost'}}>
                  <p style={{fontFamily:'Jost', color:"#888888", fontSize:"15px"}}>
                    Check In: {format_date_str(start_date)}
                  </p> 
                  
                  <p style={{fontFamily:'Jost', color:"#888888", fontSize:"15px"}}>
                    Check Out: {format_date_str(end_date)}
                  </p>

                  <div style={{display:"flex", justifyContent:"space-between"}}>
                    <p style={{color: '#888888', fontFamily:'Jost', fontSize:"15px", lineHeight:"36px"}}>
                      Units: {qty}
                    </p>

                    <p style={{color: '#676AC1', fontFamily:'Jost', fontWeight:"600", fontSize:"16px",lineHeight:"36px"}}>
                      ₹ <strong>{ total_amount > 0 ? parseInt(total_amount) : '--.--'}</strong>
                    </p>
                  </div>

                  <div style={{backgroundColor:'#CCFBD7', width:'fit-content'}}>
                    <p style={{color:'#02811B',fontFamily:'Jost'}}>{status_name}</p>
                  </div>
                  
                </Typography>

              </div>
            </Box>
          </Box>
        </div>
      </a>
    );
  }

  render() {
    return (
      <div className="container">
        {this.state.listLoading === true ? 
          <MyLoader /> : 
          <FlatList
            list={this.state.lists}
            renderItem={this.renderBookedFacility}
            handleShowMore={this.handleShowMore}
            renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}      
          />
        }
      </div>
    )
  }
}
