import { ButtonGroup, Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: "10px",
    borderColor: "rgba(196, 196, 196, 1)",
    "&:hover": {
        borderColor: "rgba(196, 196, 196, 1)"
    },
    maxWidth: "30px"
}));

const StyledInput = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 0,
            borderColor: 'transparent'
            },
        "&:hover fieldset": {
            borderColor: 'transparent'
        },
        "&.Mui-focused fieldset": {
            borderColor: 'transparent'
        },
        "& input": {
            textAlign: "center",
            width: 40,
            border: "1px solid rgba(196, 196, 196, 1)"

        },
        width: "50px"
    }
});

export default function CounterButton({label, subTitle, value, onIncrement, onDecrement, onChange, minValue, maxValue}) {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
                <Typography variant="h6" sx={{fontFamily:'Jost'}} >{label}</Typography>
                {subTitle !== undefined && <Typography variant="subtitle2" sx={{fontFamily:'Jost', color: "rgba(157, 158, 177, 1)"}} component="div">{subTitle}</Typography>}
            </Box>

            <ButtonGroup>
                <StyledButton
                    onClick={onDecrement}
                    disabled={value === minValue}
                >
                    <RemoveIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 1)"}} />
                </StyledButton>
                <StyledInput
                    sx={{ color: "rgba(0, 0, 0, 1)"}}
                    size="small"
                    onChange={onChange}
                    value={value}
                    inputProps={
                        { readOnly: true }
                    }
                />
                <StyledButton onClick={onIncrement} disabled={value === maxValue}>
                    <AddIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 1)"}} />
                </StyledButton>
            </ButtonGroup>
        </Box>
    );
}
