import React, { Component } from 'react'
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './index.css'
import { FACILITY_LIST_URL, FACILITY_IMG_URL, FACILITY_DEFAULT_IMG_URL } from '../Utility/URL';
import { apiCall, MyLoader, NO_DATA_FOUND } from '../Utility/utils';
import FlatList from 'flatlist-react'
import { format_time_str } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';

export default class CottageList extends Component {
  constructor(){
    super();
    this.state = {
      list: [],
      listLoading: true
    }
  }

  async lists(){
    let formData = {
      token: localStorage.getItem("societyToken"),
      type: "cottage"
    };

    let api_options = {
      config: {
        method: 'post',
        url: FACILITY_LIST_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        if(response.data.total>0){
          this.setState({listLoading:false, lists:response.data.data, message: ''});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    // if(typeof this.state.message != "undefined" && this.state.message != ""){
    //   return (<div className="container">{this.state.message}</div>)
    // }

    return (
      <FlatList
        list={this.state.lists}
        renderItem={this.renderFacility}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}    
      />
    )
  }

  renderFacility=(facility, idx)=>{
    const {booking_list_image, name, price, timing, id} = facility
    
    return (
      <a 
        href={"/cottages/amenitydetails/"+id} 
        style={{all:'unset'}} 
        key={idx}
        onClick={()=>{                    
          Mixpanel.track('fm_facility_clicked', facility);
        }}
      >
        <div className="card-container">
          <Box sx={{ display: 'flex', justifyContent:'flex-start' }}>
            <Box sx={{ borderRadius:'10px', display:'flex', justifyContent: 'center'}}>
              <CardMedia
                component="img"
                sx={{ width: 100, height: 110, margin:'10px' }}
                image={Boolean(booking_list_image)?FACILITY_IMG_URL+booking_list_image: FACILITY_DEFAULT_IMG_URL}
                alt="Live from space album cover"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: '1 0 auto', padding:'0.5rem 0px 0px 0.5rem' }}>
                <Typography component="div" variant="h6" sx={{fontFamily:'Jost'}}>
                  {name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontFamily:'Jost'}}>
                  Open: {format_time_str("2022-01-01,"+timing.time_from)}-{format_time_str("2022-01-01,"+timing.time_to)}
                </Typography>
                <p style={{marginBottom:'0',fontFamily:'Jost'}}><b>₹ { price.amount > 0 ? parseInt(price.amount) + '' : '--.--'}</b><span style={{color:'#8F8F8F',}}> {price.name}</span></p>
                <div style={{backgroundColor:'#CCFBD7',width:'fit-content',padding:"0.1rem 0.5rem"}}>
                  <p style={{color:'#02811B',fontFamily:'Jost'}}>Available</p>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </a>
    );
  }



  render() {
    return (
      <>
      <div className="container" >
          {this.load_main_view()}
      </div>
      </>
    )
  }
}
