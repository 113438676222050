import React, { Component } from 'react'
import RadioGroupSelect from './RadioGroupSelect'
import { Typography, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FACILITY_DETAILS_URL, AMENITY_BOOKINGS_SAVE_URL } from '../Utility/URL';
import { apiCall, format_time_str, format_date_str, date_format, SweetAlert, NO_DATA_FOUND } from '../Utility/utils';
import FlatList from 'flatlist-react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom';
import { Mixpanel } from '../Mixpanel';

const styles = {
  container: {
    backgroundColor: '#FBFBFB',
    minHeight: '100vh',
    padding: '1rem',
    width:'100%',
    fontFamily:'Jost'
  },
  label: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'400',
    fontFamily:'Jost'
  },
  keyTextContainer: {
    backgroundColor:'#FFFFFF',
    padding:'0.5rem 1rem',
    marginBottom:'2rem'
  },
  keyText: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'500',
    fontFamily:'Jost', 
    color:'#000000'
  }
}

class TimeSelection extends Component {
  constructor(){
    super();
    this.state = {
      date: moment(),
      is_date_disable: true,
      display_preview: 'none',
      display_slot_selection: 'block',
      time_slots: [],
      selected_time_slots: [],
      amount: 0,
      property_name: "",
      discounts: [],
      pax: 0,
      message: "No time slots available"
    }
  }

  async details(){
    let formData = {
      id: parseInt(window.location.href.split("timeselection/")[1]),
      token: localStorage.getItem("societyToken"),
      date: this.state.date.format('YYYY-MM-DD')
    };

    let api_options = {
      config: {
        method: 'post',
        url: FACILITY_DETAILS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        this.setState({
          pax: response.data.property_info[0].pax, 
          amount: response.data.property_info[0].price[0].amount, 
          discounts: response.data.property_info[0].discount, 
          time_slots:response.data.property_info[0].time_slot, 
          property_name:response.data.property_info[0].name,
          timeslot_booking: response.data.timeslot_booking
        });
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.details();
  };


  selectTimeSlots = (value) => {
    const index = this.state.selected_time_slots.indexOf(value);
    if(index > -1){
      this.state.selected_time_slots.splice(index, 1);
      return this.setState((prevState)=>({selected_time_slots: this.state.selected_time_slots}));
    }
    this.state.selected_time_slots.push(value);
    return this.setState((prevState)=>({selected_time_slots: this.state.selected_time_slots}));
  }

  renderTimeSlots=(time_slot, idx)=>{
    const {start, end} = time_slot;
    let count = 0
    for(var i in this.state.timeslot_booking){
      if(format_time_str("2022-01-01, " + start)===format_time_str("2022-01-01, " + this.state.timeslot_booking[i].time_from)){
        count = this.state.timeslot_booking[i].count;
        break;
      }
    };

    if(this.state.selected_time_slots.indexOf(idx) === -1){
      var color = "#9D9EB1"
      if(count >= this.state.pax || (format_date_str(moment()) == format_date_str(this.state.date) && moment() > moment(start, "hh:mm a").toDate())){
        var div_style = {backgroundColor: "#C8C8C8"};
      }
      else{
        var div_style = {border: "2px solid #B6B6C4"};
      }
    }
    else{
      var div_style = {backgroundColor: "#676AC1"};
      var color = "#FFFFFF"
    }
    
    // const div_style = this.state.selected_time_slots.indexOf(idx) === -1 ? count >= this.state.pax ? {backgroundColor: "#C8C8C8"}: {border: "2px solid #B6B6C4"} : {backgroundColor: "#676AC1"};
    // const color = this.state.selected_time_slots.indexOf(idx) === -1 ? "#9D9EB1" : moment().diff(this.state.date, 'day')==0 && moment() > moment(start, "hh:mm a").toDate() ? "#808080" : "#FFFFFF";
    return (
      <Grid item xs={6} key={idx} 
        onClick={(count >= this.state.pax || (format_date_str(moment()) == format_date_str(this.state.date) && moment() > moment(start, "hh:mm a").toDate())) ? () => "" : () => (this.selectTimeSlots(idx))}>
      {/* <Grid item xs={6} key={idx} onclick="return false" disabled="true"> */}
        <div style={{...div_style, display:'flex', justifyContent:'center',padding:'0.5rem',margin:'0 0.5rem 0 0.5rem', borderRadius:'4px'}}>
          <p style={{color: color}}>{format_time_str("2022-01-01, " + start)} - {format_time_str("2022-01-01, " + end)}</p>
        </div>
      </Grid>
    )
  }

  async save() {
    const data = {
      id: parseInt(window.location.href.split("timeselection/")[1]),
      date: this.state.date.format('YYYY-MM-DD'),
      token: localStorage.getItem("societyToken"),
      amount: parseInt(this.state.amount),
    };
    for(let i in this.state.selected_time_slots){
      data["time_from_"+this.state.selected_time_slots[i]] = this.state.time_slots[this.state.selected_time_slots[i]].start;
      data["time_to_"+this.state.selected_time_slots[i]] = this.state.time_slots[this.state.selected_time_slots[i]].end;
    };

    data.len = this.state.selected_time_slots.length;
    console.log(data)

    var api_options = {
      config: {
        method: "post",
        url: AMENITY_BOOKINGS_SAVE_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(data),
      },
      successCallback: (response)=>{
        console.log("sucess");
        let config = {
          message: response.data.message,
          confirm_btn_txt: "Ok",
          cancel_btn_show: false,
          icon: 'success',
          then_call_back: ()=>this.props.navigator(-3)
        }
        SweetAlert(config);
      },
      errorCallback: (error)=>{
        console.log("Error", error.response);
        let config = {
          message: error.response.data.message,
          confirm_btn_txt: "Yes",
          cancel_btn_show: false,
          icon: 'warning'
        }
        SweetAlert(config);
      }    
    }
    apiCall(api_options);
    
  }

  submitForm = (e) => {
    e.preventDefault()
    this.save();
  }

  update_dt = (id) => {
    if(id==3){
       this.setState({is_date_disable: false})
    } else {
      this.setState({is_date_disable: true, date:  moment().add(id-1, 'days'), selected_time_slots: [] } , () => {
         this.details();
      }); 
      
    }
         
  }

  render() {
    return (
      <>
      <Header societyName={this.state.display_preview === 'none' ? "Choose a Slot":"Slot Confirmation"}/>
      <div className="container" style={{display: this.state.display_slot_selection}}>
        <Typography variant="h6" sx={{marginTop:'1rem',fontFamily:'Jost'}} >Select Date</Typography> 
        <RadioGroupSelect
          css={{ padding:'1rem', backgroundColor:"#FBFBFB" }}
          onChange={ (id) => this.update_dt(id)  }
          choices={[
            {
              id: 1,
              name:'Today'
            },
            {
              id: 2,
              name:'Tommorow'
            },
            {
              id: 3,
              name:'Custom'
            },
          ]} 
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            label="Select Date"
            name="date" 
            inputFormat={date_format}
            disabled={this.state.is_date_disable}
            minDate={moment()} 
            value={this.state.date}
            onChange={(e)=>(this.setState(prevState=> ({date: e}), ()=>this.details() ))}
            renderInput={(params) => 
              <TextField 
                {...params}
                fullWidth
                style={{margin:'1rem 0',}} 
              />}
          />
          
        </LocalizationProvider>
        <Typography variant="h6" sx={{marginTop:'1rem',fontFamily:'Jost'}} >Select Time</Typography> 
        
        <Grid container spacing={1}>
        <FlatList
          list={this.state.time_slots}
          renderItem={this.renderTimeSlots}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}

        />
        </Grid>
        <div style={{backgroundColor:'#F8F8F8',padding:'0.5rem 1rem 0.5rem 1rem', marginTop:'1rem'}}>
          <p style={{color:'#9D9EB1',fontFamily:'Jost'}}>Please note - your booking will be confirmed only after making the payment at the facility management office at Club Tuscany. Kindly do so at the earliest to avoid any incovenience.</p>
        </div>
        <Button 
          disabled = {this.state.selected_time_slots.length>0 ? false : true }
          onClick={()=>{
            this.setState({display_preview: 'block', display_slot_selection: 'none'});
            const data = {
              id: parseInt(window.location.href.split("timeselection/")[1]),
              date: this.state.date.format('YYYY-MM-DD'),
              token: localStorage.getItem("societyToken"),
              amount: parseInt(this.state.amount),
              len: this.state.selected_time_slots.length
            };
            for(let i in this.state.selected_time_slots){
              data["time_from_"+this.state.selected_time_slots[i]] = this.state.time_slots[this.state.selected_time_slots[i]].start;
              data["time_to_"+this.state.selected_time_slots[i]] = this.state.time_slots[this.state.selected_time_slots[i]].end;
            };

            Mixpanel.track("fm_facilit_book_now_clicked", data);
          }} 
          sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',marginTop:'1rem',fontFamily:'Jost'}}
          fullWidth  variant="contained"
        >Book Now</Button>
        <Button
          onClick={()=>window.location.reload()} 
          sx={{ marginBottom:'2rem',marginTop:'1rem', fontFamily:'Jost'}} fullWidth  variant=""
        > Cancel</Button>
      </div>
      {/* Confiramtion Screen on book now click make it visible less hide it */}
      <div style={{display: this.state.display_preview,...styles.container}}>
          <Typography variant='h6' style={styles.label}>Facility:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.property_name}</Typography>
          </div>
          <Typography variant='h6' style={styles.label}>Date:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>{this.state.date.format(date_format)}</Typography>
          </div>
          <Typography variant='h6' style={styles.label}>Total Price:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}>
              Amount : ₹ {
                parseInt(this.state.amount) * 
                this.state.selected_time_slots.length * 
                (this.state.discounts.filter(discount=> discount.category_value==this.state.selected_time_slots.length).length != 0 ? (1 - parseInt(this.state.discounts.filter(discount=> discount.category_value==this.state.selected_time_slots.length)[0].amount)/100):1)
                }
              </Typography>
              <Typography variant='h6' style={styles.keyText}>
              Deposit : ₹ {
                parseInt(this.state.amount) * 
                this.state.selected_time_slots.length * 
                (this.state.discounts.filter(discount=> discount.category_value==this.state.selected_time_slots.length).length != 0 ? (1 - parseInt(this.state.discounts.filter(discount=> discount.category_value==this.state.selected_time_slots.length)[0].amount)/100):1)
                }
              </Typography>
              <Typography variant='h6' style={styles.keyText}>
              Total : ₹ {
                parseInt(this.state.amount) * 
                this.state.selected_time_slots.length * 
                (this.state.discounts.filter(discount=> discount.category_value==this.state.selected_time_slots.length).length != 0 ? (1 - parseInt(this.state.discounts.filter(discount=> discount.category_value==this.state.selected_time_slots.length)[0].amount)/100):1)
                }
              </Typography>
          </div>
          <Typography variant='h6' style={styles.label}>Time:</Typography>
          <div style={styles.keyTextContainer}>
            <Typography variant='h6' style={styles.keyText}> {this.state.selected_time_slots.length==0 ? "Not Selected any slot" : ""}</Typography>
            {/* <Typography variant='h6' style={styles.keyText}>11: 00 AM TO 12:00 PM, 11: 00 AM TO 12:00 PM, 11: 00 AM TO 12:00 PM</Typography> */}
            {
                (() => {
                let container = [];
                this.state.selected_time_slots.forEach((val, index) => {
                  container.push(<Typography variant='h6' key={val} style={styles.keyText}>{format_time_str("2022-01-01, " + this.state.time_slots[val].start) + " - " + format_time_str("2022-01-01, " + this.state.time_slots[val].end)}</Typography>);
                });
                return container;
              })()
            }
          </div>
          <Button onClick={(e)=>this.submitForm(e)} sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',marginTop:'1rem',fontFamily:'Jost'}} fullWidth  variant="contained">Confirm</Button>
          <Button onClick={()=>this.setState({display_preview: 'none', display_slot_selection: 'block'})} sx={{ marginBottom:'1rem',marginTop:'1rem', fontFamily:'Jost'}} fullWidth  variant="">Cancel</Button>
      </div>
      </>
    )
  }
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(TimeSelection);

// Inactive Time Slot are already taken by other people