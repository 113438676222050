import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Card as CardBT } from 'react-bootstrap';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import img from '../Assets/image_10.png'
import { Link } from 'react-router-dom'
import { suppressDeprecationWarnings } from 'moment';
import { apiCall, SweetAlert } from '../Utility/utils';
import { DAILYHELPER_STAFF_DELETE_URL, DAILYHELPER_STAFF_DETAILS_URL, DAILY_HELPER_IMG_URL } from '../Utility/URL';
import Header from '../Header';
import { Mixpanel } from '../Mixpanel';

class HelperDetails extends Component {

  constructor(){
    super();
    this.state = {
      details: {name:"", first_name: "", last_name: "", email: "", cotact: "", notes: "", address: "", services: [], image: ""},
      loading: true
    }
  }

  async lists() {
    var formData = {}
    formData.token =  localStorage.getItem("societyToken");
    formData.id = parseInt(window.location.href.split('dailyhelpers/')[1]);
    var api_options = {
      config:{
        method:'post',
        url: DAILYHELPER_STAFF_DETAILS_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),
      },
      successCallback:(response)=>{
        return this.setState({listLoading:false,details:response.data.data.details, message: '', loading: false});
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  delete = () => {
    const formData = {
      id: parseInt(window.location.href.split('dailyhelpers/')[1]),
      token: localStorage.getItem("societyToken")
    }
    var api_options = {
      config:{
        method:'post',
        url: DAILYHELPER_STAFF_DELETE_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),
      },
      successCallback:(response)=>{
        window.location.href = '/dailyhelpers/list';
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);

  }

  handleOpen(){

    let config = {
      message: "AAre you sure to delete dailyhelper?",
      confirm_btn_txt: "Yes",
      cancel_btn_txt: "No",
      cancel_btn_show: true,
      icon: 'warning',
      then_call_back: (result)=>{
        if (result.isConfirmed) {
          this.delete();
        }
      }
    }
    SweetAlert(config);
  }
  
  render_sub_view = () => {

    if(this.state.listLoading == true){
      return (
        <div>Loading</div>
        );
    }
    const {name, first_name, last_name, email, contact, notes, address, services, image, id, gatepass_count} = this.state.details;
    var gate_pass = gatepass_count > 0 ? 'Gatepass Available' : "";    

    return (
      <>
      <Header societyName={"DailyHelper Details"}/>
      <div>
        <div className="cd-header" style={{ padding: "1rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Avatar
              alt="Remy Sharp"
              src={DAILY_HELPER_IMG_URL+image}
              sx={{ width: 80, height: 80, marginTop: "1rem" }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography
                component="div"
                variant="h6"
                sx={{ fontFamily: "Jost" }}
              >
                {first_name + " " + last_name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ fontFamily: "Jost" }}
              >
               
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ color: "#70F28C", fontFamily: "Jost" }}
              >
                {gate_pass}
              </Typography>
            </CardContent>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            

            <a href={"tel:" + contact}
              onClick={()=>{
                Mixpanel.track("fm_dailyhelper_details_call_clicked");
              }}>
            <IconButton
              fontSize="small"
              sx={{ background: "#E1E1F2", marginTop: "1.5rem" }}
            >
              <LocalPhoneOutlinedIcon
                fontSize="small"
                sx={{ color: "#676AC1" }}
              />
            </IconButton>

            </a>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Link to={`/dailyhelpers/update/${id}`} replace >
              <IconButton
                fontSize="small"
                onClick={()=>{
                  Mixpanel.track("fm_dailyhelper_details_edit_clicked");
                }}
                sx={{ background: "#E1E1F2", marginTop: "1.5rem" }}
                >
                  <ModeEditOutlineOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#676AC1" }}
                    />
              </IconButton>
            </Link>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <IconButton
              fontSize="small"
              sx={{ background: "#E1E1F2", marginTop: "1.5rem" }}
              // onClick={this.delete}
              onClick={()=>(this.handleOpen(), Mixpanel.track("fm_dailyhelper_details_delete_clicked"))}
            >
              <DeleteSweepOutlinedIcon
                fontSize="small"
                sx={{ color: "#676AC1" }}
              />
            </IconButton>
          </Box>
        </div>
        <div className="text-container">
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            PERSONAL DETAILS:
          </Typography>
          <CardBT.Body className="text-muted">
            {/* show more */}
            <div style={{ display: "flex" }}>
              <p style={{ width: "40%" }}>Contact:</p>
              <p style={{ width: "60%" }}>{contact}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "40%" }}>Email id:</p>
              <p style={{ width: "60%" }}>{email}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "40%" }}>Address:</p>
              <p style={{ width: "60%" }}>
                {address}
              </p>
            </div>
          </CardBT.Body>
        </div>
        <div className="text-container">
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            SERVICE DETAILS:
          </Typography>
          {/* <CardBT.Body className="text-muted"> */}
          <div className="text-muted" style={{ marginTop: "1rem" }}>
            <ul>
              {
                (() => {
                  let container = [];
                  services.forEach((val, index) => {
                    container.push(<li key={index}>{val.name}</li>)
                  });
                  return container;
                })()
              }              
            </ul>
          </div>
          {/* </CardBT.Body> */}
        </div>

        
          <div className="text-container" style={{ height: "240px", display: "none" }}>
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            ID Card:
          </Typography>
          <img src="" alt="ID Card Image" />
        </div> 
      
        <div className="text-container">
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            Note:
          </Typography>
          <CardBT.Body className="text-muted">
            {/* show more */}
            <p>
              {notes}
            </p>
          </CardBT.Body>
        </div>
      </div>
      </>
      )
  }

  render(){

    return this.render_sub_view();

    const {name, first_name, last_name, email, contact, notes, address, services, image, id, gatepass_count} = this.state.details;
    var gate_pass = gatepass_count > 0 ? 'Gatepass Available' : "";
    console.log(services);

    return (
      <div>
        <div className="cd-header" style={{ padding: "1rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Avatar
              alt="Remy Sharp"
              src={img}
              sx={{ width: 80, height: 80, marginTop: "1rem" }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography
                component="div"
                variant="h6"
                sx={{ fontFamily: "Jost" }}
              >
                {first_name + " " + last_name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ fontFamily: "Jost" }}
              >
               
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ color: "#70F28C", fontFamily: "Jost" }}
              >
                {gate_pass}
              </Typography>
            </CardContent>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <IconButton
              fontSize="small"
              sx={{ background: "#E1E1F2", marginTop: "1.5rem" }}
            >
              <LocalPhoneOutlinedIcon
                fontSize="small"
                sx={{ color: "#676AC1" }}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Link to={`/dailyhelpers/update/${id}`}>
              <IconButton
                fontSize="small"
                sx={{ background: "#E1E1F2", marginTop: "1.5rem" }}
                >
                  <ModeEditOutlineOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#676AC1" }}
                    />
              </IconButton>
            </Link>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <IconButton
              fontSize="small"
              sx={{ background: "#E1E1F2", marginTop: "1.5rem" }}
              onClick={this.delete}
            >
              <DeleteSweepOutlinedIcon
                fontSize="small"
                sx={{ color: "#676AC1" }}
              />
            </IconButton>
          </Box>
        </div>
        <div className="text-container">
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            PERSONAL DETAILS:
          </Typography>
          <CardBT.Body className="text-muted">
            {/* show more */}
            <div style={{ display: "flex" }}>
              <p style={{ width: "40%" }}>Contact:</p>
              <p style={{ width: "60%" }}>{contact}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "40%" }}>Email id:</p>
              <p style={{ width: "60%" }}>{email}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "40%" }}>Address:</p>
              <p style={{ width: "60%" }}>
                {address}
              </p>
            </div>
          </CardBT.Body>
        </div>
        <div className="text-container">
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            SERVICE DETAILS:
          </Typography>
          {/* <CardBT.Body className="text-muted"> */}
          <div className="text-muted" style={{ marginTop: "1rem" }}>
            <ul>
              {
                (() => {
                  let container = [];
                  services.forEach((val, index) => {
                    container.push(<li key={index}>{val.name}</li>)
                  });
                  return container;
                })()
              }
              
            </ul>
          </div>
          {/* </CardBT.Body> */}
        </div>
        <div className="text-container" style={{ height: "240px" }}>
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            ID Card:
          </Typography>
          <img src="" alt="ID Card Image" />
        </div>
        <div className="text-container">
          <Typography variant="h5" sx={{ fontSize: 20, fontFamily: "Jost" }}>
            Note:
          </Typography>
          <CardBT.Body className="text-muted">
            {/* show more */}
            <p>
              {notes}
            </p>
          </CardBT.Body>
        </div>
      </div>
    );
  }
}

export default HelperDetails