import React from 'react'
import ReactDom from 'react-dom'
import CheckTrue from '../Assets/check-circle-green.svg'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1000,
  width: '80%',
  maxWidth: '500px',
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}
const Divider = {
  backgroundColor:"#F3F3F3",
  height:"2px",
  border: "1px solid #F3F3F3",
}

const NAME_STYLES = {
  fontFamily: "Jost",
  fontSize: "16px",

}

const PHONE_STYLES = {
  fontFamily: "Jost",
  fontSize: "13px",
  color: "#888888",
}

export default function ContactsModal({ open, contacts, onClose, action }) {
  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose}/>
      <div style={MODAL_STYLES}>
      <div style={{maxHeight: '50vh',overflowY:'scroll',scrollBehavior:'smooth'}}>
          {contacts?.map((item) => (
            <div key={item.phoneNo} onClick={()=>action(item.name, item.phoneNo)}>
              <p style={NAME_STYLES}>{item.name}</p>
              <p style={PHONE_STYLES}>{item.phoneNo}</p>
              <div style={Divider}></div>
            </div>
          ))}
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}
