import React,{ Component } from 'react'
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChatIcon from '../Assets/bi_chat-text.svg'
import { Avatar, Typography } from '@mui/material';
import UserCircleIcon from '../Assets/user-circle.svg'
import { apiCall, format_date_time_str, MyLoader } from '../Utility/utils';
import { COMPLAINTS_LIST_URL, COMPLAINTS_RESPONSE_LIST_URL, COMPLAINTS_RESPONSE_SAVE_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Grid from '@mui/material/Grid';
import moment from 'moment';
import SendIcon from '@mui/icons-material/Send';
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    minHeight: '100vh',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  card: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.05)',
    borderRadius: '5px',
    padding:'1rem',
    marginTop:'1rem'
  },
  navContainer: {
    width:'50%',
    padding:'1rem',
    textAlign:'center'
  },
  navHover: {
    borderBottom:'1px solid #676AC1',
    color:'#676AC1'
  },
  divider: {
    height: '1px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  Open: {
    padding:'0.3rem 1rem', 
    backgroundColor:'#F3F9F4', 
    borderRadius:'5px', 
    color:'#009A22'
  },
  Close: {
    padding:'0.3rem 1rem', 
    backgroundColor:'#FFEBEB', 
    borderRadius:'5px', 
    color:'#FF0000'
  }
}

const ProfileGridItem = (description) => {
  const desc = Boolean(description.desc) ? description.desc : "";
  const [isCollapse, setIsCollapse] = React.useState(true);
  let trunc_length = 100;
  return (
    <>  
      {desc.length > trunc_length ? isCollapse ? desc.substring(0, trunc_length)+"...": desc : desc}
      {desc.length > trunc_length ?  <span onClick={() => setIsCollapse(isCollapse => !isCollapse)} style={{color:'#676AC1',}}>{isCollapse ? 'Show more' : 'Show less'}</span> : <></>}
       
    </>
  )
}

class Community extends Component {
  constructor(){
    super();
    this.state = {
      list: [],
      prorities: [],
      issues: 0,
      complain_responses: {},
      listLoading: true,
      complain_status: "",
      priority_id: null,
    }
    this.toggler = {};
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken"),
      priority_id: this.state.priority_id
    }
            
    var api_options = {
      config: {
        method:'post',
        url: COMPLAINTS_LIST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(!Boolean(this.state.priority_id)){
          this.setState({priority_id: response.data.prorities[0].id, complain_status: response.data.prorities[0].name, prorities:response.data.prorities});
        }
        if(response.data.community.length > 0){
          return this.setState({listLoading:false, lists:response.data.community, issues: response.data.community.length, message: ''});
        }else {
          return this.setState({listLoading:false, lists: [], issues: response.data.community.length, message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  async responseList(id) {
    var formData = {
      id: id,
      token: localStorage.getItem("societyToken")
    }
            
    var api_options = {
      config: {
        method:'post',
        url: COMPLAINTS_RESPONSE_LIST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        console.log("inside response list");
        if(response.data.total > 0){
          return this.setState(prevState => ( { complain_responses: {...prevState.complain_responses, [id]: response.data.data} }) )
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  toggle_div = (community) => {
    this.toggler['community_' + community.id] = !this.toggler['community_' + community.id];
    this.setState({render: true});
  }

  renderResponse = (response, idx ) => {

    const {name, image, desc, created_at, id} = response;
    return (
      <div style={{backgroundColor:'#FBFBFB',marginTop:'0.3rem'}} key={idx}>
        <div style={{display:'flex', justifyContent:'space-between',padding:'0.2rem 1rem 0.2rem 0.5rem'}}>
            <Avatar alt="avatar" src={UserCircleIcon} />
            <div style={{marginLeft:'5px', width:'100%'}}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <p style={{fontSize:'16px',lineHeight:'36px',fontWeight:'400',color:'#17171C'}}>{name}</p>
                </Grid>
                <Grid item xs={6}>
                  <p style={{fontSize:'11px', lineHeight:'36px', fontWeight:'400',color:'#9D9EB1'}}>{format_date_time_str(created_at)}</p>
                </Grid >
              </Grid>       
              <p style={{fontSize:'16px',lineHeight:'24px',fontWeight:'400',color:'#17171C'}}>{desc}</p>
            </div>
        </div>
      </div>
    );
  }

  async saveResponse(id) {
    var data = {
      id: id,
      token: localStorage.getItem("societyToken"),
      desc: this.state.send_response[id]
    }
    
    console.log("reponse send..............", data)
    var api_options = {
      config: {
        method: "post",
        url: COMPLAINTS_RESPONSE_SAVE_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
      },
      successCallback: (response)=>{
        window.location.reload();
      },
      errorCallback: (error)=>{console.log("Error", error.response);}    
    }
    apiCall(api_options);
  }


  renderCommunity = (community, idx ) => {
    if(typeof this.toggler['community_' + community.id] == "undefined"){
      this.toggler['community_' + community.id] = false;  
    }
    var display_prop = this.toggler['community_' + community.id] == false ? 'none' : '';
    var display_text = this.toggler['community_' + community.id] == false ? 'show more' : 'show less';

    const {name, status_name, updated_at, desc, id, response_count} = community;
    return (
      <div style={{...styles.card}} key={idx}>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <p style={{alignSelf:'center',fontSize:"16px", fontWeight:"400", lineHeight:"36px"}}>Complaint id : {id}</p>
          <div style={styles[status_name]}>
            <p>{status_name}</p>
          </div>
        </div>
        <p style={{fontFamily:'Jost',color:"#57564F", fontSize:"20px", fontWeight:"600", lineHeight:"36px"}}>{name}</p>
        <p style={{fontFamily:'Jost',color:'#57564F', fontSize:"14px", fontWeight:"400", lineHeight:"24px"}}>{format_date_time_str(updated_at)}</p>
        <p style={{fontFamily:'Jost',color:'#57564F', display: 'none',fontSize:"14px", fontWeight:"400", lineHeight:"24px"}}><ProfileGridItem desc={desc} /> </p>
        <div style={styles.divider}></div>
        <div style={{display:'flex', color:'#888888', fontFamily:'Jost'}}>
          <img src={ChatIcon} />
          <p style={{marginLeft:'0.5rem'}}>{response_count} Response</p>
        </div>
        <span style={{color:'#676AC1'}} 
          onClick={ () => {
            this.toggle_div(community);
            this.responseList(id);
        }}>
        {display_text}</span>
        <div style={{ display: display_prop }}>
          <FlatList
            list={this.state.complain_responses[id]}
            renderItem={this.renderResponse}
            renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
          />
          <div style={{ display: 'none' }}>
          <TextField
            id="outlined-multiline-static"
            label="Share your feedback with the facility manager"
            multiline
            minRows={1}
            style={{width:'100%'}}
            variant="outlined"
            name="desc"
            onChange={(e) => this.setState(prevState=>{
              return {send_response: {...prevState.send_response,  [community.id] :e.target.value}}
            })} 
            // error={this.state.desc_error !== "" ? true : false}
            // helperText={this.state.desc_error}
          />
          <SendIcon size="large" fontSize="large" onClick={(e)=>this.saveResponse(community.id)} />
            </div>
        </div>
      </div>
    );
  }

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };
  
  handleClose = () => {
    this.setState({anchorEl: null});
  };



  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderCommunity}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render(){
    return (
      <>
      <div style={styles.container}>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <p style={{color:'#9D9EB1'}}>{this.state.issues} issues</p>
          <>
            <p
              id="basic-button"
              aria-controls={Boolean(this.state.anchorEl) ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
              onClick={this.handleClick}
              style={{fontFamily:'Jost', fontSize:'14px', lineHeight:'36px', fontWeight:'400'}}
            >
              <strong>{this.state.complain_status}</strong><ArrowDropDownIcon/>
            </p>
            <Menu
              id="basic-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {
                (() => {
                let container = [];
                this.state.prorities.forEach((val, index) => {
                  container.push(<MenuItem key={val.id} value={val.id} onClick={(e)=>{this.setState({priority_id: e.target.value, complain_status: val.name}, this.lists);this.handleClose();}}>{val.name}</MenuItem>);
                });
                return container;
              })()
              }
            </Menu>
          </>
        </div>
        {this.load_main_view()}
      </div>
      </>
    )
  }
}

export default Community