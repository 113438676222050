import React, { Component } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Download_logo from '../Assets/download_logo.svg'
import Share_logo from '../Assets/share.svg'
import { apiCall } from '../Utility/utils';
import { ACCOUNTS_INCOME_EXPENDITURE_LIST_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
// import { display } from '@mui/material/node_modules/@mui/system';

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    // minHeight: '100vh',
    width:'100%',
    fontFamily:'Jost'
  },
  text: {
    lineHeight:'36px',
    fontSize:'16px',
    fontWeight:'400',
    color:"#9D9EB1"
  },
  textContainer: {
    marginTop:'1.5rem',
    background: '#FBFBFB',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    padding: '0.5rem',
    '&:hover': {
      background: "#676AC1",
      color: '#FFFFFF'
    },
  },
  headText:{
    color:'#57564F',
    fontWeight:'600',
    lineHeight:'36px',
    fontSize:'16px'
  },
  greyTextContainer: {
    backgroundColor:'#FBFBFB',
    padding:'0.7rem 1.5rem',
  },
  divider:{
    backgroundColor:"#F3F3F3",
    height:"2px",
    border: "1px solid #F3F3F3",
  },
  yellowTextContainer: {
    backgroundColor:'#FFFDF1',
    padding:'0.7rem 1.5rem',
    borderRadius:'5px'
  },
  yellowTextHead:{
    color:"#000000",
    fontFamily:'Jost',
    lineHeight:"36px",
    fontSize:"16px",
    fontWeight:"500"
  },
  yellowText:{
    color:"#57564F",
    fontFamily:'Jost',
    lineHeight:"24px",
    fontSize:"13px",
    fontWeight:"400"
  },
  button: {
    background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',
    height:'56px',
    fontFamily:'Jost',
    fontSize:'18px',
    lineHeight:'36px',
    fontWeight:'700',
  },
  colorButton:{
    background: '#E5E6F4',
    height:'50px',
    display:'flex',
    justifyContent:'center',
    borderRadius:'5px'
  },
  colorButtonText: {
    alignSelf:'center',
    fontFamily:'Jost',
    fontSize:'15px',
    lineHeight:'26px',
    fontWeight:'600',
    color:'#676AC1'
  }

}

export default class IncomeExpenditure extends Component {
  constructor(){
    super();
    this.state = {
      assets: [],
      liablities: []
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    }
            
    var api_options = {
      config: {
        url: ACCOUNTS_INCOME_EXPENDITURE_LIST_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
         if(response.data.total > 0){
          return this.setState({assets:response.data.assets, liablities: response.data.liablities});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };
  
  // renderAsset = (asset, idx) => {
  //   const {name, company_name, contact, address, email} = asset;
  //   return (
  
  //    )
  // }

  // renderLiablity = (liablity, idx) => {
  //   const {name, company_name, contact, address, email} = liablity;
  //   return (
  
  //    )
  // }

  componentDidMount() {
    this.lists();
  };

  render() {
    return (
      <div style={styles.container}>
        <Grid container spacing={2} style={{padding:"1rem"}}>
          <Grid item xs={6}>
            <TextField label='Date' />
          </Grid>
          <Grid item xs={6}>
            <TextField label='Date' />
          </Grid>
          <Grid item xs={12}>
            <Button sx={styles.button} fullWidth  variant="contained">Done</Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{padding:"1rem"}}>
          <Grid item xs={6}>
            <div style={styles.colorButton}>
              <p style={styles.colorButtonText}>Share <img src={Share_logo}/></p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={styles.colorButton}>
              <p style={styles.colorButtonText}>Download <img src={Download_logo}/></p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={styles.yellowTextContainer}>
              <p style={styles.yellowTextHead}>Bank</p>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <p style={styles.yellowText}>Opening bal.</p>
                <p style={styles.yellowText}>00</p>
              </div>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <p style={styles.yellowText}>Closing bal.</p>
                <p style={styles.yellowText}>00</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={styles.yellowTextContainer}>
              <p style={styles.yellowTextHead}>Cash</p>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <p style={styles.yellowText}>Opening bal.</p>
                <p style={styles.yellowText}>00</p>
              </div>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <p style={styles.yellowText}>Closing bal.</p>
                <p style={styles.yellowText}>00</p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div style={styles.greyTextContainer}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={styles.headText}>Assets</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>Amount</h5>
            </Grid>
          </Grid>
        </div>
        <div style={{padding:'0.7rem 1.5rem'}}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"18px"}}>Transfer fees receivables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"18px"}}>FD Income interest receivables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"18px"}}>Late payment income recievables</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
          </Grid>
        </div>
        <div style={styles.greyTextContainer}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={styles.headText}>Liabilities</h5>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>Amount</h5>
            </Grid>
          </Grid>
        </div>
        <div style={{padding:'0.7rem 1.5rem'}}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"18px"}}>Current Liabilities</h5>
              <p style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"14px"}}>Member advanced</p>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"18px"}}>Reserve and Surplus</h5>
              <p style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"14px"}}>Member advanced</p>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
            <Grid item xs={12}>
              <div style={styles.divider}></div>
            </Grid>
            <Grid item xs={9}>
              <h5 style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"18px"}}>Income & Expenditure account</h5>
              <p style={{color:"#9D9EB1",fontFamily:"Jost", fontSize:"14px"}}>Member advanced</p>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
              <h5 style={styles.headText}>0.00</h5>
            </Grid>
          </Grid>
        </div>
        
      </div>
    )
  }
}