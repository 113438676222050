import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import { apiCall, MyLoader } from '../Utility/utils';
import { CONSTRUCTION_NOC_LIST_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Header from '../Header';
import { useParams, useNavigate   } from 'react-router-dom'
import { NO_DATA_FOUND } from '../Utility/utils';
import { Mixpanel } from '../Mixpanel';

// const styles = {
//   container: {
//     backgroundColor: '#F9F9F9',
//     minHeight: '100vh',
//     padding: '1rem',
//     width:'100%'
//   },
//   text: {
//     marginLeft:'1rem',
//     fontFamily:'Jost',
//     lineHeight:'36px',
//     fontSize:'16px',
//     fontWeight:'400'
//   },
//   textContainer: {
//     marginTop:'1.5rem',
//     background: '#FBFBFB',
//     boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
//     borderRadius:'5px',
//     padding: '0.5rem',
//     '&:hover': {
//       background: "#676AC1",
//       color: '#FFFFFF'
//     },
//   }
// }

const styles = {
  container: {
    // padding:'1rem',
    fontFamily:'Jost',
  },
  card: {
    borderTop:'1rem solid #F4F4F7',
  },
  cardHead: {
    backgroundColor:'#E1E1F2',
    padding:'0.3rem 2rem'
  },
  cardBody: {
    padding:'0.5rem 2rem'
  },
  cardBodyText: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '36px'
  },
}

class NOCList extends Component {
  constructor() {
    super();
    this.state = {
      lists: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: CONSTRUCTION_NOC_LIST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ""});
        } else {
          
          return this.setState({listLoading:false, lists: [], message: response.data.message}, ()=> this.props.navigator("/construction/noc", { replace: true }));
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderNocs = (noc, idx ) => {
    const {contractor_name, service_desc, construction_by, notes, notes_by_facility, status_name} = noc;
    return (
      <div style={styles.card} key={idx}>
        <div style={styles.cardBody}>
          <Grid container spacing={0} style={{marginTop:'0.5rem'}}>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Contractor Name: </p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{contractor_name}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Construction Company: </p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{construction_by}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Description:</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{service_desc}</p>
            </Grid> 
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Notes:</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{notes}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Rejection Reason:</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{notes_by_facility}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>Status:</p>
            </Grid>
            <Grid item xs={6}>
              <p style={styles.cardBodyText}>{status_name}</p>
            </Grid>             
          </Grid>
        </div>
      </div>
    );
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderNocs}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render() {
    return (
      <>
      <Header societyName="NOC"/>
      <div>
        {this.load_main_view()}
        <Link
          onClick={()=>{
            Mixpanel.track("fm_construction_noc_new_clicked");
          }}
          to={{
            pathname: "/construction/noc",
            state: {visitor:true}
          }} style={{all:'unset'}}>
          <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
            <AddIcon size="large" fontSize="large" />
          </Fab>
        </Link>
      </div>
      </>
    )
  }
}


function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigator={useNavigate()} />;
}

export default withParams(NOCList);