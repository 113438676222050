import { Avatar } from '@mui/material'
import React, { Component } from 'react'
import EmergencyIcon from '../Assets/directory_assets/emergency-icon.svg'
import GuardIcon from '../Assets/directory_assets/guard-icon.svg'
import HelpersIcon from '../Assets/directory_assets/helpers-icon.svg'
import PersonalIcon from '../Assets/directory_assets/personal-icon.svg'
import ResidentsIcon from '../Assets/directory_assets/residents-icon.svg'
import VehicleIcon from '../Assets/directory_assets/vehicle-icon.svg'
import { Link } from 'react-router-dom'
import Header from '../Header'
import { Mixpanel } from '../Mixpanel'

const styles = {
  container: {
    // backgroundColor: '#F7F7F7',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  listContainer: {
    backgroundColor:'#FBFBFD',
    padding:'0.5rem 1rem',
    display:'flex',
    marginTop:'0.5rem',
    textDecoration: 'none',
    color: "black"
  },
  listContainerHover: {
    backgroundColor:'#676AC1',
    color:'#FFFFFF'
  },
  avatar: {
    bgcolor: '#FFFFFF',
    marginRight:'0.5rem' 
  },
  listText: {
    alignSelf:'center',
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'400'
  }
  
}

export class Directory extends Component {
  
  render() {
    return (
      <>
      <Header societyName="Directory"/>
      <div style={styles.container}>
        
        <Link to="/directory/helperdirectory" style={styles.listContainer}
          onClick={()=>{
            Mixpanel.track("fm_directory_helperdirectory_clicked");
          }}
        >
          <Avatar sx={styles.avatar} >
            <img alt="Helpers Directory" src={HelpersIcon} /> 
          </Avatar>
          <p style={styles.listText}>Helpers Directory</p>
        </Link>
        <Link to="/directory/emergencycontactdirectory" style={styles.listContainer}
          onClick={()=>{
            Mixpanel.track("fm_directory_emergencycontactdirectory_clicked");
          }}
        >
          <Avatar sx={styles.avatar} >
            <img alt="Emergency Directory" src={EmergencyIcon} /> 
          </Avatar>
          <p style={styles.listText}>Emergency Directory</p>
        </Link>
        <Link to="/directory/guarddirectory" style={styles.listContainer}
          onClick={()=>{
            Mixpanel.track("fm_directory_guarddirectory_clicked");
          }}
        >
          <Avatar sx={styles.avatar} >
            <img alt="Guards Directory" src={GuardIcon} /> 
          </Avatar>
          <p style={styles.listText}>Guards Directory</p>
        </Link>
        <Link to="/directory/vehicledirectory" style={styles.listContainer}
          onClick={()=>{
            Mixpanel.track("fm_directory_vehicledirectory_clicked");
          }}
        >
          <Avatar sx={styles.avatar} >
            <img alt="Vehicle Directory" src={VehicleIcon} /> 
          </Avatar>
          <p style={styles.listText}>Vehicle Directory</p>
        </Link>
      </div>
      </>
    )
  }
}

export default Directory