import React,{ Component } from 'react'
import SearchIcon from '../Assets/search.svg'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Avatar } from '@mui/material';
import Avatar_png from '../Assets/Ellipse_3.png'
import PhoneIcon from '../Assets/phone-icon.svg'
import Grid from '@mui/material/Grid';
import Header from '../Header';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D2D2D2',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop:'1rem',
  // marginRight:'1rem',
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  height:'48px',
  flexGrow:1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const styles = {
  container: {
    // backgroundColor: '#F7F7F7',
    width:'100%',
    padding:'1rem',
    fontFamily:'Jost'
  },
  card: {
    marginTop:'1rem',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    padding:'0.2rem 1rem'
  },
  call: {
    fontSize:'14px',
    lineHeight:'36px',
    fontWeight:'600 semi bold',
    color:'#676AC1'
  },
  show: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'400',
    color:'#676AC1',
    textDecoration:'underline',
  },
  cardHead: {
    display:'flex',
    justifyContent:'space-between',
  },
  cardBodyText16: {
    fontSize:'16px',
    fontWeight:'400',
    lineHeight:'36px',
    color:'#888888'
  },
  cardBodyText14: {
    fontSize:'14px',
    fontWeight:'400',
    lineHeight:'36px',
    color:'#888888'
  },
}

class List extends Component {
  
  render(){
    return (
      <>
      <Header />
      <div style={styles.container}>
        <Search>
          <SearchIconWrapper>
            <img alt='search' src={SearchIcon}/>
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            sx={{}}
          />
        </Search>
        <div style={styles.card}>
          <div style={styles.cardHead}>
            <div style={{display:'flex'}}>
              <Avatar alt="avatar" src={Avatar_png} />
              <div style={{marginLeft:'0.5rem'}}>
                <p style={{fontSize:'16px',lineHeight:'36px',fontWeight:'400',color:'#17171C'}}>Anisha</p>
                <p style={{fontSize:'13px',lineHeight:'15px',fontWeight:'400',color:'#888888'}}>Daughter</p>
                <p style={styles.show}>Show more</p>
              </div>
            </div>
            <p style={styles.call}><img src={PhoneIcon} style={{marginBottom:'0.3rem'}}/>Call</p>
          </div>
        </div>
        <div style={styles.card}>
          <div style={styles.cardHead}>
            <div style={{display:'flex'}}>
              <Avatar alt="avatar" src={Avatar_png} />
              <div style={{marginLeft:'0.5rem'}}>
                <p style={{fontSize:'16px',lineHeight:'36px',fontWeight:'400',color:'#17171C'}}>Anisha</p>
                <p style={{fontSize:'13px',lineHeight:'15px',fontWeight:'400',color:'#888888'}}>Wife</p>
              </div>
            </div>
            <p style={styles.call}><img src={PhoneIcon} style={{marginBottom:'0.3rem'}}/>Call</p>
          </div>
          <Grid container spacing={1} style={{marginTop:'0.5rem'}}>
            <Grid item xs={4}>
              <p style={styles.cardBodyText16}>Contact:</p>
            </Grid>
            <Grid item xs={8}>
              <p style={styles.cardBodyText14}>96325 25420</p>
            </Grid>
            <Grid item xs={4}>
              <p style={styles.cardBodyText16}>Email id :</p>
            </Grid>
            <Grid item xs={8}>
              <p style={styles.cardBodyText14}>anisha2022@gmail.com</p>
            </Grid>
            <Grid item xs={4}>
              <p style={styles.cardBodyText16}>Description :</p>
            </Grid>
            <Grid item xs={8}>
              <p style={styles.cardBodyText14}>lorem ipsum is a dummy text</p>
            </Grid>
            
          </Grid>
          <p style={styles.show}>Show less</p>
        </div>
        {/* <a href="/directory/create" style={{all:'unset'}}>
          <Fab className="fab" color="primary" aria-label="add" style={{position:'fixed', right:'1rem', bottom:'16px'}}>
            <AddIcon size="large" fontSize="large" />
          </Fab>
        </a> */}
      </div>
      </>
    )
  }
}

export default List