import React, { Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Avatar_png from '../Assets/Ellipse_3.png'
import { apiCall, MyLoader } from '../Utility/utils';
import { COMMUNITY_ANNOUNCEMENT_LIST_URLS } from '../Utility/URL';
import FlatList from 'flatlist-react'
import moment from 'moment';
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%'
  },
  card: {
    backgroundColor: "#FFFFFF",
    // marginTop: "2rem",
    padding: "0.5rem 1rem 1rem 1rem",
    borderTop: "2rem solid #F7F7F7",
  },
  cardHeaderP1: {
    fontSize:'16px',
    lineHeight:'36px',
    fontWeight:'500',
    fontFamily:'Jost'
  },
  cardHeaderP2: {
    fontSize:'13px',
    fontWeight:'400',
    fontFamily:'Jost',
    color:'#9D9EB1'
  },
  cardBody: {
    fontFamily: 'Jost',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '32px'
  },
  divider: {
    height: '2px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}

export default class Notice extends Component {
  constructor() {
    super();
    this.state = {
      lists: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: COMMUNITY_ANNOUNCEMENT_LIST_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderAnnouncements = (announcement, idx ) => {
    const {name, created_name, desc, image, meeting_datetime} = announcement;

    return (
      <div style={styles.card} key={idx}>
        <div style={{ display: "flex" }}>
          <Avatar alt="avatar" src={image} />
          <div style={{ marginLeft: "0.8rem" }}>
            <p style={styles.cardHeaderP1}>{created_name}</p>
            <p style={styles.cardHeaderP2}>{meeting_datetime && moment(meeting_datetime).format("DD MMM YYYY, hh:mmA")}</p>
          </div>
        </div>
        <p style={styles.cardBody}>{desc}</p>
        <div style={styles.divider}></div>
        {/* <p style={styles.cardBody}>For further details contact : 96455 21365</p> */}
      </div>
    );
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderAnnouncements}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render() {
    return (
      <>
      <Header societyName="Notice"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    );
  }
}
