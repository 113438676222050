import React, { Component } from 'react'
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { format_time_str } from '../Utility/utils';
import FlatList from 'flatlist-react'
import Header from '../Header';
import cycle_svg from '../Assets/community_assets/cycle.svg'
import { apiCall, MyLoader, SweetAlert, format_date_str } from '../Utility/utils';
import { COMMUNITY_LOST_AND_FOUND_LIST_URL, COMMUNITY_LOST_AND_FOUND_IMG_URL  } from '../Utility/URL';
import { Link } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%',
    padding:"1rem"
  },
  card: {
    // backgroundColor: "#FFFFFF",
    padding: "0.2rem 0.2rem 0.2rem 0.5rem",
    // borderTop: "2rem solid #F7F7F7",
    display:"flex",
    // border:"1px solid black"
  },
  cardHeaderP1: {
    fontSize:'18px',
    lineHeight:'26px',
    fontWeight:'600',
    fontFamily:'Jost'
  },
  cardHeaderP2: {
    fontSize:'13px',
    fontWeight:'400',
    fontFamily:'Jost',
    lineHeight:'36px',
    color:'#888888'
  },
  customButton: {
    borderRadius:'3px',
    blend:'pass through',
    display:'flex',
    justifyContent:'center',
    color:'#FFFFFF',
    // backgroundColor:'#009A22',
    padding:'0.5rem',
    marginTop: '0.5rem'
  },
  divider: {
    height: '2px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}


export class LostAndFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      listLoading: true
    }
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    }
    
    var api_options = {
      config: {
        url: COMMUNITY_LOST_AND_FOUND_LIST_URL,
        method:'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),
      },
      successCallback:(response)=>{
         if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  renderLostAndFound = (lost_found, idx) => {
    let {name, desc, contact, model, image, id, created_at} = lost_found;
    return (
      <div key={id} style={{...styles.card}} >
        <Box component={Link} to={`${id}`} style={{textDecoration: 'none', color: 'black'}}> 
        <CardMedia
          component="img"
          sx={{ width: 100, height: 110, margin:'10px' }}
          image={COMMUNITY_LOST_AND_FOUND_IMG_URL + image}
          alt="Live from space album cover"
        />
        </Box>
        <Box style={{padding:"2rem 0.2rem 2rem 0.5rem", width:"100%", textDecoration: 'none', color: 'black'}} 
          component={Link}
          to={`${id}`}
        >
          <p style={styles.cardHeaderP1} >{name}</p>
          <p style={styles.cardHeaderP2} >Lost date : {format_date_str(created_at)}</p>
          {/* <p style={styles.cardHeaderP2} >Model : {model}</p>
          <p style={styles.cardHeaderP2} >Description : {desc}</p> */}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent:'center', margin:"10px" }}>
          <a href={"tel:" + contact}  sx={{background:'#E1E1F2'}}>
            <PhoneIcon sx={{color:'#676AC1'}}/>
          </a>
        </Box>
      </div>
    )
  }

  load_main_view  = () => {
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
        list={this.state.lists}
        renderItem={this.renderLostAndFound}
        renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
      />
    )
  }

  render() {
    return (
      <>
        <Header societyName="List of Lost Items"/>
        <div style={styles.container}>
          {this.load_main_view()}
        </div>
      </>
    )
  }
}

export default LostAndFound