import React, { Component } from 'react'
import cycle_svg from '../Assets/community_assets/cycle.svg'
import Header from '../Header';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { COMMUNITY_LOST_AND_FOUND_DETAILS_URL, COMMUNITY_LOST_AND_FOUND_IMG_URL } from '../Utility/URL';
import { apiCall, format_time_str, MyLoader, format_date_str } from '../Utility/utils';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%',
  },
  imageContainer1: {
    position: "relative",
    width: "100%",
    paddingBottom:"40%", /* padding is using for the height of image */
    margin: "0px",
    overflow: "hidden",
    // border: "1px solid black"
  },
  imageContainer2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "0px",
    padding: "0px",
    overflow: "hidden",
    border: "0px"
  },
  image: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "-100",
    right: "-100",
    height:"auto",
    margin: "auto",
    width: "100%",
  },
  cardHeaderP1: {
    fontSize:'18px',
    lineHeight:'26px',
    fontWeight:'600',
    fontFamily:'Jost'
  },
  cardHeaderP2: {
    fontSize:'13px',
    fontWeight:'400',
    fontFamily:'Jost',
    lineHeight:'36px',
    color:'#888888'
  },
  text1:{
    fontSize:'16px',
    fontWeight:'400',
    fontFamily:'Jost',
    lineHeight:'26px',
    color:'#57564F'
  }
}

export class LostItemDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listLoading: true,
    }
    
  }

  async details(){
    let formData = {
      id: parseInt(window.location.href.split("lostandfound/")[1]),
      token: localStorage.getItem("societyToken")
    };

    let api_options = {
      config: {
        method: 'post',
        url: COMMUNITY_LOST_AND_FOUND_DETAILS_URL,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData)
      },
      successCallback: (response)=>{
        if(Boolean(response.data.data)){
          return this.setState({listLoading:false, details: response.data.data, message: ''});
        } else {
          return this.setState({listLoading:false, details: {}, message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.details();
  };

  load_main_view = () => {
    if(this.state.listLoading === true){
      return <MyLoader />
    }
    if(typeof this.state.message != "undefined" && this.state.message != ""){
      return (<div className="container">{this.state.message}</div>)
    }
    const {name, image, contact, desc, created_at, model, color} = this.state.details;

    return (
      <>
        <div style={styles.container}>
          <div style={styles.imageContainer1}>
            <div style={styles.imageContainer2}>
              <img src={COMMUNITY_LOST_AND_FOUND_IMG_URL + image} style={styles.image} alt="lost_item_big_img" />
            </div>
          </div>
          <div style={{padding:"1rem 1rem 2rem 1rem", width:"100%"}}>
            <p style={styles.cardHeaderP1} >{name}</p>
            <p style={styles.cardHeaderP2} >Lost date : {format_date_str(created_at)}</p>
            <Grid container spacing={1} style={{marginTop:'0.5rem'}}>
              <Grid item xs={4}>
                <p style={styles.text1}>Model:</p>
              </Grid>
              <Grid item xs={8}>
                <p style={styles.text1}>{model}</p>
              </Grid>
              <Grid item xs={4}>
                <p style={styles.text1}>Colour:</p>
              </Grid>
              <Grid item xs={8}>
                <p style={styles.text1}>{color}</p>
              </Grid> 
              <Grid item xs={4}>
                <p style={styles.text1}>Details:</p>
              </Grid>
              <Grid item xs={8}>
              <p style={styles.text1}>{desc}</p>
              </Grid>        
            </Grid>
            <a href={"tel:" + contact} style={{textDecoration: "none"}}>
              <Button 
                sx={{background:'linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%)',marginTop:'2rem',fontFamily:'Jost'}}
                fullWidth  variant="contained"
              >Contact</Button>
            </a>

          </div>
        </div>
      </>
    )
  }

  render() {
    return (
      <>
        <Header societyName="Lost Item"/>
        {this.load_main_view()}
      </>
    )
  }
}

export default LostItemDetail