import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import { apiCall, format_date_str, format_date_time_str, format_time_str, MyLoader } from '../Utility/utils';
import { COMMUNITY_MEETINGS_LIST_URLS, COMMUNITY_MEETING_REQUEST_URL } from '../Utility/URL';
import FlatList from 'flatlist-react'
import moment from 'moment';
import Header from '../Header';
import { NO_DATA_FOUND } from '../Utility/utils';

const styles = {
  container: {
    backgroundColor: '#F7F7F7',
    minHeight: '100vh',
    width:'100%'
  },
  card: {
    backgroundColor: "#FFFFFF",
    // marginTop: "2rem",
    padding: "0.5rem 1.5rem 1rem 1.5rem",
    borderTop: "2rem solid #F7F7F7"
  },
  cardHeaderP1: {
    fontSize:'18px',
    lineHeight:'26px',
    fontWeight:'600',
    fontFamily:'Jost'
  },
  cardHeaderP2: {
    fontSize:'13px',
    fontWeight:'400',
    fontFamily:'Jost',
    color:'#9D9EB1'
  },
  cardBody500: {
    fontFamily: 'Jost',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '26px'
  },
  cardBody400: {
    fontFamily: 'Jost',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px'
  },
  customButton: {
    borderRadius:'3px',
    blend:'pass through',
    display:'flex',
    justifyContent:'center',
    color:'#FFFFFF',
    // backgroundColor:'#009A22',
    padding:'0.5rem',
    marginTop: '0.5rem'
  },
  divider: {
    height: '2px',
    backgroundColor: '#D8D8D8',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}

export default class Meetings extends Component {
  constructor() {
    super();
    this.state = {
      lists: [],
      listLoading: true
    }
    this.toggler = {};
  }

  async lists() {
    var formData = {
      token: localStorage.getItem("societyToken")
    };

    var api_options = {
      config: {
        method:'post',
        url: COMMUNITY_MEETINGS_LIST_URLS,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        if(response.data.total > 0){
          return this.setState({listLoading:false, lists:response.data.data, message: ''});
        }else {
          return this.setState({listLoading:false, lists: [], message: response.data.message});
        }
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  async meetingRequest(meeting_id, status_id) {
    var formData = {
      token: localStorage.getItem("societyToken"),
      id: parseInt(meeting_id),
      status_id: parseInt(status_id)
    };

    var api_options = {
      config: {
        method:'post',
        url: COMMUNITY_MEETING_REQUEST_URL,
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(formData),

      },
      successCallback:(response)=>{
        window.location.reload();
      },
      errorCallback: (error)=>{console.log("Error", error.response);}
    };
    apiCall(api_options);
  };

  componentDidMount() {
    this.lists();
  };

  toggle_div = (meeting) => {    
    this.toggler['meeting_' + meeting.id] = !this.toggler['meeting_' + meeting.id];
    this.setState({render: true});
  }

  renderMeetings = (meeting, idx ) => {
    if(typeof this.toggler['meeting_' + meeting.id] == "undefined"){
      this.toggler['meeting_' + meeting.id] = false;  
    }
    var display_prop = this.toggler['meeting_' + meeting.id] == false ? 'none' : '';
    var display_text = this.toggler['meeting_' + meeting.id] == false ? 'show more' : 'show less';

    const {name, desc, wing_name, created_name, created_at, meeting_datetime, is_attend} = meeting;

    return (
      <div  style={styles.card} key={idx}>
        <div >
          <p style={styles.cardHeaderP1}>{name}</p>
          <p style={styles.cardHeaderP2}>{format_date_time_str(created_at)}</p>
          <p onClick={ () => this.toggle_div(meeting)} style={{...styles.cardBody500, color:'#676AC1', textDecoration:'underline', marginTop:'0.5rem'}}>{display_text}</p>
        </div>
        <Grid container spacing={2} style={{marginTop:'0.5rem', display: display_prop}}>
          <Grid item xs={6}>
            <p style={styles.cardBody500}>Date : {format_date_str(meeting_datetime)}</p>
          </Grid>
          <Grid item xs={6}>
            <p style={styles.cardBody500}>Time : {format_time_str(meeting_datetime)}</p>
          </Grid>
          
          <Grid item xs={6}>
            <p style={styles.cardBody500}>Host: {created_name}</p>
          </Grid>
          <Grid item xs={12}>            
            <p style={{...styles.cardBody500,marginTop:'1rem'}}><strong>Purpose of the Meet:</strong></p>
            <p style={{...styles.cardBody400,color:'#57564F'}}>{desc}</p>
          </Grid>
          {this.render_meeting_result(meeting)}          
        </Grid>
       
        {this.render_btn(meeting)}
        
        
      </div>
    );
  }

  render_meeting_result = (meeting) => {

    if(meeting.is_closed == 1) {
        return (
          <Grid item xs={12}>            
            <p style={{...styles.cardBody500,marginTop:'1rem'}}><strong>Conclusion of the Meet:</strong></p>
            <p style={{...styles.cardBody400,color:'#57564F'}}>{meeting.result_note}</p>
          </Grid>
        )
    }
    
    return;

  }

  render_btn = (meeting) => {
      
    if(meeting.is_closed == 1) {
        return;
    }

          const { is_attend} = meeting;

    return (

      <Grid container spacing={2} style={{marginTop:'0.5rem', display: is_attend===0?"":"none"}}>
          <Grid item xs={6} onClick={()=>{this.meetingRequest(meeting.id, 1)}}>
            <div style={{...styles.customButton, backgroundColor:'#009A22'}} >
              <p style={styles.cardBody500}>Accept</p>
            </div>
          </Grid>
          <Grid item xs={6} onClick={()=>{this.meetingRequest(meeting.id, 2)}}>
            <div style={{...styles.customButton, backgroundColor:'#CF4037'}} >
              <p style={styles.cardBody500}>Cancel</p>
            </div>
          </Grid>
        </Grid>
      
      )
  }

  load_main_view  = () => {
    
    if(this.state.listLoading === true){
        return <MyLoader />
    }

    return (
      <FlatList
          list={this.state.lists}
          renderItem={this.renderMeetings}
          renderWhenEmpty={() => NO_DATA_FOUND(this.state.message)}
        />
    )
  }

  render() {
    return (
      <>
      <Header societyName="Meetings"/>
      <div style={styles.container}>
        {this.load_main_view()}
      </div>
      </>
    );
  }
}
