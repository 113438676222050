import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import FormHelperText from '@mui/material/FormHelperText';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PhoneNumberInput from "./PhoneNumberField";


const GetOTP = ({countryCode, mobileNumber, handleCountryCodeChange, handleMobileNumberChange, handleGetOTP}) => {
    return (
        <>
            <CardContent sx={{m: 2}}>
                <Typography variant="h5" component="div">
                    Login
                </Typography>
                <PhoneNumberInput
                    countryCode={countryCode}
                    mobileNumber={mobileNumber}
                    onCountryCodeChange={handleCountryCodeChange}
                    onMobileNumberChange={handleMobileNumberChange}
                />
                <FormHelperText>OTP will be sent to this number to verify your account</FormHelperText>
            </CardContent>
            <CardActions sx={{textAlign: "center"}}>
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                >
                    <Button variant="contained" sx={{backgroundColor: "black"}} onClick={handleGetOTP}>
                        Get OTP <ArrowRightAltIcon/> 
                    </Button>
                </Box>
            </CardActions>
        </>
    );
}

export default GetOTP;