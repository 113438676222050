import React,  { useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { apiCall } from "../../Utility/utils";
import { AADHAR_UPLOAD_URL, DAILY_HELPER_IMG_URL } from "../../Utility/URL";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 2,
};

const AdharUpload = ({label, onChange, onClick, fileName}) => {
    const fileInputRef = useRef(null);
    const [ selectedFile, setSelectedFile ] = useState(null);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleButtonClick = () => {
        onClick();
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        uploadAdharFile(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };

    const getName = () => {
        if (fileName) {
            const name = fileName;
            const maxLength = 10;
            const nameLength = name.length;
            const nameComponents = name.split(".");
            const extention = nameComponents[nameComponents.length -1];
    
            // if (nameLength + extention.length > maxLength) {
            //     return name.slice(0, maxLength) + "... ." + extention;
            // }
            return name;
        }
    }

    const uploadAdharFile = (file) => {
        console.log("Uploading Aadhar file")
        const data = new FormData();
        data.append('token', localStorage.getItem("societyToken"));
        data.append("file", file);
          
        apiCall({
            config: {
                method: 'post',
                url: AADHAR_UPLOAD_URL,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            },
            successCallback: (response)=> {
                console.log("File uploading response", response.data);
                onChange(response.data.data.id)
            },
            errorCallback: (error)=>{console.log("Error", error);}
        });
    }

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" sx={{fontFamily:'Jost', color: "rgba(136, 136, 136, 1)"}} display="flex" justifyContent="space-between" alignItems="center">
                    {label}
                    { selectedFile !== null && <CheckIcon  sx={{ ml: 1, bgcolor: "green", borderRadius: "50%", color: "white", fontSize: "1rem"}} /> }
                </Typography>

                <Link onClick={handleOpen}  href="#" sx={{fontFamily:'Jost', color: "rgba(0, 0, 0, 1)"}}>
                    <strong>{getName()}</strong> 
                </Link>
                
                <Link onClick={handleButtonClick} variant="h6" sx={{fontFamily:'Jost', color: "rgba(103, 106, 192, 1)"}}>
                    <input
                        type="file"
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                    { selectedFile === null ? "+ Uploads" : "Change" }
                    
                </Link>

            </Box>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ color: "rgba(255, 255, 255, 1)", p: 0}}
                    >
                        <CloseIcon fontSize="large" />
                        <Typography variant="h6"  sx={{ color: "white"}}>
                           { selectedFile !== null && selectedFile.name}
                        </Typography>
                    </IconButton>

                    <Box width="100%">
                        <img
                            src={DAILY_HELPER_IMG_URL + fileName}
                            alt="Preview"
                            style={{ width: '100%', maxHeight: '100%' }}
                        />

                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default AdharUpload;